export default {
  bind(el, binding) {
    // eslint-disable-next-line no-param-reassign
    el.style.visibility = binding.value ? 'visible' : 'hidden';
  },
  update(el, binding) {
    // eslint-disable-next-line no-param-reassign
    el.style.visibility = binding.value ? 'visible' : 'hidden';
  },
};
