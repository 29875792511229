import PosPage from './views/PosPage.vue';

const routes = [
  {
    path: '/pos',
    component: PosPage,
    name: 'pos',
    props: (route) => ({ ...route.query, ...route.params }),
    meta: { i18n: ['point-of-sale-pov', 'datetime-pov'] },
  },
];

export default routes;
