import httpService from '@/modules/common/services/httpService';

const paymentStatusesRoute = '/business/payments/v1/payment_requests';

const fetchPaymentRequest = async (paymentStatusUid) => httpService.get(`${paymentStatusesRoute}/${paymentStatusUid}`)
  .then((res) => res.data?.data?.payment_request);

const fetchPaymentLink = async (paymentStatusUid, clientUid, amount) => httpService.get(`${paymentStatusesRoute}/${paymentStatusUid}/get_link?new_api=true&client_id=${clientUid}&amount=${amount}`)
  .then((res) => res?.data?.data?.link);

const fetchOpenPaymentRequests = async (matterUid, page = 1, per_page = 30) => httpService.get(paymentStatusesRoute, {
  per_page,
  page,
  'filter[matter_uid][eq]': matterUid,
  'filter[state][in]': 'DUE,OVERDUE',
  'filter[entity_type][in]': 'Meeting,EventAttendance,ProductOrder,ClientBookingPackage',
  'filter[amount_pending][lte]': 0,
  'filter[display]': true,
  sort: 'created_at:asc',
})
  .then((res) => res.data?.data.payment_requests);

export default {
  fetchPaymentRequest,
  fetchOpenPaymentRequests,
  fetchPaymentLink,
};
