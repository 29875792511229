const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "DashboardPage" */ './views/DashboardPage.vue'),
    props: { featureFlag: 'new_dashboard', angularRoute: 'app.dashboard' },
    meta: { i18n: 'dashboard-pov' },
  },
];

export default routes;
