<template>
  <div class="tax-summary d-flex flex-column">
    <div v-if="taxes.length > 0" class="reg-row d-flex flex-row justify-space-between">
      <div class="reg-row-label">{{ subtotalLabel }}</div>
      <div class="reg-row-value">{{ $priceDisplay(prices.netTotal, currency) }}</div>
    </div>
    <div v-for="(tax, index) in taxes" :key="index" class="reg-row d-flex flex-row justify-space-between">
      <div class="reg-row-label">{{ `${tax.name} (${tax.rate}%)` }}</div>
      <div class="reg-row-value">{{ $priceDisplay(tax.tax_total, currency) }}</div>
    </div>
    <div class="total d-flex flex-row justify-space-between" :class="mode">
      <div class="total-label">{{ totalLabel }}</div>
      <div class="total-value">{{ $priceDisplay(prices.grandTotal, currency) }}</div>
    </div>
  </div>
</template>

<script>
import taxUtil from '@/modules/payments/utils/taxUtil';

export default {
  name: 'PriceSummary',
  props: {
    price: {
      type: Number,
      required: true,
    },
    taxes: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'exclude',
    },
    currency: {
      type: String,
      default: 'USD',
    },
    locale: {
      type: String,
      default: 'en',
    },
    quantity: {
      type: Number,
      required: false,
    },
    hideTopBorder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    prices() {
      return taxUtil.calcPriceBreakdown(this.mode, this.price, this.taxes);
    },
    showBorder() {
      return !this.hideTopBorder && (this.taxes.length > 0 || this.mode === 'include');
    },
    totalLabel() {
      return this.mode === 'include' ? this.$t('payment.tax_summary.total_label.include') : this.$t('payment.tax_summary.total_label.exclude');
    },
    subtotalLabel() {
      return this.mode === 'include' ? this.$t('payment.tax_summary.subtotal_label.include') : this.$t('payment.tax_summary.subtotal_label.exclude');
    },
  },
};
</script>

<style lang="scss" scoped>
.tax-summary{
    padding-bottom: var(--size-value1);
    display: flex;
    justify-content: space-between;
    align-self: stretch;
  .reg-row{
    padding-top: var(--size-value1);
    padding-bottom: var(--size-value1);

    &.amount-include-vat,&.new-net {
      .reg-row-label, .reg-row-value{
        font-weight: var(--font-weight-large) !important;
      }
    }
    .reg-row-label, .reg-row-value{
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-x-small);
      line-height: var(--size-value5);
    }
    .reg-row-label{
      padding-inline-start: var(--size-value4);
    }
    .reg-row-value{
      padding-inline-end: var(--size-value4);
    }
  }
  .total{
    padding-top: var(--size-value1);
    padding-bottom: var(--size-value1);
    justify-content: space-between;
    .total-label, .total-value{
      font-weight: var(--font-weight-medium2);
      font-size: var(--font-size-x-small);
      line-height: var(--size-value5);
    }

    .total-label {
      padding-inline-start: var(--size-value4);
    }

    .total-value {
      padding-inline-end: var(--size-value4);
    }
  }
}
</style>
