<template>
  <VcLayout flex-grow-0>
    <VcPageHeader :title="$t('point_of_sale.page_title')"
                  :subtitle="$t('point_of_sale.page_subtitle')"
                  :show-back-button="showBackButton"
                  :chip-label="chipLabel"
                  @onBackClicked="onBackClicked"
                  data-qa="pos-page-header"/>
  </VcLayout>
</template>
<script>
export default {
  name: 'PosPageHeader',
  props: {
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    chipLabel() {
      if (this.showBackButton) return null;
      return this.$t('point_of_sale.beta');
    },
  },
  methods: {
    onBackClicked() {
      this.$router.handleBack();
    },
  },
};
</script>
