const routes = [
  {
    path: '/settings/payments',
    component: () => import(/* webpackChunkName: "PaymentSettings" */ './views/PaymentSettingsPage.vue'),
    name: 'payment-settings',
    props: (route) => ({ ...route.query }),
    meta: { i18n: 'payment-settings-pov' },
  },
];

export default routes;
