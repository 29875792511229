const isEqual = (arr1, arr2, forceOrder = false) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const arr1String = (forceOrder ? arr1 : arr1.toSorted()).toString();
  const arr2String = (forceOrder ? arr2 : arr2.toSorted()).toString();

  return arr1String === arr2String;
};

export default {
  isEqual,
};
