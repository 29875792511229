<template>
  <div class="pos-page-container">
    <VcLayout justify-center align-start class="pos-page-wrapper">
      <VcLayout column class="pos-page-wrapper__content">
        <PosPageHeader :show-back-button="showBackButton"/>

        <PosBanner
          v-if="!loading && !isCheckoutEnabled"
          @onAction="openPaymentWizard"
        />

        <VcLayout v-if="loading" justify-center>
          <VcLoader/>
        </VcLayout>

        <PosTableContainer v-else class="pos-page-wrapper__list" @edit-price-and-add="onEditPriceBeforeAddingItem"/>
      </VcLayout>

      <VcLayout v-if="loading && $isMobile()" align-self-center justify-center>
        <VcLoader/>
      </VcLayout>

      <template v-else>
        <PosCheckoutContainer :key="matter_id"
                              @select-client="selectClient"
                              @open-catalog-mobile="openCatalogMobile"
                              @edit-item="onEditItem"
                              @remove-item="onRemoveItem"
                              :populate-unpaid="populateUnpaid"
                              @handle-free-checkout="handleFreeCheckout"
                              class="pos-page-wrapper__checkout"/>
      </template>
    </VcLayout>

    <MobileCatalogModal v-model="modals.mobileCatalogModal" @edit-price-and-add="onEditPriceBeforeAddingItem"/>
    <NavigationGuardModal v-model="modals.navigationGuardModal" @onConfirm="navigateAway"/>
    <EditItemModal v-model="modals.editItemModal"
                   @onSave="onSaveItem"
                   @update:visible="onCloseEditModal"
                   :item="getItemToEdit()" :tax-mode="taxMode" :taxes="taxes"
                   :force-price-edit="itemToEditBeforeAdd !== null"/>
    <LinkedEntitiesRemoveModal v-model="modals.linkedEntitiesRemoveModal"
                               @onConfirm="onMultiServiceRemove"
                               :source-item="linkedItemsModalData.sourceItem"
                               :linked-entities="linkedItemsModalData.linkedEntities"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PosBanner from '@/modules/payments/components/PosBanner.vue';
import PosCheckoutContainer from '@/modules/payments/views/PosCheckoutContainer.vue';
import PosPageHeader from '@/modules/payments/components/PosPageHeader.vue';
import PosTableContainer from '@/modules/payments/views/PosTableContainer.vue';
import { MODALS } from '@/modules/payments/constants/pos';
import MobileCatalogModal from '@/modules/payments/components/modals/MobileCatalogModal.vue';
import NavigationGuardModal from '@/modules/payments/components/modals/NavigationGuardModal.vue';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import eventListenerService from '@/utils/eventCommunicationUtils/eventListenerService';
import EditItemModal from '@/modules/payments/components/modals/EditItemModal.vue';
import arrayUtil from '@/utils/arrayUtil';
import LinkedEntitiesRemoveModal
  from '@/modules/payments/components/modals/LinkedEntitiesRemoveModal.vue';
import LayoutConstants from '@/constansts/layoutConstants';

export default {
  name: 'PosPage',
  components: {
    PosBanner,
    LinkedEntitiesRemoveModal,
    EditItemModal,
    MobileCatalogModal,
    NavigationGuardModal,
    PosPageHeader,
    PosCheckoutContainer,
    PosTableContainer,
  },
  data() {
    return {
      modals: {
        navigationGuardModal: false,
        mobileCatalogModal: false,
        editItemModal: false,
        linkedEntitiesRemoveModal: false,
      },
      loading: true,
      navigationCallback: null,
      editingItemIndex: null,
      linkedItemsModalData: {},
      isCheckoutDone: false,
      itemToEditBeforeAdd: null,
    };
  },
  props: {
    matter_id: {
      type: String,
      default: null,
    },
    entities: {
      type: [Array, String],
      default: () => [],
      validator: (value) => (Array.isArray(value) && value.every((item) => item.uid && item.type))
        || (typeof value === 'string' && value.split(',').filter(Boolean).every((item) => item.includes(':'))),
    },
    populateUnpaid: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('PosStore', ['selectedMatter', 'selectedClient', 'checkoutItems', 'getLinkedItems', 'newProducts', 'priceBreakdown']),
    ...mapGetters('PaymentSettingsStore', ['isCheckoutEnabled', 'taxMode']),
    ...mapGetters('ToastStore', ['toastConfig']),
    ...mapGetters('TaxesStore', ['taxes']),
    selectedMatterUid() {
      return this.selectedMatter?.uid;
    },
    initialEntities() {
      if (typeof this.entities === 'string') {
        return this.entities.split(',').filter(Boolean).map((entity) => {
          const [uid, type] = entity.split(':');
          return { uid, type };
        });
      }

      return this.entities;
    },
    topBarConfig() {
      const showBackButton = Boolean(this.$isMobile());
      const title = this.$t('point_of_sale.page_title');

      return { title, showBackButton, show: true };
    },
    isUnmodifiedPaymentRequest() {
      const initialEntitiesArr = this.initialEntities.map((entity) => `${entity.uid}:${entity.type}`);
      const checkoutItemsArr = this.checkoutItems.map((item) => `${item.entity_uid}:${item.entity_type}`);
      return arrayUtil.isEqual(initialEntitiesArr, checkoutItemsArr);
    },
    isPageUntouched() {
      return this.isCheckoutEmpty || this.isCheckoutDone;
    },
    isCheckoutEmpty() {
      return this.checkoutItems.length === 0 && !this.selectedMatter;
    },
    freeSaleToastText() {
      return this.$t('point_of_sale.toasts.free_sale_toast');
    },
  },
  watch: {
    selectedMatterUid(value) {
      this.setupMatterContext(value);
    },
    matter_id: {
      immediate: true,
      handler(value) {
        this.setMatterUid(value);
      },
    },
  },
  async mounted() {
    eventListenerService.addListener('modalClosed', this.onModalClosed);
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
    try {
      await Promise.all([
        this.setTopBarConfig(this.topBarConfig),
        this.setMatterUid(this.matter_id),
        this.fetchSettings(),
        this.loadTaxes(),
      ]);
    } catch (error) {
      console.error('Error during mount:', error);
    } finally {
      this.loading = false;
    }
    this.$track('Checkout - Page View');
  },
  beforeDestroy() {
    this.clearData();
    eventListenerService.removeListener('modalClosed', this.onModalClosed);
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  },
  methods: {
    ...mapActions('LayoutTopBarStore', ['setTopBarConfig']),
    ...mapActions('ModalStore', ['openModal']),
    ...mapActions('PaymentSettingsStore', ['fetchSettings']),
    ...mapActions('PaymentRequestStore', ['fetchOpenPaymentRequests']),
    ...mapActions('PosStore', ['setMatterUid', 'setBillableItemsAndFetchData', 'createSaleAndTakePayment', 'clearData', 'updateBillableItem', 'addBillableItem', 'removeBillableItem', 'removeLinkedBillableItems']),
    ...mapActions('TaxesStore', ['loadTaxes']),
    ...mapActions('ProductsStore', ['setRefreshProducts']),
    ...mapActions('ToastStore', ['setToastConfig']),
    async setupMatterContext(matterUid) {
      if (!matterUid) return;
      try {
        if (!this.checkoutItems.length) {
          await this.setBillableItemsAndFetchData({
            billableItems: this.initialEntities,
            highlight: true,
          });
        }
        await this.fetchOpenPaymentRequests(matterUid);
      } catch (error) {
        console.error('Error during setupMatterContext:', error);
      }
    },
    beforeUnloadHandler(event) {
      if (!this.isPageUntouched) {
        event.preventDefault();
      }
    },
    selectClient() {
      this.openModal({
        name: MODALS.ClientPicker,
        payload: [{ title: this.$t('common.quick_actions_menu.point_of_sale') }],
      });
    },
    openCatalogMobile() {
      this.modals.mobileCatalogModal = true;
    },
    openPaymentWizard() {
      this.openModal({
        name: MODALS.PaymentWizard,
        payload: [{ source: 'POS page' }],
      });
    },
    onModalClosed({ name, result }) {
      switch (name) {
        case MODALS.TakePayment:
        case MODALS.QrCode:
          if (result) {
            this.handlePaymentSuccess(result, name);
          }
          break;
        case MODALS.PaymentWizard:
          this.fetchSettings();
          break;
        case MODALS.SendLinkSuccess:
          this.handleSendLinkSuccess(name);
          break;
        case MODALS.ClientPicker:
          if (result) {
            this.setMatterUid(result.client.matter_uid);
          }
          break;
        default:
          break;
      }
    },
    handlePaymentSuccess(result, modalName) {
      this.isCheckoutDone = true;
      if (this.newProducts.length > 0) {
        this.setRefreshProducts(true);
      }
      if (result.send_link) {
        this.saleId = result.currentCart.uid;
        this.openModal({
          name: MODALS.SendLinkSuccess,
          payload: [{
            channel: result.channel,
            client: this.selectedClient,
          }],
        });
      } else {
        this.toastPaymentSuccess(false);
        this.exitPosPage(this.saleId, result.payment.payment_id, modalName);
      }
    },
    handleSendLinkSuccess(modalName) {
      this.isCheckoutDone = true;
      this.toastPaymentSuccess(true);
      this.exitPosPage(this.saleId, null, modalName);
    },
    navigateAway() {
      if (this.newProducts.length > 0) {
        this.setRefreshProducts(true);
      }
      this.setBillableItemsAndFetchData({ billableItems: [] });
      this.navigationCallback();
    },
    getItemToEdit() {
      return this.itemToEditBeforeAdd ?? this.checkoutItems[this.editingItemIndex];
    },
    onEditItem(index) {
      this.modals.editItemModal = true;
      this.editingItemIndex = index;
    },
    onEditPriceBeforeAddingItem(catalogItem) {
      this.itemToEditBeforeAdd = catalogItem;
      this.modals.editItemModal = true;
      this.editingItemIndex = this.checkoutItems.length;
    },
    onSaveItem(item) {
      if (this.itemToEditBeforeAdd) {
        this.addBillableItem({ uid: this.itemToEditBeforeAdd.uid, type: this.itemToEditBeforeAdd.type, ...item });
      } else {
        this.updateBillableItem({ index: this.editingItemIndex, billableItem: item });
      }
    },
    onCloseEditModal() {
      this.$nextTick(() => { this.itemToEditBeforeAdd = null; });
    },
    onRemoveItem(index) {
      const linkedItems = this.getLinkedItems(this.checkoutItems[index]);
      if (linkedItems.length > 1) {
        this.linkedItemsModalData = {
          sourceItem: this.checkoutItems[index],
          linkedEntities: linkedItems,
        };
        this.modals.linkedEntitiesRemoveModal = true;
      } else {
        this.removeBillableItem(index);
      }
    },
    onMultiServiceRemove() {
      this.removeLinkedBillableItems(this.linkedItemsModalData.linkedEntities);
    },
    getToastConfig(text, type) {
      return {
        value: true,
        type: type || 'success',
        bodyText: text,
        timeout: this.toastConfig.timeout,
        identifier: 'pos-toast',
      };
    },
    navigateToSalePage(saleUid) {
      outgoingPostMessageUtils.post(LayoutConstants.NAVIGATE_TO, {
        route: 'app.sale.show',
        data: { uid: saleUid },
        options: { location: 'replace' },
      });
    },
    exitPosPage(saleUid, paymentUid, modalName) {
      switch (modalName) {
        case MODALS.SendLinkSuccess:
          this.navigateToSalePage(saleUid);
          break;
        default:
          if (this.isUnmodifiedPaymentRequest) {
            this.$router.handleBack({ replace: true });
          } else if (paymentUid) {
            outgoingPostMessageUtils.post('navigate-to', {
              route: 'app.payment.show',
              data: { uid: paymentUid },
              options: { location: 'replace' },
            });
          } else {
            this.navigateToSalePage(saleUid);
          }
          outgoingPostMessageUtils.post('pos_payment_success');
          break;
      }
    },
    handleFreeCheckout({ saleUid }) {
      this.isCheckoutDone = true;
      this.exitPosPage(saleUid, null);
      outgoingPostMessageUtils.post('fire-action', {
        event_name: 'refresh_payment_request',
        data: {},
      });
    },
    toastPaymentSuccess(isSendLink) {
      let toastText = '';

      if (isSendLink) {
        toastText = this.$t('payment.take_payment.send_link_success');
      } else {
        toastText = this.$t('payment.take_payment.record_payment_added');
      }
      if (!toastText) return;
      const toastConfig = this.getToastConfig(toastText, this.toastConfig.type);
      this.setToastConfig(toastConfig);
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (this.isPageUntouched) {
      next();
    } else {
      this.navigationCallback = next;
      this.modals.navigationGuardModal = true;
      next(false);
    }
  },
  beforeRouteLeave(to, from, next) {
    const isFreeSale = this.priceBreakdown.grandTotal === 0;
    if (isFreeSale && this.isCheckoutDone) {
      const toastConfig = this.getToastConfig(this.freeSaleToastText);
      this.setToastConfig(toastConfig);
    }
    if (this.isPageUntouched) {
      next();
    } else {
      this.navigationCallback = next;
      this.modals.navigationGuardModal = true;
      next(false);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "styles/variables.scss";

.pos-page-container {
  height: 100%;
  background-color: var(--layout_bg_color);
  text-align: start;
}

.pos-page-wrapper {
  height: 100%;
  gap: var(--size-value6);

  &__content {
    height: 100%;
    overflow: hidden;
    flex: 2 1 67%;
    padding: var(--size-value6);
    display: none;

    @include md-and-up {
      display: flex;
    }

    .v-progress-circular {
      margin-top: var(--size-value4);
    }
  }

  &__list, &__checkout {
    height: 100%;
    width: 100%;
    flex: 1 0 100%;
    overflow: hidden;
    padding-bottom: var(--size-value1);

    @include md-and-up {
      padding-bottom: var(--size-value6);
    }
  }

  &__list {
    min-height: 0;
    background-color: var(--modal-bg-color);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-3);

    @include md-and-up {
      flex: 1;
    }
  }

  &__checkout {
    height: 100%;
    width: 100%;
    flex: 1 0 100%;
    overflow: hidden;
    padding-bottom: var(--size-value6);
    background-color: var(--modal-bg-color);
    box-shadow: var(--shadow-1);

    @include md-and-up {
      flex-basis: 33%;
      min-width: 316px;
    }
  }
}
</style>
