<template>
  <div class="scheduling-totals">
    <!--    eslint-disable-next-line vuejs-accessibility/click-events-have-key-events       -->
    <div v-if="pendingApprovalCount" @click="$emit('onPendingApprovalClick')" class="scheduling-total pointer-cursor mb-9">
      <div class="section-title">{{ $t('widget.scheduling_widget.pending_approval') }}</div>
      <div class="section-text mt-2">{{ pendingApprovalCount }}</div>
    </div>
    <!--    eslint-disable-next-line vuejs-accessibility/click-events-have-key-events       -->
    <div v-if="showMonthCount" @click="$emit('onMonthCountClick')" class="scheduling-total pointer-cursor">
      <div class="section-title">{{ $t('widget.scheduling_widget.booking_this_month') }}</div>
      <div class="mt-2" :class="monthCount ? 'section-text' : 'empty-text'">
        {{ monthCount || $t('widget.scheduling_widget.booking_this_month_empty') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchedulingTotals',
  props: {
    pendingApprovalCount: { type: Number },
    monthCount: { type: Number },
    showMonthCount: { type: Boolean, default: false },
  },
};
</script>

<style scoped lang="scss">
.pointer-cursor {
  cursor: pointer;
}
.section-title {
  font-size: var(--font-size-small2);
  font-weight: var(--font-weight-large2);
}
.section-text {
  font-size: var(--font-size-medium2);
  font-weight: var(--font-weight-large2);
}
.empty-text {
  font-size: var(--font-size-x-small);
  color: var(--gray-darken-3)
}
.scheduling-total {
  &:hover {
    color: var(--link-color);
  }
}
</style>
