import servicesService from '@/modules/services/service/servicesService';

const PAGE_SIZE = 25;

const state = {
  services: [],
  loading: false,
  currentPageIndex: 0,
  completed: false,
};

const mutations = {
  addServices(state, services) {
    state.services = state.services.concat(services);
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setCompleted(state, completed) {
    state.completed = completed;
  },
  increaseCurrentPageIndex(state) {
    state.currentPageIndex += 1;
  },
};

const actions = {
  async loadServices({ state, dispatch }) {
    if (state.services.length) return;
    await dispatch('loadMoreServices');
  },
  async loadMoreServices({ commit, state, rootGetters }) {
    if (state.completed || state.loading) return;
    commit('setLoading', true);

    try {
      const businessId = rootGetters['AuthStore/businessUid'];
      const services = await servicesService.fetchServices(businessId, state.currentPageIndex, PAGE_SIZE);
      commit('addServices', services);
      commit('increaseCurrentPageIndex');
      if (services.length < PAGE_SIZE) {
        commit('setCompleted', true);
      }
    } catch (e) {
      console.log('Error loading services', e); // eslint-disable-line no-console
    }

    commit('setLoading', false);
  },
};

const getters = {
  services: (state) => state.services,
  getServiceByUid: (state) => (uid) => state.services.find((service) => service.id === uid),
  loading: (state) => state.loading,
  completed: (state) => state.completed,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
