function downloadFile(fileData, fileName) {
  const url = window.URL.createObjectURL(fileData);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.setAttribute('download', fileName);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  window.URL.revokeObjectURL(url);
}

export default {
  downloadFile,
};
