<template>
  <VcConfirmModal size="md"
                  :show-dialog="visible"
                  :title="discardChangesTitleText"
                  :content-text="discardChangesSubtitleText"
                  :ok-button-label="discardChangesAgreeText"
                  :cancel-button-label="discardChangesCancelText"
                  @onCloseButtonClicked="closeModal"
                  @onCancelClicked="closeModal"
                  @onOkClicked="confirmDiscard"/>
</template>

<script>
export default {
  name: 'NavigationGuardModal',
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    discardChangesTitleText() {
      return this.$t('point_of_sale.discard_changes_dialog.title');
    },
    discardChangesSubtitleText() {
      return this.$t('point_of_sale.discard_changes_dialog.subtitle');
    },
    discardChangesAgreeText() {
      return this.$t('point_of_sale.discard_changes_dialog.agree');
    },
    discardChangesCancelText() {
      return this.$t('point_of_sale.discard_changes_dialog.cancel');
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:visible', false);
    },
    confirmDiscard() {
      this.$emit('update:visible', false);
      this.$emit('onConfirm');
    },
  },
};
</script>
