<template>
  <VcConfirmModal size="md"
                  :show-dialog="visible"
                  :title="linkedEntitiesRemoveTitleText"
                  :content-text="linkedEntitiesRemoveSubtitleText"
                  :ok-button-label="linkedEntitiesRemoveAgreeText"
                  :cancel-button-label="linkedEntitiesRemoveCancelText"
                  @onCloseButtonClicked="closeModal"
                  @onCancelClicked="closeModal"
                  @onOkClicked="confirmDiscard"
                  class="linked-entities-container">
    <template #content>
      <div class="linked-entities-container__subtitle" v-html="linkedEntitiesRemoveSubtitleText"/>

      <div class="linked-entities-container__list-title">
        {{ $t('point_of_sale.linked_entities_remove_dialog.list_title') }}
      </div>

      <VcFocusArea>
        <VcLayout column class="linked-entities-container__list">
          <VcLayout v-for="entity in otherLinkedEntities" :key="entity.id"
                    class="linked-entities-container__list-item"
                    :column="$isMobile()">
            <div class="flex-grow-1 text-truncate">{{ entity.name }}</div>
            <div class="flex-shrink-0">{{ entity.datetime_info }}</div>
          </VcLayout>
        </VcLayout>
      </VcFocusArea>
    </template>
  </VcConfirmModal>
</template>

<script>
export default {
  name: 'LinkedEntitiesRemoveModal',
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    sourceItem: {
      type: Object,
      default: () => ({}),
    },
    linkedEntities: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    linkedEntitiesRemoveTitleText() {
      return this.$t('point_of_sale.linked_entities_remove_dialog.title');
    },
    linkedEntitiesRemoveSubtitleText() {
      return this.$t('point_of_sale.linked_entities_remove_dialog.subtitle', { service_name: this.sourceItem.name });
    },
    linkedEntitiesRemoveAgreeText() {
      return this.$t('point_of_sale.linked_entities_remove_dialog.agree');
    },
    linkedEntitiesRemoveCancelText() {
      return this.$t('point_of_sale.linked_entities_remove_dialog.cancel');
    },
    otherLinkedEntities() {
      return this.linkedEntities.filter((item) => {
        const isSourceItem = item.entity_uid === this.sourceItem.entity_uid && item.entity_type === this.sourceItem.entity_type;
        return !isSourceItem;
      });
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:visible', false);
    },
    confirmDiscard() {
      this.$emit('update:visible', false);
      this.$emit('onConfirm');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "styles/variables.scss";

.linked-entities-container {
  &__subtitle {
    font-size: var(--font-size-small2);
  }

  &__list-title {
    margin-top: var(--size-value4);
    margin-bottom: var(--size-value2);
    font-weight: var(--font-weight-large);
    font-size: var(--font-size-x-small);
    line-height: var(--size-value5);
  }

  &__list {
    @include md-and-up {
      gap: var(--size-value1);
    }
  }

  &__list-item {
    padding-block: var(--size-value2);
    font-size: var(--font-size-x-small);
    line-height: var(--size-value5);
    gap: var(--size-value1);

    @include md-and-up {
      padding-block: var(--size-value1);
    }
  }
}
</style>
