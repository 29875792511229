/* eslint-disable no-await-in-loop */
/**
 * Calls a given function and keeps calling it after the specified delay has passed.
 *
 * @param {() => any} fn The function to call.
 * @param {() => boolean | Promise<boolean>} [shouldStopPolling] A callback function indicating whether to stop polling.
 * @param {number | (() => number)} delayOrDelayCallback The delay (in milliseconds)
 * to wait before calling the function again. Can be a function.
 * @returns {Promise<void>}
 */
import sleep from '@/utils/sleep';

export default async function poll(fn, shouldStopPolling, delayOrDelayCallback, timeout = -1) {
  let output = await fn();
  let shouldStop = await shouldStopPolling(output);
  const startTime = Date.now();
  while (!shouldStop && (timeout === -1 || Date.now() - startTime < timeout)) {
    const delay = typeof delayOrDelayCallback === 'number' ? delayOrDelayCallback : delayOrDelayCallback();
    await sleep(Math.max(0, delay));

    output = await fn();
    shouldStop = await shouldStopPolling(output);
  }
  if (shouldStop) {
    return output;
  }
  throw new Error('Timeout exceeded');
}
