<template>
  <div v-if="hasAiGeneratorFf">
    <VcButton ref="activatorButton"
              @click="onButtonClick"
              :loading="loading"
              :style="calculatedStyle"
              :disabled="disabled"
              class="ai-generator-button pa-0"
              outlined
              showDisabledBackground>
      <template #prepend>
        <VcIcon size="16">icon-Generate-AI</VcIcon>
      </template>
    </VcButton>

    <AiGenerator
      :anchor-y="y"
      :anchor-x="x"
      absolute
      offset-y
      :feature="feature"
      :modelName="modelName"
      :agent-name="agentName"
      :target-text-value="value"
      :variables="variables"
      :dropdownState="dropdownMenuOpen"
      :disclaimerText="disclaimerText"
      :analyticsParameters="analyticsParameters"
      :info-banner-text="infoBannerText"
      @dropdownStateChange="dropDownStateChange"
      @startStream="startStream"
      @onStream="onStream"
      @endStream="endStream"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AiGenerator from '@/modules/aiContentGenerator/components/AiGenerator.vue';

export default {
  name: 'AiGeneratorButton',
  components: { AiGenerator },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    infoBannerText: {
      type: String,
      default: null,
    },
    agentName: {
      type: String,
      default: 'vanilla',
    },
    modelName: {
      type: String,
    },
    feature: {
      type: String,
      required: true,
    },
    variables: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      validator: (value) => ['sm', 'md'].includes(value),
      default: 'md',
    },
    disclaimerText: {
      type: String,
      default: '',
    },
    analyticsParameters: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      x: 0,
      y: 0,
      loading: false,
      dropdownMenuOpen: false,
    };
  },
  computed: {
    calculatedStyle() {
      const sizeMap = {
        sm: 'var(--size-value8)',
        md: 'var(--size-value13)',
      };
      return {
        width: sizeMap[this.size],
        height: sizeMap[this.size],
      };
    },
    hasAiGeneratorFf() {
      return this.$hasFeature('bizai_content_generator_enabled');
    },
  },
  methods: {
    ...mapActions('AngularStore', ['setIsOverlayDisplay']),
    emitTextUpdate(value) {
      this.$emit('input', value);
    },
    onButtonClick() {
      const rect = this.$refs.activatorButton.$el.getBoundingClientRect();
      this.x = rect.left;
      this.y = rect.bottom;
      this.dropDownStateChange(true);
    },
    dropDownStateChange(state) {
      this.dropdownMenuOpen = state;
    },
    startStream() {
      this.emitTextUpdate('');
      this.loading = true;
    },
    onStream(token) {
      this.emitTextUpdate(token);
    },
    endStream() {
      this.loading = false;
    },
  },
};
</script>
