<template>
  <VcBanner
    v-if="!hideBanner"
    class="pos-banner"
    data-qa="pos-banner"
    :title="$t('point_of_sale.banner.title')"
    :subtitle="$t('point_of_sale.banner.subtitle')"
    :button-label="$t('point_of_sale.banner.button')"
    :link-label="$t('point_of_sale.banner.link')"
    :image="require('@/assets/images/payments/pos-banner.svg')"
    close-button
    @onAction="$emit('onAction')"
    @closeBanner="hideBanner = true"
  />
</template>

<script>
export default {
  name: 'PosBanner',
  data() {
    return {
      hideBanner: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.pos-banner {
  margin-block-end: var(--size-value6);
}
</style>
