const state = {
  layoutActionsDisabled: false,
  documentTitle: '',
};

const getters = {
  isLayoutActionsDisabled: (state) => state.layoutActionsDisabled,
  getDocumentTitle: (state) => state.documentTitle,
  formattedDocumentTitle: (state, _, rootState, rootGetters) => {
    if (!state.documentTitle) {
      return 'Loading...';
    }
    const featureFlagsLoaded = rootGetters['BusinessStore/featureFlagsLoaded'];
    const isBrandingLoaded = rootGetters['BusinessStore/isBrandingLoaded'];
    if (!featureFlagsLoaded || !isBrandingLoaded) {
      return state.documentTitle;
    }
    const brandName = rootGetters['BusinessStore/brandName'];
    if (brandName && rootGetters['BusinessStore/featureFlags'].includes('partner_name_in_page_title')) {
      return `${state.documentTitle} | ${brandName}`;
    }
    return state.documentTitle;
  },
};

const mutations = {
  setLayoutActionsDisabled(state, isDisabled) {
    state.layoutActionsDisabled = isDisabled;
  },
  setDocumentTitle(state, title) {
    state.documentTitle = title;
  },
};

const actions = {
  async refreshLayoutData({ dispatch }) {
    await dispatch({ type: 'BusinessStore/fetchAttributes' }, { root: true });
    await dispatch({ type: 'BusinessStore/fetchBranding' }, { root: true });
    await dispatch({ type: 'LayoutBottomNavStore/createBottomNavTabs' }, { root: true });
    await dispatch({ type: 'MainMenuStore/aggregateMenuItemsForStaff' }, { root: true });
  },
  checkLayoutActionsDisabled({ rootGetters, commit }) {
    const isStaticPage = rootGetters['BusinessStore/hasFeature']('static_page');
    const isAccountBlocked = rootGetters['BusinessStore/attributes']?.account_blocked;
    commit('setLayoutActionsDisabled', isStaticPage || isAccountBlocked);
  },
  setDocumentTitle({ commit }, title) {
    commit('setDocumentTitle', title);
  },
};

export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions,
};
