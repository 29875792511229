import httpService from '@/modules/common/services/httpService';

const basePath = '/v2/settings';

const getAppsByType = async ({ type, businessUid }) => httpService.get(`${basePath}/get_available_apps_by_type`, { type, buid: businessUid, nocache: new Date().toDateString() })
  .then((res) => res.data.data)
  .catch((error) => {
    console.error(error);
    return { code: 'error' };
  });

const getAvailableMarketApps = async () => httpService.get(`${basePath}/get_available_market_apps`)
  .then((res) => res.data.data)
  .catch((error) => {
    throw new Error('Error getting available market apps', error);
  });

const getExternalApps = async () => httpService.get(`${basePath}/get_external_applications`)
  .then((res) => res.data.data)
  .catch((error) => {
    throw new Error('Error getting external apps', error);
  });

export default {
  getAppsByType,
  getAvailableMarketApps,
  getExternalApps,
};
