const routes = [
  {
    path: '/settings/bizai',
    component: () => import(/* webpackChunkName: "BizAiSettings" */ './views/BizAiSettingsPage.vue'),
    name: 'bizai-settings',
    props: (route) => ({ ...route.query }),
    meta: { i18n: 'bizai-pov' },
  },
];

export default routes;
