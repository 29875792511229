import gatewayService from '@/modules/common/services/gatewayService';

const baseRoute = '/v3/ai/staff_ai_settings';

const getStaffAiSettings = async (staffUid) => {
  try {
    const res = await gatewayService.get(`${baseRoute}/${staffUid}`);
    return res.data;
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

const updateStaffAiSettings = async (staffUid, payload) => {
  try {
    const res = await gatewayService.put(`${baseRoute}/${staffUid}`, payload);
    return res.data;
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

export default {
  getStaffAiSettings,
  updateStaffAiSettings,
};
