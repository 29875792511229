import { render, staticRenderFns } from "./EmbeddedAppDelegatorIframe.vue?vue&type=template&id=a60ee9ea&scoped=true"
import script from "./EmbeddedAppDelegatorIframe.vue?vue&type=script&lang=js"
export * from "./EmbeddedAppDelegatorIframe.vue?vue&type=script&lang=js"
import style0 from "./EmbeddedAppDelegatorIframe.vue?vue&type=style&index=0&id=a60ee9ea&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a60ee9ea",
  null
  
)

export default component.exports