import poll from '@/utils/poll';
import servicesService from '@/modules/services/service/servicesService';
import scraperService from '../services/serviceScraperService';

const state = {
  services: null,
  loader: false,
  workflowUid: null,
};

const getters = {
  services: (state) => state.services,
  loader: (state) => state.loader,
};

const mutations = {
  setLoader(state, loader) {
    state.loader = loader;
  },
  setScrapeResult(state, { workflowUid, services }) {
    state.workflowUid = workflowUid;
    state.services = services;
  },
};

function buildServiceItems(state, logo) {
  return state.services.filter((service) => service.checkbox).map((service) => {
    let chargeType;
    if (service.price === 'free') {
      chargeType = 'no_cost';
    } else if (service.price === 'for_a_fee') {
      chargeType = 'discuss';
    } else {
      throw new Error('Invalid price type');
    }
    return ({
      name: service.name,
      description: service.description,
      charge_type: chargeType,
      display: service.display,
      image_path: logo,
      price: 0,
      interaction_type: 'default_business_location',
    });
  });
}

const COMPLETION_STATES = ['COMPLETED', 'FAILED'];

async function getScrapeResult(url) {
  const runData = await scraperService.scrape(url);
  const runStatus = await poll(
    async () => (await scraperService.getRun(runData.data.uid)).data,
    (result) => COMPLETION_STATES.includes(result.status),
    5 * 1000,
    5 * 60 * 1000,
  );

  if (runStatus.status === 'FAILED') {
    throw new Error(`Run has failed for ${url}`);
  }
  return runStatus;
}

const actions = {
  async scrapeServices({ commit }, url) {
    commit('setLoader', true);
    try {
      const scrapeResult = await getScrapeResult(url);
      const statefulServices = scrapeResult.output.data.services.map((service, idx) => ({
        ...service,
        checkbox: false,
        display: false,
        id: `service-${idx}`,
      }));
      statefulServices.sort((a, b) => {
        if (a.price === 'free' && b.price === 'for_a_fee') {
          return -1;
        }
        if (a.price === 'for_a_fee' && b.price === 'free') {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });
      commit('setScrapeResult', { workflowUid: scrapeResult.uid, services: statefulServices });
    } finally {
      commit('setLoader', false);
    }
  },
  async importScrapedServices({ state, commit }, { logo }) {
    commit('setLoader', true);
    try {
      const items = buildServiceItems(state, logo);
      const bulkCreateResult = await servicesService.bulkCreateServices({
        items,
      });
      const createdServices = bulkCreateResult.created.map((result) => ({
        name: result.name,
        uid: result.uid,
      }));

      await scraperService.feedback(state.workflowUid, {
        services: createdServices,
      });
    } finally {
      commit('setLoader', false);
    }
  },
  updateServiceProperty({ state }, { id, property, newVal }) {
    state.services.find((x) => x.id === id)[property] = newVal;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
