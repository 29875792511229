<template>
  <VcInputModal size="md"
                close-button
                :show-dialog="visible"
                :title="customItemTitleText"
                :ok-button-label="customItemAgreeText"
                :cancel-button-label="customItemCancelText"
                :show-back-button="false"
                :okButtonProps="{disabled: !isAddEnabled}"
                @onCloseButtonClicked="closeModal"
                @onCancelClicked="closeModal"
                @onOkClicked="addCustomItem"
                class="custom-item-modal">
    <template #content>
      <VcLayout column>
        <div class="custom-item-modal__label">
          <span class="custom-item-modal__text">{{ itemLabelText }}</span>
        </div>
        <VcTextField data-qa="item-name"
                    class="py-1 mb-2"
                    counter :maxlength="70"
                    :value="currentItem.name"
                    :rules="[rules.required]"
                    :label="$t('payment.payment_dialogs.custom_item_dialog.name')"
                    @input="newValue => currentItem.name = newValue"/>

        <VcLayout class="custom-item-modal__description">
          <VcTextField data-qa="item-description"
                       class="py-1 mb-2"
                       counter :maxlength="100" auto-grow
                       :value="currentItem.description"
                       :label="$t('payment.payment_dialogs.custom_item_dialog.description')"
                       @input="newValue => currentItem.description = newValue"/>

          <AiGeneratorButton class="mt-2"
                             feature="product-description"
                             :variables="contentGeneratorVariables"
                             :analyticsParameters="analyticsParameters"
                             v-model="currentItem.description"/>
        </VcLayout>

        <div class="custom-item-modal__label">
          <span class="custom-item-modal__text">{{ priceDetailsLabel }}</span>
        </div>

        <VcFocusArea class="custom-item-modal__inputs"  :class="{'flex-column': $isMobile() , 'flex-row': !$isMobile() }">
          <div class="d-flex flex-row align-start custom-item-modal__inputs__counters" :class="{'mobile': $isMobile() }">
              <VcCounter :value="currentItem.rate" type="number" :min="0"
                        data-qa="custom-item-price"
                        :rules="grandTotalRules"
                        class="custom-item-modal__counter"
                        :prefix="$currencySymbol(currency)"
                        :label="$t('payment.payment_dialogs.custom_item_dialog.grand_total')"
                        @input="newValue => currentItem.rate = newValue"/>
              <VcCounter :value="currentItem.quantity" type="number"
                        data-qa="custom-item-quantity"
                        class="custom-item-modal__counter"
                        :rules="[rules.positiveNumber, rules.required]"
                        :label="$t('payment.payment_dialogs.custom_item_dialog.quantity')"
                        @input="newValue => currentItem.quantity = newValue"/>
          </div>
          <div :class="{'tax-picker__mobile': $isMobile(), 'tax-picker': !$isMobile()}">
            <TaxPicker  :taxes="taxes"
                          :isMobile="$isMobile()"
                          :selectedTaxes="currentItem.taxes"
                          @update-selected="updatedSelectedTaxes"
                          :class="{'mobile': $isMobile()}"></TaxPicker>
          </div>
        </VcFocusArea>
        <VcAccordion v-if="showTaxSummary" gap="s" :items="priceBreakdownAccordionItem" focusArea
                      class="custom-item-modal__price-breakdown" :openPanels="isOpenPanel" @change="toggleAccordion">
          <template #header-0 >
            <div :class="['custom-item-modal__price-breakdown__header', {'status-open': isOpenPanel.length}]">
              <VcIcon class="custom-item-modal__price-breakdown__header--status" size="10">$caret_down</VcIcon>
              {{$t('payment.tax_summary.price_breakdown')}}
            </div>
          </template>
          <template #content-0>
            <div class="tax-summary d-flex flex-row">
              <div class="tax-summary-content flex-grow-1" :class="{'mobile': $isMobile()}">
                <TaxSummary :price="getPrice"
                          :taxes="currentItem.taxes"
                          :mode="taxMode"
                          :quantity="parseFloat(currentItem.quantity)"
                          :currency="currency"
                          :locale="locale"/>
              </div>
            </div>
          </template>
        </VcAccordion>
        <VcCheckbox data-qa="display-product-checkbox"
                    class="mb-2 mt-1"
                    :label="$t('payment.payment_dialogs.custom_item_dialog.save_item_checkbox')"
                    :value="saveNewItem"
                    @change="val => saveNewItem = val"/>
      </VcLayout>
    </template>
  </VcInputModal>
</template>

<script>
import TaxPicker from '@/modules/payments/components/taxes/TaxPicker.vue';
import TaxSummary from '@/modules/payments/components/taxes/TaxSummary.vue';
import { ENTITY_TYPES } from '@/modules/payments/constants/pos';
import productUtil from '@/modules/products/utils/productUtil';
import AiGeneratorButton from '@/modules/aiContentGenerator/components/AiGeneratorButton.vue';

const defaultItem = {
  quantity: 1,
  rate: null,
  name: '',
  description: '',
  taxes: [],
};

export default {
  name: 'CustomItemModal',
  components: {
    AiGeneratorButton,
    TaxPicker,
    TaxSummary,
  },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    taxes: {
      type: Array,
      default: () => ([]),
    },
    visible: {
      type: Boolean,
      default: false,
    },
    businessCategory: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: 'en',
    },
    currency: {
      type: String,
      default: 'USD',
    },
    taxMode: {
      type: String,
      required: true,
    },
    startOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentItem: {
        quantity: 1,
        rate: null,
        name: '',
        description: '',
        taxes: [],
        type: Object,
        required: true,
        validator: (value) => value.name && value.rate > 0 && value.quantity > 0,
      },
      rules: {
        required: (v) => !!v || this.$t('payment.payment_dialogs.validations.required'),
        min: (v) => v >= 0 || this.$t('payment.payment_dialogs.validations.min'),
        positiveNumber: (v) => v > 0 || this.$t('payment.payment_dialogs.validations.positive'),
      },
      grandTotal: 0,
      isOpenPanel: [],
      openedOnce: false,
      saveNewItem: false,
    };
  },
  created() {
    if (this.startOpen) this.toggleAccordion();
  },
  computed: {
    contentGeneratorVariables() {
      return {
        product_name: {
          title: this.$t('payment.payment_dialogs.custom_item_dialog.content_generator.item_name'),
          value: this.currentItem.name,
        },
        locale: {
          title: this.$t('payment.payment_dialogs.custom_item_dialog.content_generator.locale'),
          value: this.$i18n.locale,
        },
        business_category: {
          title: this.$t('payment.payment_dialogs.custom_item_dialog.content_generator.business_category'),
          value: this.businessCategory,
        },
      };
    },
    isAddEnabled() {
      return this.currentItem.rate && this.currentItem.rate >= 0 && this.currentItem.name && this.currentItem.quantity > 0;
    },
    customItemTitleText() {
      return this.$t('payment.payment_dialogs.custom_item_dialog.title');
    },
    customItemAgreeText() {
      return this.$t('payment.payment_dialogs.custom_item_dialog.save');
    },
    customItemCancelText() {
      return this.$t('payment.payment_dialogs.custom_item_dialog.cancel');
    },
    itemLabelText() {
      return this.$t('payment.payment_dialogs.custom_item_dialog.item');
    },
    priceDetailsLabel() {
      return this.$t('payment.payment_dialogs.custom_item_dialog.price_details');
    },
    grandTotalRules() {
      return [this.rules.required, this.rules.min];
    },
    taxPickerLabel() {
      return this.$t('payment.tax_picker.label');
    },
    getPrice() {
      return parseFloat(this.currentItem.rate || 0) * (this.currentItem.quantity || 0);
    },
    showTaxSummary() {
      return this.currentItem.rate && parseFloat(this.currentItem.rate) !== 0
              && this.currentItem.taxes?.length > 0 && this.currentItem.quantity > 0;
    },
    priceBreakdownAccordionItem() {
      return [{ name: this.$t('payment.tax_summary.price_breakdown') }];
    },
    analyticsParameters() {
      return {
        source: 'custom-item-pos',
      };
    },
  },
  methods: {
    closeModal() {
      Object.assign(this.currentItem, defaultItem);
      this.currentItem.taxes = [];
      this.saveNewItem = false;
      this.openedOnce = true;
      this.isOpenPanel = [];
      this.$emit('update:visible', false);
    },
    addCustomItem({ valid }) {
      if (valid) {
        this.$track('Add Custom Item - Add Click', {
          Save_as_product: this.saveNewItem,
          Quantity: this.currentItem.quantity,
          Number_of_set_taxes: this.currentItem.taxes.length,
        });
        if (this.saveNewItem) {
          const product = productUtil.parseProductItemToCreate({ ...this.currentItem, currency: this.currency, save: true });
          this.$emit('onSave', product);
        } else {
          const checkoutProduct = this.parseCheckoutProduct(this.currentItem);
          this.$emit('saveToCheckout', checkoutProduct);
        }

        if (this.$isMobile()) {
          this.$emit('show-checkout');
        } else {
          this.closeModal();
        }
      }
    },
    parseCheckoutProduct(item) {
      const catalogProduct = {};
      catalogProduct.type = ENTITY_TYPES.Custom;
      catalogProduct.name = item.name;
      catalogProduct.description = item.description;
      catalogProduct.quantity = 1;
      catalogProduct.taxes = item.taxes;
      catalogProduct.currency = this.currency;
      catalogProduct.price = Number(item.rate);

      return { quantity: Number(item.quantity), product: catalogProduct };
    },
    async toggleAccordion() {
      this.openedOnce = true;
      this.isOpenPanel = this.isOpenPanel.length ? [] : [0];
    },
    updatedSelectedTaxes(selectedTaxes) {
      this.currentItem.taxes = [...selectedTaxes];
      this.isOpenPanel = [0];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "styles/variables.scss";

.custom-item-modal {
  &__label {
    height: var(--size-value6);
    align-self: stretch;
  }
  &__text {
    font-size: var(--font-size-x-small);
    font-weight: var(--font-weight-large);
    line-height: var(--size-value6);
  }
  &__inputs {
    gap: var(--size-value4);

    &__counters {
      gap: var(--size-value4);
    }
  }

  &__description {
    gap: var(--size-value4);
  }

  &__price-breakdown {
    gap: var(--size-value1);
    min-height: 0px;
    line-height: var(--size-value6);

    &__header {
      display: flex;
      align-items: center;
      gap: var(--size-value2);
      font-size: var(--font-size-x-small);
      margin-block: var(--size-value2);
      line-height: var(--size-value5);
      overflow: hidden;
      color: var(--gray-darken-5);
      text-overflow: ellipsis;

      &--status {
        transform: rotate(-90deg);

        .status-open & {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.tax-picker {
    width: 75%;
    &__mobile{
      width: 100%;
    }
  }

.tax-summary{
    width: 100%;
    .empty-space{
      width: 50%;
      margin-inline-end: 20px;
    }
    .tax-summary-content {
      width: 100%;
      &.mobile{
        width: 100%;
      }
    }
  }

</style>
