import Vue from 'vue';

// Import all directives
import showVisible from './showVisible.directive';

// Register all directives
Vue.directive('show-visible', showVisible);

export default {
  showVisible,
};
