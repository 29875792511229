import DesignSystem from '@vcita/design-system';
import DesignSystemInit from '@vcita/design-system/init/DesignSystem';
import Vue from 'vue';
import { VApp } from 'vuetify/lib';
import initI18n from '@vcita/design-system/init/initI18n';
import VcSkeleton from '@vcita/design-system/src/components/VcSkeleton/VcSkeleton.vue';
import VcButton from '@vcita/design-system/src/components/VcButton/VcButton.vue';
import VcLayout from '@vcita/design-system/src/components/VcLayout/VcLayout.vue';
import VcLoader from '@vcita/design-system/src/components/VcLoader/VcLoader.vue';
import VcMenuItem from '@vcita/design-system/src/components/VcMenuItem/VcMenuItem.vue';
import VcIcon from '@vcita/design-system/src/components/VcIcon/VcIcon.vue';
import VcBadge from '@vcita/design-system/src/components/VcBadge/VcBadge.vue';
import VcDropdown from '@vcita/design-system/src/components/VcMenu/VcDropdown.vue';
import VcImage from '@vcita/design-system/src/components/VcImage/VcImage.vue';
import VcMenu from '@vcita/design-system/src/components/VcMenu/VcMenu.vue';
import VcList from '@vcita/design-system/src/components/list/VcList/VcList.vue';

const theme = {
  dark: false,
  options: {
    customProperties: true, // allow create css variables based on the theme
  },
  themes: {
    light: {
      primary: '#0C315E',
      secondary: {
        base: '#0093B8',
        lighten1: '#99D4E3',
        lighten2: '#C2E5EE',
        lighten3: '#EDF7FA',
      },
      text: '#FFFFFF',
      anchor: 'var(--link-color)',
    },
  },
};

const options = {
  googleAppKey: window.pov_config.VUE_APP_GOOGLE_MAPS_API_KEY,
};

Vue.use(DesignSystem, {
  components: {
    VcLayout,
    VcLoader,
    VcSkeleton,
    VcButton,
    VcMenuItem,
    VcDropdown,
    VcIcon,
    VcBadge,
    VcImage,
    VcMenu,
    VcList,
    VcQuickActions: () => import('@vcita/design-system/src/components/VcQuickActions/VcQuickActions.vue'),
    VcSmallQuickAction: () => import('@vcita/design-system/src/components/VcQuickActions/VcSmallQuickAction.vue'),
    VcOverlay: () => import('@vcita/design-system/src/components/VcOverlay/VcOverlay.vue'),
    VcWideTopMenuBar: () => import('@vcita/design-system/src/components/VcWideTopMenuBar/VcWideTopMenuBar.vue'),
    VcToast: () => import('@vcita/design-system/src/components/VcToast/VcToast.vue'),
    VcPageHeader: () => import('@vcita/design-system/src/components/page/elements/VcPageHeader.vue'),
    VcInputModal: () => import('@vcita/design-system/src/components/modal/VcInputModal/VcInputModal.vue'),
    VcModalContainer: () => import('@vcita/design-system/src/components/modal/elements/VcModalContainer.vue'),
    VcModalHeader: () => import('@vcita/design-system/src/components/modal/elements/VcModalHeader.vue'),
    VcModalFooter: () => import('@vcita/design-system/src/components/modal/elements/VcModalFooter.vue'),
    VcBottomNav: () => import('@vcita/design-system/src/components/VcBottomNav/VcBottomNav.vue'),
    VcTopMenuBar: () => import('@vcita/design-system/src/components/VcTopMenuBar/VcTopMenuBar.vue'),
    VcProfileIndication: () => import('@vcita/design-system/src/components/VcProfileIndication/VcProfileIndication.vue'),
    VcMenuItemsGroup: () => import('@vcita/design-system/src/components/VcMenuItemsGroup/VcMenuItemsGroup.vue'),
    VcProgressCircular: () => import('@vcita/design-system/src/components/VcProgressCircular/VcProgressCircular.vue'),
    VcEmptyState: () => import('@vcita/design-system/src/components/VcEmptyState/VcEmptyState.vue'),
    VcTabs: () => import('@vcita/design-system/src/components/VcTabs/VcTabs.vue'),
    VcDataTable: () => import('@vcita/design-system/src/components/table/elements/VcDataTable/VcDataTable.vue'),
    VcCheckbox: () => import('@vcita/design-system/src/components/VcCheckbox/VcCheckbox.vue'),
    VcAlert: () => import('@vcita/design-system/src/components/VcAlert/VcAlert.vue'),
    VcTextField: () => import('@vcita/design-system/src/components/VcTextField/VcTextField.vue'),
    VcSegmentedControl: () => import('@vcita/design-system/src/components/VcSegmentedControl/VcSegmentedControl.vue'),
    VcAvatar: () => import('@vcita/design-system/src/components/VcAvatar/VcAvatar.vue'),
    VcDraggableList: () => import('@vcita/design-system/src/components/VcDraggableList/VcDraggableList.vue'),
    VcTimeSince: () => import('@vcita/design-system/src/components/VcTimeSince/VcTimeSince.vue'),
    VcInfiniteScroll: () => import('@vcita/design-system/src/components/listPage/VcInfiniteScroll/VcInfiniteScroll.vue'),
    VcLink: () => import('@vcita/design-system/src/components/VcLink/VcLink.vue'),
    VcSwitch: () => import('@vcita/design-system/src/components/VcSwitch/VcSwitch.vue'),
    VcTooltip: () => import('@vcita/design-system/src/components/VcTooltip/VcTooltip.vue'),
    VcClientItem: () => import('@vcita/design-system/src/components/sidepane/VcClientItem/VcClientItem.vue'),
    VcDetailItem: () => import('@vcita/design-system/src/components/sidepane/VcDetailItem/VcDetailItem.vue'),
    VcAccordion: () => import('@vcita/design-system/src/components/VcAccordion/VcAccordion.vue'),
    VcTimeline: () => import('@vcita/design-system/src/components/sidepane/VcTimeline/VcTimeline.vue'),
    VcTimelineRecord: () => import('@vcita/design-system/src/components/sidepane/VcTimelineRecord/VcTimelineRecord.vue'),
    VcConfirmModal: () => import('@vcita/design-system/src/components/modal/VcConfirmModal/VcConfirmModal.vue'),
    VcEntityStatus: () => import('@vcita/design-system/src/components/sidepane/VcEntityStatus/VcEntityStatus.vue'),
    VcSidepaneHeader: () => import('@vcita/design-system/src/components/sidepane/VcSidepaneHeader/VcSidepaneHeader.vue'),
    VcGroupedItems: () => import('@vcita/design-system/src/components/VcGroupedItems/VcGroupedItems.vue'),
    VcPoweredBy: () => import('@vcita/design-system/src/components/VcPoweredBy/VcPoweredBy.vue'),
    VcTextArea: () => import('@vcita/design-system/src/components/VcTextArea/VcTextArea.vue'),
    VcFocusArea: () => import('@vcita/design-system/src/components/VcFocusArea/VcFocusArea.vue'),
    VcCounter: () => import('@vcita/design-system/src/components/VcCounter/VcCounter.vue'),
    VcScrollShadow: () => import('@vcita/design-system/src/components/VcScrollShadow/VcScrollShadow.vue'),
    VcDivider: () => import('@vcita/design-system/src/components/VcDivider/VcDivider.vue'),
    VcModalWrapper: () => import('@vcita/design-system/src/components/modal/elements/VcModalWrapper.vue'),
    VcWizard: () => import('@vcita/design-system/src/components/wizard/VcWizard/VcWizard.vue'),
    VcSelectField: () => import('@vcita/design-system/src/components/VcSelectField/VcSelectField.vue'),
    VcExpansionCard: () => import('@vcita/design-system/src/components/VcExpansionCard/VcExpansionCard.vue'),
    VcListbox: () => import('@vcita/design-system/src/components/listBox/VcListbox/VcListbox.vue'),
    VcRadioGroup: () => import('@vcita/design-system/src/components/VcRadioGroup/VcRadioGroup.vue'),
    VcSystemAlert: () => import('@vcita/design-system/src/components/VcSystemAlert/VcSystemAlert.vue'),
    VcSideNav: () => import('@vcita/design-system/src/components/VcSideNav/VcSideNav.vue'),
    VcBanner: () => import('@vcita/design-system/src/components/VcBanner/VcBanner.vue'),
    VcInputPopover: () => import('@vcita/design-system/src/components/VcInputPopover/VcInputPopover.vue'),
    VcInputBottomSheet: () => import('@vcita/design-system/src/components/VcInputBottomSheet/VcInputBottomSheet.vue'),
    VcForm: () => import('@vcita/design-system/src/components/VcForm/VcForm.vue'),
    VcPhoneField: () => import('@vcita/design-system/src/components/VcPhoneField/VcPhoneField.vue'),
    VcChip: () => import('@vcita/design-system/src/components/VcChip/VcChip.vue'),
    VcSearchBar: () => import('@vcita/design-system/src/components/VcSearchBar/VcSearchBar.vue'),
    VcBottomSheet: () => import('@vcita/design-system/src/components/VcBottomSheet/VcBottomSheet.vue'),
    VcDatePicker: () => import('@vcita/design-system/src/components/VcDatePicker/VcDatePicker.vue'),
    VcUpsellBlock: () => import('@vcita/design-system/src/components/VcUpsellBlock/VcUpsellBlock.vue'),
    VcCard: () => import('@vcita/design-system/src/components/VcCard/VcCard.vue'),
    VcDropzone: () => import('@vcita/design-system/src/components/VcDropzone/VcDropzone.vue'),
    VcAvatarStack: () => import('@vcita/design-system/src/components/VcAvatarStack/VcAvatarStack.vue'),
  },
});

initI18n();

Vue.component('v-app', VApp);

const config = { theme: { theme }, options };
export default DesignSystemInit(config);
