import gatewayService from '@/modules/common/services/gatewayService';

const basePath = '/v3/ai/workflows';

const scrape = async (url) => gatewayService.post(`${basePath}/run?name=${window.encodeURIComponent('workflow/web-scraping/services')}`, {
  inputs: {
    url,
  },
}).then((res) => res.data);

const getRun = async (workflowUid) => gatewayService.get(`${basePath}/run/${workflowUid}`).then((res) => res.data);

const feedback = async (workflowUid, data) => gatewayService.post(`${basePath}/feedback`, {
  uid: workflowUid,
  data,
}).then((res) => res.data);

export default {
  scrape,
  getRun,
  feedback,
};
