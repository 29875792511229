<template>
  <VcLayout column class="sales-widget pa-6" :class="{ 'sales-widget--loaded': isWidgetReady }">
    <div
      class="sales-widget__title"
      @click="navigate({ path: paymentRoute })"
      @keydown.enter="navigate({ path: paymentRoute })"
      tabindex="0"
      role="button"
      :aria-label="$t('widget.sales_widget.title')"
    >{{ $t('widget.sales_widget.title') }}</div>
    <VcLayout class="sales-widget__wrapper mt-4">
      <VcLayout class="sales-widget__data">
        <PaymentWidgetList :isLoading="isWidgetLoading" :salesData="salesWidgetData" :currency="currency"
                           @navigate="navigate" @click-empty-state="goToPaymentWizard"/>
      </VcLayout>
      <VcLayout class="sales-widget__forecast">
        <ForecastGraph :isLoading="isForecastLoading" :forecastData="forecastData" :currency="currency"/>
      </VcLayout>
    </VcLayout>
  </VcLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PaymentWidgetList from './PaymentWidgetList.vue';
import ForecastGraph from './ForecastGraph.vue';

const EMPTY_STATE = 'Empty state';

const OVERDUE_PAYMENTS_VALUES = {
  empty: EMPTY_STATE,
  last_week: '1: 1 - 7 days',
  last_month: '1: 7- 30 days',
  old: '1: 30+ days',
  last_week_and_last_month: '2: 1 - 7 days, 7- 30 days',
  last_week_and_old: '2: 1 - 7 days, 30+ days',
  last_month_and_old: '2: 7- 30 days, 30+ days',
  all: '3: 1 - 7 days, 7- 30 days, 30+ days',
};

export default {
  name: 'SalesWidget',
  components: {
    PaymentWidgetList,
    ForecastGraph,
  },
  data() {
    return {
      isWidgetLoading: true,
      isForecastLoading: true,
      isWidgetReady: false,
    };
  },
  computed: {
    ...mapGetters('PaymentsStore/SalesWidgetStore', ['salesWidgetData', 'forecastData']),
    ...mapGetters('PaymentSettingsStore', ['currency']),
    paymentRoute() {
      if (this.$hasFeature('point_of_sale')) {
        return '/app/pos';
      }
      return '/app/payments';
    },
  },
  async created() {
    this.addI18nModule('widgets-pov');
    await this.init();
    this.handleMixpanelEvents();
    this.$emit('ready');
    this.isWidgetReady = true;
  },
  methods: {
    ...mapActions('PaymentsStore/SalesWidgetStore', ['fetchSalesWidgetData', 'fetchForecastData']),
    ...mapActions('PaymentSettingsStore', ['fetchSettings']),
    ...mapActions('ModalStore', ['openModal']),
    async init() {
      await Promise.all([
        this.fetchSettings(),
        this.widgetInit(),
        this.forecastInit(),
      ]);
    },
    async widgetInit() {
      await this.fetchSalesWidgetData();
      this.isWidgetLoading = false;
    },
    async forecastInit() {
      await this.fetchForecastData();
      this.isForecastLoading = false;
    },
    navigate({ path, queryParams }) {
      this.$emit('sdkAction', 'Navigate', {
        type: 'relative',
        path,
        queryParams,
      });
    },
    goToPaymentWizard() {
      this.$emit('sdkAction', 'OpenModal', {
        modalName: 'PaymentWizard',
        modalProps: [{ source: 'Sales Widget' }],
      });
    },
    handleMixpanelEvents() {
      this.$track('Dashboard Sales Widget components state', {
        overdue_payments_state: this.getOverduePaymentsState(),
        ytd_state: this.getYtdState(),
        pending_estimates: this.getPendingEstimatesState(),
        forecast_state: this.getForcastState(),
      });
    },
    getOverduePaymentsState() {
      const overduePayments = this.salesWidgetData?.overdue_payments_summary?.data;
      if (!overduePayments) {
        return OVERDUE_PAYMENTS_VALUES.empty;
      }
      let key;
      const lastWeek = overduePayments.last_week_count > 0;
      const lastMonth = overduePayments.last_month_count > 0;
      const old = overduePayments.old_count > 0;
      if (lastWeek && lastMonth && old) {
        key = 'all';
      } else {
        key = [
          lastWeek && 'last_week',
          lastMonth && 'last_month',
          old && 'old',
        ].filter(Boolean).join('_and_') || 'empty';
      }
      return OVERDUE_PAYMENTS_VALUES[key];
    },
    getYtdState() {
      const values = {
        empty: EMPTY_STATE,
        ytd_comparison: 'YTD + comparison',
        ytd_no_comparison: 'YTD. No comparison',
      };
      const ytd = this.salesWidgetData?.total_payments?.data;
      if (!ytd) {
        return values.empty;
      }
      let key = 'empty';
      const shouldShowYtdBadge = (new Date()).getMonth() >= 3; // 01-04 or later;
      const currentYearHasPayments = +ytd.current_year !== 0;
      const lastYearHasPayments = +ytd.last_year !== 0;

      if (currentYearHasPayments && lastYearHasPayments && shouldShowYtdBadge) {
        key = 'ytd_comparison';
      } else if (currentYearHasPayments || lastYearHasPayments) {
        key = 'ytd_no_comparison';
      }
      return values[key];
    },
    getPendingEstimatesState() {
      const pendingEstimates = this.salesWidgetData?.pending_estimates?.data;
      if (!pendingEstimates) {
        return EMPTY_STATE;
      }
      return pendingEstimates.count > 0 ? 'Populated' : EMPTY_STATE;
    },
    getForcastState() {
      if (!this.forecastData || Object.keys(this.forecastData).length === 0) {
        return EMPTY_STATE;
      }
      const hasTotalAmount = Object.values(this.forecastData).some((data) => data.total_amount > 0);
      return hasTotalAmount ? 'Populated' : EMPTY_STATE;
    },
  },
};
</script>

<style scoped lang="scss">
$xSmall: 505px;
$small: 598px;
$medium: 738px;
$large: 859px;
$xLarge: 1031px;

.sales-widget {
  container-type: inline-size;
  color: var(--gray-darken-5);
  text-align: start;
  width: 100%;
  height: 100%;

  &__title {
    width: fit-content;
    font-size: var(--font-size-medium2);
    font-weight: var(--font-weight-large2);
    cursor: pointer;
    &:hover {
      color: var(--link-color);
    }
  }

  &__wrapper {
    @container (min-width: #{$medium}) {
      gap: 121px;
    }
  }

  &__data {
    flex-grow: 0;
    flex-shrink: 1;
    width: 279px;
  }

  &__forecast {
    display: none;

    @container (min-width: #{$small}) {
      display: flex;
    }
  }
}
</style>
