class CrmConstants {
  static CLIENT_LIST_HEADERS = [
    {
      display: false,
      field_type: 'checkbox',
      widthType: 'fixed',
      width: 48,
      identifier: 'checkbox',
      old_identifier: 'checkbox',
    }, {
      display: false,
      isSortable: true,
      sortBy: 'matter_name_tokenized.raw',
      widthType: 'fixed',
      width: 216,
      identifier: 'matter_name',
      old_identifier: 'matter_name',
    }, {
      display: 'client.crm_page.table_headers_capitalized.tags',
      display_regular: 'client.crm_page.table_headers.tags',
      field_type: 'text',
      dataType: 'tag',
      widthType: 'auto',
      minWidth: 256,
      maxWidth: 356,
      identifier: 'tags',
      old_identifier: 'tags',
    }, {
      display: 'client.crm_page.table_headers_capitalized.last_activity',
      display_regular: 'client.crm_page.table_headers.last_activity',
      widthType: 'fixed',
      width: 216,
      identifier: 'last_activity_action',
      old_identifier: 'last_activity',
    }, {
      display: 'client.crm_page.table_headers_capitalized.activity_time',
      display_regular: 'client.crm_page.table_headers.activity_time',
      isSortable: true,
      sortBy: 'last_client_activity',
      widthType: 'fixed',
      width: 185,
      identifier: 'last_activity_time',
      old_identifier: 'activity_time',
    }, {
      display: 'client.crm_page.table_headers_capitalized.full_name',
      display_regular: 'client.crm_page.table_headers.full_name',
      isSortable: true,
      sortBy: 'first_name.raw',
      widthType: 'fixed',
      width: 192,
      identifier: 'contact_full_name',
      old_identifier: 'full_name',
    }, {
      display: 'client.crm_page.table_headers_capitalized.contact_image',
      display_regular: 'client.crm_page.table_headers.contact_image',
      field_type: 'image',
      dataType: 'avatar',
      widthType: 'fixed',
      width: 64,
      identifier: 'photo_path',
      old_identifier: 'contact_image',
    }, {
      display: 'client.crm_page.table_headers_capitalized.contact_status',
      display_regular: 'client.crm_page.table_headers.contact_status',
      widthType: 'fixed',
      width: 148,
      identifier: 'contact_status',
      old_identifier: 'contact_status',
    }, {
      display: 'client.crm_page.table_headers_capitalized.phone',
      display_regular: 'client.crm_page.table_headers.phone',
      widthType: 'fixed',
      width: 148,
      identifier: 'phone_to_display',
      old_identifier: 'phone',
    }, {
      display: 'client.crm_page.table_headers_capitalized.email',
      display_regular: 'client.crm_page.table_headers.email',
      widthType: 'fixed',
      width: 248,
      identifier: 'email',
      old_identifier: 'email',
    }, {
      display: 'client.crm_page.table_headers_capitalized.address',
      display_regular: 'client.crm_page.table_headers.address',
      widthType: 'fixed',
      width: 216,
      identifier: 'contact_address',
      old_identifier: 'address',
    }, {
      display: 'client.crm_page.table_headers_capitalized.created_at',
      display_regular: 'client.crm_page.table_headers.created_at',
      isSortable: true,
      sortBy: 'created_at',
      widthType: 'auto',
      identifier: 'created_at',
      old_identifier: 'created_at',
    }, {
      display: 'client.crm_page.table_headers_capitalized.review_score',
      display_regular: 'client.crm_page.table_headers.review_score',
      widthType: 'fixed',
      width: 100,
      identifier: 'review_score',
      old_identifier: 'review_score',
    }, {
      display: 'client.crm_page.table_headers_capitalized.assigned_staff',
      display_regular: 'client.crm_page.table_headers.assigned_staff',
      widthType: 'fixed',
      width: 192,
      identifier: 'contact_assigned_staff_uid',
      old_identifier: 'assigned_staff',
    },
  ];

  static CUSTOM_FIELDS_TYPES_PROPERTIES_MAP = {
    SingleLine: {
      widthType: 'auto',
      minWidth: 256,
      maxWidth: 356,
    },
    DropDown: {
      widthType: 'fixed',
      width: 216,
    },
    Checkbox: {
      widthType: 'auto',
    },
    Datepicker: {
      widthType: 'fixed',
      width: 148,
    },
    OptIn: {
      widthType: 'auto',
    },
    Identifier: {
      widthType: 'auto',
    },
    Name: {
      widthType: 'auto',
    },
    FirstName: {
      widthType: 'fixed',
      width: 192,
    },
    LastName: {
      widthType: 'fixed',
      width: 192,
    },
    OpenPayments: {
      widthType: 'fixed',
      width: 148,
    },
    PendingEstimates: {
      widthType: 'fixed',
      width: 216,
    },
    ApprovedEstimates: {
      widthType: 'fixed',
      width: 216,
    },
  };

  static DEFAULT_VIEW = {
    columns: ['matter_name', 'tags', 'last_activity', 'email', 'phone', 'created_at', 'review_score', 'address', 'contact_image', 'full_name', 'contact_status', 'assigned_staff'],
    sorting_column: 'last_client_activity',
    sorting_direction: 'desc',
    filter: '{}',
  };

  static BULK_ACTION_PROPERTIES = {
    invite: {
      cap: 100,
      dialog: 'NewAnnouncement',
    },
    add_tags: {
      cap: 100,
      dialog: 'ClientBulkTags',
      function: 'add',
    },
    remove_tags: {
      cap: 100,
      dialog: 'ClientBulkTags',
      function: 'remove',
    },
    change_status: {
      cap: 100,
      dialog: 'ClientBulkStatus',
    },
    message: {
      cap: 20,
      dialog: 'NewMessage',
    },
    share_document: {
      cap: 20,
      dialog: 'NewDocument',
    },
    internal_document: {
      cap: 20,
      dialog: 'NewDocument',
    },
    request_signature: {
      cap: 20,
      dialog: 'NewDocument',
    },
    delete: {
      cap: 1000,
    },
    merge: {
      dialog: 'ClientMerge',
    },
  };

  /* eslint-disable lines-between-class-members */
  static PER_PAGE_OPTIONS = [10, 20, 50, 100].map((num) => ({ text: num, value: num }));
  static PER_PAGE_DEFAULT = { text: 20, value: 20 };
  static MOBILE_PAGE_SIZE = 20;
  static MAX_OPEN_VIEWS = 7;
  static MAX_NAME_LENGTH = 35;
  static MAX_DESCRIPTION_LENGTH = 100;
  static BULK_ACTION_MAPPING = ['matter_uid', 'full_name', 'first_name', 'last_name', 'matter_name', 'photo_path', 'color_id'];
}

export default CrmConstants;
