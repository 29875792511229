import callsTextingService from '@/modules/callsTexting/services/callsTextingService';

const state = {
  callsSettings: null,
  quotaBalance: null,
  quotaCredit: null,
  activeDedicatedNumber: null,
  showBusinessInfoError: false,
};

const getters = {
  callsSettings: (state) => state.callsSettings,
  quotaCredit: (state) => state.quotaCredit,
  quotaBalance: (state) => state.quotaBalance,
  activeDedicatedNumber: (state) => state.activeDedicatedNumber,
  showBusinessInfoError: (state) => state.showBusinessInfoError,
};

const mutations = {
  setShowBusinessInfoError(state, show) {
    state.showBusinessInfoError = show;
  },
  setCallsSettings(state, settings) {
    state.callsSettings = settings;
  },
  setCallQuota(state, { credit, balance }) {
    state.quotaCredit = credit;
    state.quotaBalance = balance;
  },
  setActiveDedicatedNumber(state, activeDedicatedNumber) {
    state.activeDedicatedNumber = activeDedicatedNumber;
  },
};

const helpers = {
  getInstalledPMCApp: (rootGetters) => {
    const pmcAppName = 'pickmycall';
    const pmcAppInstalled = rootGetters['AppsStore/getMarketAppByName'](pmcAppName)?.status === 'installed';
    if (pmcAppInstalled) return pmcAppName;

    const pmcFreeAppName = 'pickmycallfree';
    const pmcFreeAppInstalled = rootGetters['AppsStore/getMarketAppByName'](pmcFreeAppName)?.status === 'installed';
    if (pmcFreeAppInstalled) return pmcFreeAppName;
    return 'NULL';
  },
};

const actions = {
  setShowBusinessInfoError({ commit }, show) {
    commit('setShowBusinessInfoError', show);
  },
  async createCallsSettings({ commit, rootGetters }, settings) {
    try {
      const installedPMCApp = helpers.getInstalledPMCApp(rootGetters);
      const updatedSettings = { ...settings, external_app_name: installedPMCApp };
      const result = await callsTextingService.createCallsSettings(updatedSettings);
      commit('setCallsSettings', result.data);
      return result;
    } catch (error) {
      return { code: 'error', message: error.message };
    }
  },
  async updateCallsSettings({ commit, state }, updatedSettings) {
    const result = await callsTextingService.updateCallsSettings(state.callsSettings.uid, updatedSettings);
    commit('setCallsSettings', result.data);
  },
  async fetchCallsSettings({ commit }) {
    const result = await callsTextingService.getCallsSettings();
    if (result.success) {
      commit('setCallsSettings', result.data.voice_call_settings[0]);
    }
  },
  async getRemainingQuota({ commit }) {
    const result = await callsTextingService.getRemainingQuota();
    if (result.success && result.data.voice_call_quotas[0]) {
      commit('setCallQuota', result.data.voice_call_quotas[0]);
    }
  },
  async fetchActiveDedicatedNumber({ commit }, params) {
    try {
      const activeDedicatedNumber = await callsTextingService.getBusinessActiveDedicatedNumber(params);
      commit('setActiveDedicatedNumber', activeDedicatedNumber);
      return activeDedicatedNumber;
    } catch (err) {
      return err;
    }
  },
  async setTextingStatus({ commit, state }, status) {
    await callsTextingService.toggleTextingStatus(status);
    const { activeDedicatedNumber } = state;
    commit('setActiveDedicatedNumber', { ...activeDedicatedNumber, status });
  },
  async claimNumber({ commit }, { voiceTextAppInstalled }) {
    try {
      const activeDedicatedNumber = await callsTextingService.claimNumber({ voiceTextAppInstalled });
      commit('setActiveDedicatedNumber', activeDedicatedNumber);
      return activeDedicatedNumber;
    } catch (err) {
      return err;
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
