import paymentSettingsService from '@/modules/paymentSettings/service/paymentSettingsService';

const state = {
  paymentSettings: {
    currency: 'USD', // Default currency
  },
  showChargeClientCardAlert: true,
};

const getters = {
  paymentSettings: (state) => state.paymentSettings,
  currency: (state) => state.paymentSettings?.currency,
  taxMode: (state) => state.paymentSettings?.tax_mode,
  isPrimaryPaymentGatewayConnected: (state) => state.paymentSettings?.connected_payment_gateways?.includes(
    state.paymentSettings?.payments_gateway_type,
  ),
  isWalletConnected: (state) => state.paymentSettings?.connected_payment_gateways?.some((item) => item.includes('wallet_') || item.toLowerCase().includes('paypal')),
  // eslint-disable-next-line no-shadow
  isCheckoutEnabled: (state, getters) => getters.isPrimaryPaymentGatewayConnected || getters.isWalletConnected,
  showChargeClientCardAlert: (state) => state.showChargeClientCardAlert,
  isGatewayConnected: (state) => (gatewayType) => !!state.paymentSettings?.connected_payment_gateways?.find(
    (gatewayName) => gatewayName === gatewayType,
  ),
};

const mutations = {
  setPaymentSettings(state, paymentSettings) {
    state.paymentSettings = { ...state.paymentSettings, ...paymentSettings };
  },
  setShowChargeClientCardAlert(state, showChargeClientCardAlert) {
    state.showChargeClientCardAlert = showChargeClientCardAlert;
  },
};

const actions = {
  async fetchSettings({ commit }) {
    const settings = await paymentSettingsService.fetchSettings();
    commit('setPaymentSettings', settings);
  },
  async saveSettings({ commit }, settings) {
    const saveSettingsResponse = await paymentSettingsService.saveSettings({
      payment_settings: settings,
    });
    commit('setPaymentSettings', saveSettingsResponse.payment_settings);
  },
  async updateDefaultCurrency() {
    return paymentSettingsService.updateDefaultCurrency();
  },
  updateShowChargeClientCardAlert({ commit }, showChargeClientCardAlert) {
    commit('setShowChargeClientCardAlert', showChargeClientCardAlert);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
