<template>
  <VcLayout column class="compact-checkout-details-wrapper">
    <VcAlert v-if="isShowEmptyWarning" type="warning"
             :header-text="$t('point_of_sale.compact_checkout_details.empty_warning')"
             :body-text="$t('point_of_sale.compact_checkout_details.empty_warning_body')"
             close-button @closeAlert="hideEmptyWarning"/>

    <VcLayout gap="3" class="compact-checkout-details py-3">
      <VcLayout column>
        <VcLayout class="compact-checkout-details__net-total">
          {{ $t('point_of_sale.price_breakdown.net_total') }}
          {{ ' ' }}
          {{ $priceDisplay(netTotal, currency) }}
        </VcLayout>

        <VcLayout class="compact-checkout-details__items-count">
          <div v-if="itemsCount > 0">
            {{ $t('point_of_sale.compact_checkout_details.items_count', {count: itemsCount}) }}
          </div>
          <div v-else>{{ $t('point_of_sale.compact_checkout_details.no_items') }}</div>
        </VcLayout>
      </VcLayout>

      <VcButton large @click="showCheckout" :label="showCheckoutButtonText"/>
    </VcLayout>
  </VcLayout>
</template>

<script>
export default {
  name: 'CompactCheckoutDetails',
  data() {
    return {
      isShowEmptyWarning: false,
    };
  },
  props: {
    itemsCount: {
      type: Number,
      required: true,
    },
    netTotal: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      default: 'USD',
    },
  },
  computed: {
    showCheckoutButtonText() {
      return this.$t('point_of_sale.compact_checkout_details.go_to_checkout');
    },
  },
  watch: {
    itemsCount(value) {
      if (value > 0) {
        this.hideEmptyWarning();
      }
    },
  },
  methods: {
    hideEmptyWarning() {
      this.isShowEmptyWarning = false;
    },
    showCheckout() {
      if (this.itemsCount === 0) {
        this.isShowEmptyWarning = true;
        return;
      }
      this.$emit('show-checkout');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "styles/variables.scss";

.compact-checkout-details-wrapper {
  gap: var(--size-value3);
  box-shadow: var(--bottom-nav-shadow);
}

.compact-checkout-details {
  padding-inline: var(--size-value4);

  @include md-and-up {
    padding-inline: var(--size-value6);
  }

  &__net-total {
    color: var(--gray-darken-5);
    font-size: var(--font-size-x-small);
    font-weight: var(--font-weight-medium2);
    line-height: var(--size-value5);
  }

  &__items-count {
    color: var(--gray-darken-4);
    font-size: var(--font-size-xx-small);
    font-weight: var(--font-weight-medium);
    line-height: var(--size-value4);
  }
}
</style>
