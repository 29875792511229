<template>
  <div>
    <VcQuickActions
        :value="showDialogue"
        :title="$t('common.quick_actions_menu.quick_actions')"
        :largeActions="displayMainActions"
        :smallActions="displaySecondaryActions"
        @input="toggleDialogue"
        @onLargeActionClicked="onActionClicked"
        @onSmallActionClicked="onActionClicked"
        :isLoading="quickActions.length === 0"
        :nudge-right="14"
        dataQa="VcQuickActions">
      <template #activator="{on, attrs}">
        <VcButton :ghost="true" :pill="true" :label="buttonText" :outlined="true"
                  v-bind='attrs' v-on='!isActionsDisabled && on'
                  :color="buttonTextColor" :style="buttonStyle"
                  :loading="isMenuTranslationLoading"
                  data-qa="vcMenu-QuickAction">
          <template #prepend>
            <VcIcon :color="secondaryColor" size="16" class="plus-icon">$plus</VcIcon>
          </template>
        </VcButton>
      </template>
    </VcQuickActions>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import QuickActionsConstants from '@/constansts/quickActionsConstants';

export default {
  name: 'QuickActions',
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('AngularStore', ['isAngularLoaded', 'isAngularDisplayed']),
    ...mapGetters('QuickActionsStore', ['quickActions', 'mainActions', 'secondaryActions', 'showDialogue', 'actionCategories']),
    ...mapGetters('BusinessStore', ['matterTerm', 'textColor', 'secondaryColor']),
    ...mapGetters('MainMenuStore', ['isMenuTranslationLoading']),
    buttonTextColor() {
      return this.textColor;
    },
    buttonStyle() {
      return `border-color: ${this.textColor} !important;`;
    },
    displaySecondaryActions() {
      const displaySecondaryActions = [];
      QuickActionsConstants.actionCategories.forEach((category) => {
        const categoryActions = this.secondaryActions.filter((action) => action.category === category);
        if (categoryActions.length > 0) {
          displaySecondaryActions.push({
            // eslint-disable-next-line localization/no-logic-in-translations
            label: category === 'crm' ? this.$t(`common.navigation.matter_terms.${this.matterTerm}`).toUpperCase() : this.$t(`common.quick_actions_menu.categories.${category}`),
            id: category,
            items: categoryActions.map((action) => this.buildDisplayAction(action)),
          });
        }
      });
      return displaySecondaryActions.length > 0 ? displaySecondaryActions : [{}];
    },
    displayMainActions() {
      const displayMainActions = this.mainActions.map((action) => this.buildDisplayAction(action));
      return displayMainActions.length > 0 ? displayMainActions : [{}];
    },
    buttonText() {
      return this.$t('common.quick_actions_menu.quick_actions');
    },
  },
  async created() {
    if (this.isAngularLoaded) {
      this.askForQuickActionsData();
    }
  },
  methods: {
    ...mapActions('QuickActionsStore', ['askForQuickActionsData', 'toggleShowDialogue']),
    ...mapActions('AngularStore', ['toggleIsOverlayDisplay']),
    ...mapActions('ModalStore', ['setForceModalMode']),
    fireQuickAction(quickActionName) {
      this.setForceModalMode(true);
      outgoingPostMessageUtils.post('fire-quick-action', {
        actionName: quickActionName,
        hideBackground: !this.isAngularDisplayed,
      });
    },
    onActionClicked(action) {
      this.toggleDialogue();
      if (action.name === 'point_of_sale') {
        this.$track('POS Take Payment Click', { Origin: 'Quick actions', Is_from_entity: false });
      }
      this.fireQuickAction(action.name);
    },
    toggleDialogue() {
      if (this.isActionsDisabled) return;
      this.toggleIsOverlayDisplay();
      this.toggleShowDialogue();
    },
    buildDisplayAction(action) {
      let image;
      try {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        image = require(`@/assets/images/quick-actions/${action.name}.svg`);
      } catch (e) {
        image = {};
      }

      return {
        // eslint-disable-next-line localization/no-logic-in-translations
        title: this.$t(action.translationKey, { client: this.$t(`common.navigation.matter_terms.singular.lowercase.${this.matterTerm}`) }),
        image,
        name: action.name,
        id: action.name,
      };
    },
  },
  watch: {
    isAngularLoaded() {
      if (this.isAngularLoaded) this.askForQuickActionsData();
    },
  },
};
</script>

<style lang="scss" scoped>
  .plus-icon{
    margin-inline-end: var(--size-value3);
  }
</style>
