<template>
  <VcInputModal size="md"
                close-button
                :show-dialog="visible"
                :title="$t('products.dialogs.product_dialog.title')"
                :ok-button-label="$t('products.dialogs.product_dialog.save')"
                :cancel-button-label="$t('products.dialogs.product_dialog.cancel')"
                :show-back-button="false"
                @onCloseButtonClicked="closeModal"
                @onCancelClicked="closeModal"
                @onOkClicked="addProduct"
                class="product-modal">
    <template #content>
      <VcLayout column>
        <VcTextField data-qa="item-name"
                    class="py-1 mb-2"
                    counter :maxlength="70"
                    :value="product.name"
                    :rules="[rules.required]"
                    :placeholder="$t('products.dialogs.product_dialog.name')"
                    @input="newValue => product.name = newValue"/>

        <VcTextField data-qa="item-description"
                    class="py-1 mb-2"
                    counter :maxlength="100"
                    :value="product.description"
                    :placeholder="$t('products.dialogs.product_dialog.description')"
                    @input="newValue => product.description = newValue"/>

        <VcFocusArea class="flex-column flex-md-row product-modal__inputs">
              <VcCounter :value="product.rate" type="number" :min="0"
                        data-qa="item-price"
                        :rules="grandTotalRules"
                        class="product-modal__counter"
                        :prefix="$currencySymbol(currency)"
                        :label="$t('products.dialogs.product_dialog.grand_total')"
                        @input="newValue => product.rate = newValue"/>
          <div :class="{'tax-picker__mobile': $isMobile(), 'tax-picker': !$isMobile()}">
            <TaxPicker  v-if="showTaxPicker"
                        :taxes="taxes"
                        :isMobile="$isMobile()"
                        :selectedTaxes="product.taxes"
                        @update-selected="updatedSelectedTaxes"
                        :class="{'mobile': $isMobile()}"></TaxPicker>

          <div v-else class="d-flex flex-column product-modal__tax-flow">
              <span me-1>{{ $t('products.dialogs.product_dialog.tax_flow.no_tax.none_link') }}</span>
              <VcLink url="#"
                      :label="$t('products.dialogs.product_dialog.tax_flow.no_tax.link')"
                      target="_self"
                      @click="applyTaxFlowLink"
                      class="tax-settings-link">
              </VcLink>
            </div>
          </div>
        </VcFocusArea>
        <VcAccordion v-if="showTaxSummary" gap="s" :items="priceBreakdownAccordionItem" focusArea
                      class="product-modal__price-breakdown" :openPanels="openPanels" @change="toggleAccordion">
          <template #header-0 >
            <div align-items d-flex :class="['product-modal__price-breakdown__header', {'status-open': openPanels.length}]">
              <VcIcon class="product-modal__price-breakdown__header--status" size="10">$caret_down</VcIcon>
              {{$t('payment.tax_summary.price_breakdown')}}
            </div>
          </template>
          <template #content-0>
            <div class="tax-summary d-flex flex-row">
              <div class="tax-summary-content flex-grow-1" :class="{'mobile': $isMobile()}">
                <TaxSummary :price="getPrice"
                          :taxes="product.taxes"
                          :mode="taxMode"
                          :quantity="parseFloat(product.quantity)"
                          :currency="currency"
                          :locale="locale"/>
              </div>
            </div>
          </template>
        </VcAccordion>

        <div v-if="showImageUpload" class="product-modal__label">
          <span class="product-modal__text">{{ $t('products.dialogs.product_dialog.product_image') }}</span>
        </div>

        <VcFocusArea v-if="showImageUpload" class="d-flex flex-row product-modal__photo">
          <div class="d-flex flex-row mx-4  justify-center align-center product-modal__photo__container">
            <VcImage v-if="product.image_url" :image="product.image_url" class="upload-image"/>
            <ItemImage v-else :image="product.image_url" :type="imageType" class="temp-image" />
          </div>
          <div class="d-flex flex-column product-modal__photo__left">
              <span class="product-modal__text">{{ $t('products.dialogs.product_dialog.photo.photo') }}</span>
              <span class="product-modal__photo__tip">
                {{ $t('products.dialogs.product_dialog.photo.tip') }}
              </span>
              <div class="d-flex product-modal__photo__buttons">
                <VcButton @click="uploadImage" small flavor="secondary" outlined>
                  {{ $t('products.dialogs.product_dialog.photo.choose_file') }}
                </VcButton>
                <VcButton v-if="product.image_url" small flavor="secondary" outlined @click="product.image_url = null"
                                :class="{'mobile': $isMobile()}">
                  {{ $t('products.dialogs.product_dialog.photo.remove_image') }}
                </VcButton>
              </div>
            </div>
        </VcFocusArea>
      </VcLayout>
    </template>
  </VcInputModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TaxPicker from '@/modules/payments/components/taxes/TaxPicker.vue';
import TaxSummary from '@/modules/payments/components/taxes/TaxSummary.vue';
import productUtil from '@/modules/products/utils/productUtil';
import { ENTITY_TYPES } from '@/modules/payments/constants/pos';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import ItemImage from '@/modules/payments/components/posTable/ItemImage.vue';

const defaultProduct = {
  quantity: 1,
  rate: null,
  name: '',
  description: '',
  taxes: [],
  image_url: null,
};

export default {
  name: 'ProductDialog',
  components: {
    TaxPicker,
    TaxSummary,
    ItemImage,
  },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    taxes: {
      type: Array,
      default: () => ([]),
    },
    visible: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: 'en',
    },
    startOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      product: {
        quantity: 1,
        rate: null,
        name: '',
        description: '',
        taxes: [],
        type: Object,
        required: true,
        image_url: defaultProduct.image_url,
        validator: (value) => value.name && value.rate > 0 && value.quantity > 0,
      },
      rules: {
        required: (v) => !!v || this.$t('products.dialogs.validations.required'),
        min: (v) => v >= 0 || this.$t('products.dialogs.validations.min'),
        positiveNumber: (v) => v > 0 || this.$t('products.dialogs.validations.positive'),
      },
      grandTotal: 0,
      openPanels: [],
      openedOnce: false,
    };
  },
  created() {
    this.addI18nModule('products-pov');
    if (this.startOpen) this.toggleAccordion();
  },
  computed: {
    ...mapGetters('PaymentSettingsStore', ['currency', 'taxMode']),
    isAddEnabled() {
      return this.rate && this.rate > 0 && this.name;
    },
    grandTotalRules() {
      return [this.rules.required, this.rules.min];
    },
    getPrice() {
      return parseFloat(this.product.rate || 0);
    },
    showTaxSummary() {
      return this.product.rate && parseFloat(this.product.rate) !== 0 && this.product.taxes?.length > 0;
    },
    priceBreakdownAccordionItem() {
      return [{ name: this.$t('payment.tax_summary.price_breakdown') }];
    },
    showTaxPicker() {
      return this.taxes.length > 0;
    },
    imageType() {
      return ENTITY_TYPES.Product;
    },
    showImageUpload() {
      // NOTE: hidden till we solve the iisue with image upload VCITA-40890
      return false;
    },
  },
  methods: {
    ...mapActions('ModalStore', ['openModal']),
    closeModal() {
      this.$set(this, 'product', { ...defaultProduct });
      this.product.taxes = [];
      this.openedOnce = true;
      this.openPanels = [];
      this.$emit('update:visible', false);
    },
    addProduct({ valid }) {
      if (valid) {
        const newProduct = productUtil.parseProductItemToCreate({ ...this.product, currency: this.currency, save: true });
        this.$emit('on-add-first-product', newProduct);
        this.closeModal();
      }
    },
    async toggleAccordion() {
      this.openedOnce = true;
      this.openPanels = this.openPanels.length ? [] : [0];
    },
    updatedSelectedTaxes(selectedTaxes) {
      this.product.taxes = [...selectedTaxes];
      this.openPanels = [0];
    },
    applyTaxFlowLink() {
      outgoingPostMessageUtils.post('navigate-to', {
        route: 'app.billing_and_invoicing_settings_page',
        data: { tab: 'taxes' },
        options: { location: 'replace' },
      });
    },
    async uploadImage() {
      this.openModal({
        name: 'ImageUploader',
        payload: ['product', null, true, { autoTrigger: false, uploadFirst: false }],
        functionName: 'show',
      }).then((result) => {
        if (typeof result === 'string') {
          this.product.image_url = result;
        } else {
          this.product.image_url = null;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "styles/variables.scss";

.product-modal {
  &__label {
    gap: var(--size-value4);
    height: var(--size-value6);
    align-self: stretch;
    font-weight: var(--font-weight-large);
    padding-top: var(--size-value4);
    padding-bottom: var(--size-value8);
  }
  &__text {
    font-size: var(--font-size-x-small);
    font-weight: var(--font-weight-large);
    line-height: var(--size-value6);
  }
  &__inputs {
    gap: var(--size-value4);
    padding-bottom: var(--size-value4);
  }

  &__photo {
    padding: var(--size-value4);
    gap: var(--size-value4);

    &__tip {
      font-size: var(--font-size-x-small);
      font-weight: var(--font-weight-medium);
    }

    &__buttons {
      gap: var(--size-value4);
      padding-top: var(--size-value4);
    }

    &__container {
      height: 150px;
      width: 150px;

      .temp-image {
        width: var(--size-value12);
        height: var(--size-value12);
      }
      .upload-image {
        width: var(--size-value20);
        height: var(--size-value20);
      }
    }
  }

  &__tax-flow{
      font-size: var(--font-size-x-small);
      font-weight: var(--font-weight-medium);
      line-height: var(--size-value6);
      vertical-align: middle;
      .tax-settings-link {
        color: var(--v-secondary-base);
      }
    }

  &__price-breakdown {
    gap: var(--size-value1);
    line-height: var(--size-value6);

    &__header {
      gap: var(--size-value2);
      font-size: var(--font-size-x-small);
      margin-block: var(--size-value2);
      line-height: var(--size-value5);
      overflow: hidden;
      color: var(--gray-darken-5);
      text-overflow: ellipsis;

      &--status {
        transform: rotate(-90deg);

        .status-open & {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.tax-picker {
    width: 50%;
    &__mobile{
      width: 100%;
    }
  }

.tax-summary{
    width: 100%;
    .empty-space{
      width: 50%;
      margin-inline-end: 20px;
    }
    .tax-summary-content {
      width: 100%;
      &.mobile{
        width: 100%;
      }
    }
  }

</style>
