<template>
  <VcLayout column class="overdue-breakdown-list">
    <div v-for="item in breakdownItems"
         :key="item.interval"
         class="overdue-breakdown text-truncate"
         @click="onBreakdownClick(item)"
         @keydown.enter="onBreakdownClick(item)" tabindex="0">
      <span class="badge me-2" :class="`color-${item.color}`">&nbsp;</span>

      <b>{{ item.interval }}</b>
      &nbsp;|&nbsp;
      <span>{{ item.count }}</span>
      &nbsp;|&nbsp;
      <span>{{ item.amount }}</span>
    </div>
  </VcLayout>
</template>

<script>
export default {
  name: 'OverduePaymentsBreakdown',
  props: {
    breakdownData: {
      type: Object,
      required: true,
      validator(value) {
        return [
          'last_week',
          'last_week_count',
          'last_month',
          'last_month_count',
          'old',
          'old_count',
        ].every((key) => value[key] !== undefined);
      },
    },
    currency: {
      type: String,
      required: true,
    },
  },
  computed: {
    breakdownItems() {
      const breakdownItems = [];

      if (this.breakdownData.last_week_count) {
        breakdownItems.push({
          color: 'yellow',
          timeFrame: 'last_week',
          interval: this.$t('widget.sales_widget.payment_widget_list.overdue_breakdown.last_week'),
          count: this.$t('widget.sales_widget.payment_widget_list.overdue_breakdown.count', { count: this.breakdownData.last_week_count }),
          amount: this.$priceDisplay(this.breakdownData.last_week, this.currency),
        });
      }

      if (this.breakdownData.last_month_count) {
        breakdownItems.push({
          color: 'orange',
          timeFrame: 'last_month',
          interval: this.$t('widget.sales_widget.payment_widget_list.overdue_breakdown.last_month'),
          count: this.$t('widget.sales_widget.payment_widget_list.overdue_breakdown.count', { count: this.breakdownData.last_month_count }),
          amount: this.$priceDisplay(this.breakdownData.last_month, this.currency),
        });
      }

      if (this.breakdownData.old_count) {
        breakdownItems.push({
          color: 'red',
          timeFrame: 'old',
          interval: this.$t('widget.sales_widget.payment_widget_list.overdue_breakdown.old'),
          count: this.$t('widget.sales_widget.payment_widget_list.overdue_breakdown.count', { count: this.breakdownData.old_count }),
          amount: this.$priceDisplay(this.breakdownData.old, this.currency),
        });
      }

      return breakdownItems;
    },
  },
  methods: {
    onBreakdownClick(item) {
      this.$track('Dashboard Sales Widget overdue payments by overdue period Click', { overdue_indication: item.interval });
      this.$emit('breakdown-click', { timeFrame: item.timeFrame });
    },
  },
};
</script>

<style scoped lang="scss">
.overdue-breakdown-list {
  gap: var(--size-value2);
}

.overdue-breakdown {
  cursor: pointer;
  font-size: var(--font-size-xx-small);

  &:hover {
    color: var(--link-color);
  }

  .badge {
    display: inline-block;
    width: var(--size-value2);
    line-height: var(--size-value2);
    border-radius: 50%;

    &.color-yellow {
      background-color: var(--yellow);
    }

    &.color-orange {
      background-color: var(--orange);
    }

    &.color-red {
      background-color: var(--red);
    }
  }
}
</style>
