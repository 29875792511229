import httpService from '@/modules/common/services/httpService';

const v1BaseRoute = '/platform/v1/services';
const v2BaseRoute = '/v2/settings/services';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE_INDEX = 0;

const fetchServices = (
  businessId,
  pageIndex = DEFAULT_PAGE_INDEX,
  pageSize = DEFAULT_PAGE_SIZE,
  filter = {},
) => httpService.get(v1BaseRoute, {
  business_id: businessId,
  page: pageIndex,
  per_page: pageSize,
  order_by: 'name',
  include_hidden: true,
  ...filter,
}).then((res) => res.data?.data.services);

const bulkCreateServices = (
  params,
) => httpService.post(`${v2BaseRoute}/bulk_create`, params).then((res) => res.data);

export default {
  fetchServices,
  bulkCreateServices,
};
