<template>
  <VcLayout column>
    <ClientDetails
      class="flex-grow-0"
      :client-details="clientDetails" :matter-term="matterTerm"
      :items-in-checkout="Boolean(checkoutItems.length)"
      :unpaid-items-description="unpaidItemsDescription"
      :number-of-unpaid-items="unpaidItems.length"
      @select-client="selectClient"
      @add-unpaid-items="addUnpaidItems"
    />

    <BillableItemList
      class="flex-fill billable-items-container"
      :items="checkoutItems"
      :tax-mode="taxMode"
      @add-item="openCatalog"
      @edit-item="editItem"
      @remove-item="removeItem"
    />

    <template v-if="checkoutItems.length || !$isMobile()">
      <PriceBreakdown
        class="flex-grow-0"
        :currency="priceBreakdown.currency"
        :netTotal="priceBreakdown.netTotal"
        :taxTotal="priceBreakdown.taxTotal"
        :grandTotal="priceBreakdown.grandTotal"
        :discount="priceBreakdown.discount"
        :discountValue="priceBreakdown.discountValue"
      />

      <CheckoutButton
        class="flex-grow-0"
        :disabled="!canCreateSale"
        :loading="isCheckoutLoading"
        :isFreeCheckout="isFreeCheckout"
        :canChargeCard="isPrimaryPaymentGatewayConnected"
        :isCheckoutEnabled="isCheckoutEnabled"
        :has-alert="hasAlert"
        :numberOfItemsInCart="checkoutItems?.length"
        @action-clicked="checkoutActionClicked"
        @alert-clicked="openPaymentMethodSettingsDialog"
      />
    </template>
  </VcLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BillableItemList from '@/modules/payments/components/posCheckout/BillableItemList.vue';
import ClientDetails from '@/modules/payments/components/posCheckout/ClientDetails.vue';
import PriceBreakdown from '@/modules/payments/components/posCheckout/PriceBreakdown.vue';
import CheckoutButton from '@/modules/payments/components/posCheckout/CheckoutButton.vue';
import { CHECKOUT_ACTIONS, MODALS } from '@/modules/payments/constants/pos';

export default {
  name: 'PosCheckoutContainer',
  components: {
    CheckoutButton,
    PriceBreakdown,
    ClientDetails,
    BillableItemList,
  },
  props: {
    populateUnpaid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('PosStore', ['selectedMatter', 'selectedClient', 'clientDetails', 'checkoutItems', 'priceBreakdown', 'filterByCurrency']),
    ...mapGetters('PaymentRequestStore', ['getOpenPaymentRequestsByMatterUid']),
    ...mapGetters('PaymentSettingsStore', ['taxMode', 'isPrimaryPaymentGatewayConnected', 'isCheckoutEnabled']),
    ...mapGetters('BusinessStore', ['matterTermTranslation']),
    matterTerm() {
      // eslint-disable-next-line localization/no-logic-in-translations
      return this.$t(this.matterTermTranslation.capitalized_singular);
    },
    canCreateSale() {
      return this.clientDetails?.clientName && this.checkoutItems?.length > 0 && this.priceBreakdown.grandTotal >= 0;
    },
    isFreeCheckout() {
      return this.checkoutItems?.length > 0 && this.priceBreakdown.grandTotal === 0;
    },
    unpaidItems() {
      const data = this.getOpenPaymentRequestsByMatterUid(this.clientDetails?.matterUid) ?? [];
      const dataByCurrency = this.filterByCurrency(data);
      const checkoutItemUids = this.checkoutItems.map((item) => item.entity_uid);
      return dataByCurrency.filter((item) => !checkoutItemUids.includes(item.payable_uid));
    },
    unpaidItemsDescription() {
      if (this.unpaidItems.length === 0 || !this.showUnpaidItemsAlert) {
        return '';
      }
      const count = this.unpaidItems.length;
      const totalAmount = this.unpaidItems
        .map((item) => Number(item.amount) - Number(item.amount_paid || 0) - Number(item.amount_pending || 0))
        .reduce((a, b) => a + b, 0);
      const totalAmountText = this.$priceDisplay(totalAmount, this.unpaidItems[0].currency);
      const params = { count, price: totalAmountText };
      return this.$t('point_of_sale.client_details.unpaid_items_description', params);
    },
    hasAlert() {
      return !this.isPrimaryPaymentGatewayConnected || this.isFreeCheckout;
    },
  },
  data() {
    return {
      isCheckoutLoading: false,
      showUnpaidItemsAlert: true,
    };
  },
  watch: {
    unpaidItems: {
      handler: 'autoPopulateUnpaid',
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.autoPopulateUnpaid();
    });
  },
  methods: {
    ...mapActions('PosStore', [
      'createSaleAndTakePayment',
      'removeBillableItem',
      'setBillableItemsAndFetchData',
    ]),
    ...mapActions('ModalStore', ['openModal']),
    autoPopulateUnpaid() {
      if (this.populateUnpaid && this.unpaidItems.length && this.showUnpaidItemsAlert) {
        this.showUnpaidItemsAlert = false;
        this.addUnpaidItems(true);
      }
    },
    async addUnpaidItems(isAutoPopulated = false) {
      const billableItems = this.unpaidItems.map((item) => ({
        uid: item.payable_uid,
        type: item.payable_type,
        paidAmount: Number(item.amount_paid),
      }));
      await this.setBillableItemsAndFetchData({
        billableItems,
        replaceList: false,
        highlight: isAutoPopulated,
      });
      this.showUnpaidItemsAlert = false;
    },
    async checkoutActionClicked(type) {
      this.isCheckoutLoading = true;
      const sale = await this.createSaleAndTakePayment(type);
      this.isCheckoutLoading = false;
      if (type === CHECKOUT_ACTIONS.FreeCheckout) {
        this.$emit('handle-free-checkout', { saleUid: sale.uid });
        return;
      }
      await this.buildModalParams(sale, type);
    },
    selectClient() {
      this.$emit('select-client');
    },
    openCatalog() {
      this.$emit('open-catalog-mobile');
    },
    editItem(item) {
      this.$emit('edit-item', item);
    },
    removeItem(itemIndex) {
      this.$emit('remove-item', itemIndex);
    },
    openPaymentMethodSettingsDialog() {
      this.openModal({
        name: 'PaymentsSettingsDialog',
        payload: [],
        functionName: 'openPaymentProvidersDialog',
      });
    },
    buildModalParams(sale, checkoutAction) {
      const modalData = {
        clientUid: this.selectedClient.uid,
        matterUid: this.selectedMatter.uid,
        mode: 'POS',
        existingCart: sale,
        fixedStage: checkoutAction,
      };
      let modalName;
      let modalPayload;
      if (checkoutAction === CHECKOUT_ACTIONS.QrCode) {
        modalName = MODALS.QrCode;
        modalPayload = [modalData];
      } else {
        modalName = MODALS.TakePayment;
        modalPayload = [modalData.clientUid, modalData.matterUid, modalData.mode, null, null, modalData.existingCart, modalData.fixedStage];
      }
      this.openModal({
        name: modalName,
        payload: modalPayload,
      });
    },
  },
};
</script>
