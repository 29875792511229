import httpService from '@/modules/common/services/httpService';

const gatewayService = httpService.create(window.pov_config.VUE_APP_API_URL_GATEWAY);
const baseRoute = 'v3/apps/';
const JWKSRoute = `${baseRoute}compact_jws_tokens`;

const getJWKS = async (param) => gatewayService.post(JWKSRoute, param).then((res) => res.data).then((res) => res.data);

export default {
  getJWKS,
};
