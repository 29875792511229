<template>
  <VcLayout column class="billable-items-list">
    <VcScrollShadow v-if="items.length > 0" hide-top-shadow data-qa="billable-items-list">
      <VcLayout column class="billable-items-container">
        <BillableItem v-for="(item, index) in items" :key="index"
                      :item="item" :taxMode="taxMode"
                      :data-qa="`billable-item-${index}`"
                      @edit-item="editItem(index)" @remove-item="removeItem(index)"/>

        <VcLayout>
          <VcButton v-if="$isMobile()" @click="addItem"
                    plain class="billable-items-container__add-item-button"
                    data-qa="add-item-button">
            <VcIcon size="16" color="currentColor" class="me-2">icon-Plus-circle</VcIcon>
            {{ $t('point_of_sale.checkout_items.add_item') }}
          </VcButton>
        </VcLayout>

      </VcLayout>
    </VcScrollShadow>

    <VcLayout v-else class="billable-items-empty-state" align-center>
      <VcEmptyState :image="require('@/assets/images/payments/product.svg')"
                    size="sm"
                    :title="$t('point_of_sale.checkout_items.empty_state.title')"
                    :subtitle="emptyStateSubtitle">
        <template #buttonArea>
          <VcLayout v-if="$isMobile()" justify-center class="mt-4">
            <VcButton @click="addItem"
                      :label="$t('point_of_sale.checkout_items.add_item')"
                      flavor="secondary"
                      data-qa="add-item-button">
              <template #prepend>
                <VcIcon size="16" color="currentColor" class="me-2">icon-Plus-new</VcIcon>
              </template>
            </VcButton>
          </VcLayout>
        </template>
      </VcEmptyState>
    </VcLayout>
  </VcLayout>
</template>

<script>
import BillableItem from '@/modules/payments/components/posCheckout/BillableItem.vue';
import { TAX_MODES } from '@/modules/payments/utils/taxUtil';

export default {
  name: 'BillableItemList',
  components: { BillableItem },
  props: {
    items: {
      type: Array,
      required: true,
      validator: (prop) => prop.every((item) => item.name && item.grandTotal >= 0 && item.currency),
    },
    taxMode: {
      type: String,
      validator: (value) => Object.values(TAX_MODES).includes(value),
    },
  },
  computed: {
    emptyStateSubtitle() {
      if (!this.$isMobile()) {
        return this.$t('point_of_sale.checkout_items.empty_state.subtitle');
      }
      return null;
    },
  },
  methods: {
    addItem() {
      this.$emit('add-item');
    },
    editItem(index) {
      this.$emit('edit-item', index);
    },
    removeItem(index) {
      this.$emit('remove-item', index);
    },
  },
};
</script>

<style scoped lang="scss">
@import "styles/variables.scss";

.billable-items-list {
  height: 100%;
  min-height: 0;
}

.billable-items-container {
  &__add-item-button {
    margin-block: var(--size-value2);
  }
}

.billable-items-empty-state {
  height: 100%;
}
</style>
