const ENTITY_TYPES = {
  Product: 'Product',
};

function generateLocalID() {
  return Math.floor(Math.random() * (100000 - 1000 + 1)) + 1000;
}

function parseProductItemToCreate(item) {
  const product = {};
  product.description = item.description;
  product.name = item.name;
  product.price = Number(item.rate);
  product.taxes = item.taxes;
  product.currency = item.currency;
  product.item_index = 0;
  product.local_id = this.generateLocalID();
  product.save = item.save;
  product.image_url = item.image_url || null;
  product.tax_ids = (item.taxes || []).map((tax) => (tax ? (tax.uid || tax.id) : null));

  return { quantity: Number(item.quantity), type: ENTITY_TYPES.Product, product: { ...product } };
}

export default {
  generateLocalID,
  parseProductItemToCreate,
};
