import store from '@/store';

const addToVueInstance = (Vue) => {
  Vue.prototype.$hasFeature = function (feature) {
    return store.getters['BusinessStore/hasFeature'](feature);
  };

  Vue.prototype.$featureFlagsLoaded = function () {
    return store.getters['BusinessStore/featureFlagsLoaded'];
  };
};

export default {
  addToVueInstance,
};
