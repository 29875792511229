import clientService from '@/modules/client/services/clientService';
import Vue from 'vue';

const state = {
  clients: {},
  vaultedCards: {},
  clientResults: {},
  statuses: {},
};

const getters = {
  client: (state) => (uid) => state.clients[uid],
  clientVaultedCards: (state) => (clientUid) => state.vaultedCards[clientUid],
  clientResults: (state) => (view) => state.clientResults[view]?.results,
  clientResultsCount: (state) => (view) => state.clientResults[view]?.count,
  statusById: (state) => (id) => state.statuses[id],
  statuses: (state) => state.statuses,
};

const mutations = {
  setClient(state, client) {
    state.clients = { ...state.clients, [client.uid]: client };
  },
  setVaultedCards(state, payload) {
    state.vaultedCards = { ...state.vaultedCards, [payload.clientUid]: payload.cards };
  },
  setClientResults(state, payload) {
    state.clientResults = {
      ...state.clientResults,
      [payload.viewUid]: {
        results: payload.data.clients,
        count: payload.data.count,
      },
    };
  },
  setAggregatedClientResults(state, payload) {
    const currentResults = state.clientResults[payload.viewUid]?.results || [];
    state.clientResults = {
      ...state.clientResults,
      [payload.viewUid]: {
        results: [...currentResults, ...payload.data.clients],
        count: payload.data.count,
      },
    };
  },
  removeViewFromClientResults(state, uid) {
    Vue.delete(state.clientResults, uid);
  },
  resetClientResults(state) {
    state.clientResults = {};
  },
  resetOtherTabsClientResults(state, viewUid) {
    state.clientResults = {
      [viewUid]: state.clientResults[viewUid],
    };
  },
  setStatuses(state, data) {
    const statuses = [...data.statuses, ...data.custom_statuses];
    state.statuses = statuses.reduce((a, v) => ({ ...a, [v.id]: v }), {});
  },
  updateTags(state, {
    matters,
    tags,
    action,
    viewUid,
  }) {
    const clients = state.clientResults[viewUid].results.filter((client) => matters.includes(client.matter_uid));

    clients.forEach((client) => {
      if (action === 'add') {
        // eslint-disable-next-line no-param-reassign
        client.tag_list = [...new Set([...client.tag_list, ...tags])];
      } else {
        // eslint-disable-next-line no-param-reassign
        client.tag_list = client.tag_list.filter((tag) => !tags.includes(tag));
      }
    });
  },
  updateStatus(state, { contacts, status, viewUid }) {
    const clients = state.clientResults[viewUid].results.filter((client) => contacts.includes(client.contact_uid));

    clients.forEach((client) => {
      // eslint-disable-next-line no-param-reassign
      client.contact_status = status;
    });
  },
};

const actions = {
  async getClient({ commit }, uid) {
    const client = await clientService.fetchClient(uid);
    commit('setClient', client);
  },
  async getVaultedCards({ commit }, clientUid) {
    const cards = await clientService.fetchClientVaultedCards(clientUid);
    commit('setVaultedCards', { clientUid, cards });
  },
  async fetchClients({ commit, rootGetters }, payload) {
    const {
      viewUid,
      perPage,
      page,
      query,
      sortBy,
      sortOrder,
      filter,
      setClients,
      aggregateResults,
    } = payload;

    try {
      const useNewDecoration = rootGetters['BusinessStore/hasFeature']('crm_custom_fields');
      const currentViewClientResults = getters.clientResults(state)(viewUid) || [];
      if (!setClients && currentViewClientResults.length) return { existingData: true };

      const data = await clientService.getClients(perPage, page, query, sortBy, sortOrder, filter, useNewDecoration);
      data.clients = data.clients.map((client) => ({ ...client, tag_list: client.tag_list ? client.tag_list : client.tags.split(' ').filter((tag) => tag) }));
      if (aggregateResults) {
        commit('setAggregatedClientResults', { viewUid, data });
      } else {
        commit('setClientResults', { viewUid, data });
      }
      return { existingData: false, count: data.count };
    } catch (error) {
      const res = { error };
      console.error(error);
      return res;
    }
  },
  resetClients({ commit }) {
    commit('resetClientResults');
  },
  resetOtherTabsClientResults({ commit }, viewUid) {
    commit('resetOtherTabsClientResults', viewUid);
  },
  async fetchStatuses({ commit }) {
    const statuses = await clientService.getStatuses();
    commit('setStatuses', statuses);
  },
  updateTags({ commit }, payload) {
    commit('updateTags', payload);
  },
  updateStatus({ commit }, payload) {
    commit('updateStatus', payload);
  },
  async createNote({ dispatch }, payload) {
    const createNoteResponse = await clientService.createNote(payload);
    dispatch('getClient', createNoteResponse?.data?.client?.uid);
    return createNoteResponse;
  },
  removeViewFromClientsResult({ commit }, uid) {
    commit('removeViewFromClientResults', uid);
  },
  async deleteClients(_, uids) {
    const res = {};

    try {
      await clientService.deleteClients(uids);
    } catch (e) {
      console.error(e);
      res.error = e;
    }

    return res;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
