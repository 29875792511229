const handleFavicon = (data, domainBase) => {
  const favicon = document.getElementById('favicon');
  if (domainBase) {
    if (window.location.hostname.includes('vchost.co')) {
      favicon.setAttribute('href', `${window.pov_config.VUE_APP_CLOUDINARY}favicons/development`);
    } else {
      const favName = window.location.hostname.replaceAll('-', '');
      favicon.setAttribute('href', `${window.pov_config.VUE_APP_CLOUDINARY}favicons/${favName}`);
    }
    return;
  }
  switch (data) {
    case 'development':
    case 'integration':
    case 'production':
      favicon.setAttribute('href', `${window.pov_config.VUE_APP_POV_ASSETS_URL}images/favicons/favicon_${data}.png`);
      break;
    case 'star':
      favicon.setAttribute('href', `${window.pov_config.VUE_APP_POV_ASSETS_URL}images/favicons/favicon_${data}.ico`);
      break;
    default:
      favicon.setAttribute('href', `${window.pov_config.VUE_APP_POV_ASSETS_URL}images/favicons/favicon_generic.ico`);
  }
};

export default {
  handleFavicon,
};
