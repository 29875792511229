import salesWidgetService from '@/modules/widgets/salesWidget/services/salesWidgetService';

const state = {
  salesWidgetData: null,
  forecastData: null,
};

const getters = {
  salesWidgetData: (state) => state.salesWidgetData,
  forecastData: (state) => state.forecastData,
};

const mutations = {
  setSalesWidgetData(state, data) {
    state.salesWidgetData = data;
  },
  setForecastData(state, data) {
    state.forecastData = data;
  },
};

const actions = {
  async fetchSalesWidgetData({ commit }) {
    const response = await salesWidgetService.getSalesWidgetData();
    if (response.success) {
      commit('setSalesWidgetData', response.data);
    } else {
      commit('setSalesWidgetData', null);
    }
  },
  async fetchForecastData({ commit }) {
    const response = await salesWidgetService.getForecastData();
    if (response.success) {
      commit('setForecastData', response.data);
    } else {
      commit('setForecastData', null);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
