import httpService from '@/modules/common/services/httpService';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import LayoutConstants from '@/constansts/layoutConstants';
import eventListenerService from '@/utils/eventCommunicationUtils/eventListenerService';

const props = {};

// eslint-disable-next-line max-len
const saveDeviceParams = (deviceToken, deviceType, deviceUUID, appOwner, appVersion) => {
  props.deviceToken = deviceToken;
  props.deviceType = deviceType;
  props.deviceUUID = deviceUUID;
  props.appOwner = appOwner;
  props.appVersion = appVersion;
};

// Corresponds to mobile-app-util.js.coffee in Frontage angular
// When the app starts but is not authenticated, then this needs to be
// called after login is successful
const registerDevice = () => {
  if (props.deviceUUID) {
    httpService.post('/v2/devices', {
      device_id: props.deviceUUID,
      device_token: props.deviceToken,
      device_type: props.deviceType,
      app_owner: props.appOwner,
      app_version: props.appVersion,
    });
  }
};

function getDeviceUUID() {
  return props.deviceUUID;
}

function getClients(pageNumber) {
  return httpService.get(`/platform/v1/clients?phone_exists=true&per_page=100&page=${pageNumber}`);
}

function getMobileSettings() {
  return httpService.get('/v2/settings/mobile');
}

function emitAppStateChanged(newState) {
  outgoingPostMessageUtils.post(LayoutConstants.FIRE_ACTION, { event_name: 'appStateChangedTo', data: newState });
  eventListenerService.emit('appStateChangedTo', { state: newState });
}

export default {
  saveDeviceParams,
  registerDevice,
  getClients,
  getMobileSettings,
  emitAppStateChanged,
  getDeviceUUID,
};
