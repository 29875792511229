import httpService from '@/modules/common/services/httpService';
import SearchBarConstants from '@/constansts/SearchBarConstants';

const MAX_ITEM_AMOUNT = 5;
const baseRoute = '/v2/search';

const uniteResults = (results) => {
  const data = results;
  data[0].data = { ...data[0].data, top_hits: { ...data[0].data.top_hits, client: data[1].data.top_hits.client } };
  return data[0].data;
};

const searchResults = async (searchParams, query) => {
  try {
    return Promise.all([httpService.get(
      `${baseRoute}?query=${query}&entities=${SearchBarConstants.searchEntities.join(',')}`,
      searchParams,
    )])
      .then((res) => res[0].data);
  } catch (e) {
    console.log('error in fetchSearchResults', e);
    return [];
  }
};

const searchResultsWithSortByActivityFilter = async (searchParams, query) => {
  try {
    return Promise.all([httpService.get(
      `${baseRoute}?query=${query}&entities=${SearchBarConstants.searchEntities.join(',')}`,
      searchParams,
    ), httpService.get(
      `${baseRoute}?query=${query}&search_filter[sort_option]=activity`,
      searchParams,
    )])
      .then((res) => uniteResults(res));
  } catch (e) {
    console.log('error in fetchSearchResults', e);
    return [];
  }
};

const fetchSearchResults = async (query, teamsViewFilter, hasPhoneSearchFF) => {
  let result;
  const searchParams = {
    quick: true, source: 'toolbar_search', teams_view_filter: teamsViewFilter, per_page: MAX_ITEM_AMOUNT,
  };
  if (hasPhoneSearchFF) {
    result = await searchResults(searchParams, query);
  } else {
    result = await searchResultsWithSortByActivityFilter(searchParams, query);
  }
  return result;
};

export default {
  fetchSearchResults,
  MAX_ITEM_AMOUNT,
};
