<template>
  <VcLayout column class="client-details-wrapper">
    <VcLayout column class="client-details-container">
      <VcClientItem v-if="clientDetails"
                    :contact-name="clientDetails.clientName"
                    :matter-name="clientDetails.matterName"
                    :client-avatar="clientDetails.avatar"
      >
        <template #actions>
          &nbsp;
        </template>
      </VcClientItem>
      <VcButton v-else
                @click="selectClientbounced"
                :label="$t('point_of_sale.client_details.select_client', { matter_term_singular: matterTerm })"
                flavor="primary"
                :x-large="!$isMobile()"
                :large="$isMobile()"
                :class="{ 'mx-4': $isMobile(), 'my-2': $isMobile() }"
                data-qa="add-client">
        <template #prepend>
          <VcIcon size="16" color="currentColor" class="me-3">icon-Add-client</VcIcon>
        </template>
      </VcButton>

      <VcAlert v-if="clientDetails && unpaidItemsDescription" type="info"
               :body-text="unpaidItemsDescription"
               :button-text="$t('point_of_sale.client_details.add_unpaid_items')"
               @onAction="addUnpaidItemsDebounced"/>
    </VcLayout>
  </VcLayout>
</template>

<script>
import justDebounce from 'just-debounce';

export default {
  name: 'ClientDetails',
  props: {
    clientDetails: {
      type: Object,
      validator: (prop) => prop.clientName && prop.avatar,
    },
    matterTerm: {
      type: String,
      default: '',
    },
    itemsInCheckout: {
      type: Boolean,
      default: false,
    },
    unpaidItemsDescription: {
      type: String,
      default: '',
    },
    numberOfUnpaidItems: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      addUnpaidItemsDebounced: justDebounce(this.addUnpaidItems, 300, true, true),
      selectClientbounced: justDebounce(this.selectClient, 500, true, true),
    };
  },
  methods: {
    selectClient() {
      this.$track('Checkout - Select Client Click', { Is_cart_empty: !this.itemsInCheckout });
      this.$emit('select-client');
    },
    addUnpaidItems() {
      this.$track('Checkout - Add Unpaid Items', { Number_of_items: this.numberOfUnpaidItems });
      this.$emit('add-unpaid-items');
    },
  },
};
</script>

<style scoped lang="scss">
@import "styles/variables.scss";

.client-details-wrapper {
  border-bottom: 1px solid var(--gray-darken-2);

  @include md-and-up {
    padding: var(--size-value4);
    border-bottom: var(--border-frame);
  }
}

.client-details-container {
  margin: 0;

  @include md-and-up {
    border: var(--border-frame);
    border-radius: var(--border-radius);
  }

  .VcClientItem {
    padding: var(--size-value2);
  }

  .add-client {
    padding: var(--size-value3);
    height: var(--size-value16);
    width: 100%;

    &__content {
      gap: var(--size-value3);
      line-height: var(--size-value8);
      color: var(--gray-darken-5);
      font-size: var(--size-value4);
      font-weight: var(--font-weight-large);
    }

    &__icon {
      background: var(--blue-lighten-3);
      border-radius: var(--border-radius-circle);
      height: var(--size-value8);
      width: var(--size-value8);
    }
  }
}
</style>
