import layoutService from '@/modules/widgetManager/services/layoutService';

const state = {
  layoutConfig: { type: undefined },
};

const getters = {
  layoutConfig: (state) => state.layoutConfig,
};

const mutations = {
  setLayoutConfig(state, layoutConfig) {
    state.layoutConfig = layoutConfig;
  },
};

const actions = {
  // eslint-disable-next-line consistent-return
  async getLayoutByType({ commit, state }, type) {
    let { layoutConfig } = state;
    if (state.layoutConfig.type !== type) {
      layoutConfig = await layoutService.getBoard(type);
      // todo handle error better when api is ready with console error and error state for the user
      if (!layoutConfig.success) {
        console.error('error getting layout config');
        return Promise.reject(new Error('no success'));
      }
      commit('setLayoutConfig', layoutConfig.data.staffWidgetsBoards[0]);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
