import httpService from '@/modules/common/services/httpService';

const baseRoute = '/v2/dashboard/campaigns';
const sentSinceDays = 30;
const perPage = 3;
const page = 1;
const getCampaigns = async () => httpService.get(`${baseRoute}?sent_since=${sentSinceDays}&per_page=${perPage}&page=${page}`).then((res) => res.data);

export default {
  getCampaigns,
};
