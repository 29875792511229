import { getAppsByType, getApps } from '@/modules/app/services/appsService';

// TODO: merge with appStore.js
const state = {
  availableApps: {
    ai_assistant: null,
  },
  marketApps: [],
};

const getters = {
  availableApps: (state) => state.availableApps,
  availableAppByCodeName: (state) => ({ appCodeName }) => Object.values(state.availableApps)
    .flat()
    .find(
      (app) => app?.app_code_name === appCodeName,
    ),
  marketApps: (state) => state.marketApps,
  getMarketAppByName: (state) => (appCodeName) => state.marketApps.find((app) => app.app_code_name === appCodeName),
};

const mutations = {
  setAvailableApps(state, { type, availableApps }) {
    if (!Array.isArray(availableApps)) return;
    state.availableApps[type] = availableApps;
  },
  setMarketApps(state, { apps }) {
    state.marketApps = apps;
  },
};

const actions = {
  async loadAppsByType({ rootGetters, commit, state }, type) {
    if (state.availableApps[type]) {
      return;
    }

    const businessUid = rootGetters['AuthStore/businessUid'];
    const res = await getAppsByType({ type, businessUid });
    if (res.apps) {
      commit('setAvailableApps', { type, availableApps: res.apps });
    }
  },
  async getMarketApps({ commit }) {
    const res = await getApps();
    commit('setMarketApps', res);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
