<template>
  <VcBottomSheet :value="visible"
                 @input="closeModal">
    <VcLayout column class="mobile-catalog-modal">
      <VcLayout class="mobile-catalog-modal__header flex-grow-0" align-center>
        <VcButton icon fab small :width="24" @click="closeModal">
          <template #prepend>
            <VcIcon size="16">icon-Chevron-down</VcIcon>
          </template>
        </VcButton>
        <div class="flex-grow-1">{{ discardChangesTitleText }}</div>
      </VcLayout>

      <VcDivider/>

      <PosTableContainer @go-to-checkout="closeModal" @edit-price-and-add="onEditPriceBeforeAddingItem"/>
    </VcLayout>
  </VcBottomSheet>
</template>

<script>
import PosTableContainer from '@/modules/payments/views/PosTableContainer.vue';

export default {
  components: { PosTableContainer },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    discardChangesTitleText() {
      return this.$t('point_of_sale.catalog_title');
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:visible', false);
    },
    onEditPriceBeforeAddingItem(catalogItem) {
      this.$emit('edit-price-and-add', catalogItem);
    },
  },
};
</script>

<style scoped lang="scss">
.mobile-catalog-modal {
  height: 85vh;

  &__header {
    padding-block: var(--size-value2);
    padding-inline: var(--size-value3);
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-large);
    line-height: var(--size-value6);
    gap: var(--size-value2);
  }
}
</style>
