<template>
  <VcLayout class="embeddedAppsDisplay"
            v-show="displayedApp !== undefined" :data-qa="dataQa" column fill-height>
    <EmbeddedAppDelegator v-for="appId in liveApps" :key="appId"
                          v-show="appId === displayedApp"
                          v-bind="getAppData(getExternalAppById(appId))"
                          parentSource="EMB"/>
  </VcLayout>
</template>
<script>

/**
 * This is the container for the live embedded apps, which will always be on the DOM.
 * It's used in order to keep them alive on the DOM even when not displayed, until they are removed from the liveApp list.
 */

import EmbeddedAppDelegator from '@/modules/app/components/EmbeddedAppDelegator.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'EmbeddedAppsDisplay',
  components: { EmbeddedAppDelegator },
  props: {
    dataQa: {
      type: String,
      default: 'EmbeddedAppsDisplay',
    },
  },
  computed: {
    ...mapGetters('AppStore', ['getExternalAppById']),
    ...mapGetters('AppStore/EmbeddedAppStore', ['liveApps', 'displayedApp', 'getAppRoute']),
  },
  methods: {
    getAppData(app) {
      const url = this.getAppRoute(app.app_code_name);
      return {
        component: 'iFrame',
        appId: app.app_code_name,
        appCodeName: app.app_code_name,
        appUrl: url.href,
        appName: app.app_name,
        displayHeader: !!app.show_header,
      };
    },
  },
};
</script>

<style lang="scss" scoped>

.embeddedAppsDisplay {
  width: 100%;
  height: 100%;
}

</style>
