<template>
  <component :is="this.$isMobile() ? 'VcInputBottomSheet' : 'VcInputPopover'"
              v-model="showPopover"
              :footer="this.$isMobile()"
              class="tax-picker-pov"
              data-qa="tax-picker-pov"
              :top="-60"
              @input="handleCancel"
              @onCancelClicked="handleCancel"
              @onOkClicked="validateTax"
              :title="getTitle"
              :class="{'mobile': this.$isMobile()}">
    <template #activator="activatorObj">
      <div v-bind="activatorObj" @click="openPopover" @keydown.enter="openPopover" tabindex="0"
        data-qa="tax-picker-button" class="tax-picker-button">
        <VcTextField data-qa="tax-picker-tf"
                  :label="label ? label : $t('payment.tax_picker.label')"
                  readonly
                  :disabled="disabled"
                  append-icon="$caret_down"
                  :value="selectedTaxesText">
      </VcTextField>
      </div>
    </template>
    <template>
      <div class="tax-picker-pov-popover-content">
          <VcListbox :items="taxListItems"
                 :title="getItemListTitle"
                 :add-another="false"
                 :add-search="false"
                 :emptyListMsg="getEmptyStateMsg"
                 data-qa="tax-picker-pov-vc-list"
                 class="tax-picker-pov-vc-list"
                 @change="onTaxAdded">
          </VcListbox>
        </div>
    </template>
  </component>
</template>
<script>

export default {
  name: 'TaxPicker',
  components: {
  },
  model: {
    prop: 'selectedTaxes',
    event: 'update-selected',
  },
  props: {
    taxes: {
      type: Array,
      default: () => ([]),
    },
    selectedTaxes: {
      type: Array,
      default: () => ([]),
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedTaxes: {
      immediate: true,
      async handler() {
        this.selectedTaxesMobile = [...this.selectedTaxes];
      },
    },
  },
  data() {
    return {
      showPopover: false,
      selectedTaxesMobile: [],
    };
  },
  computed: {
    selectedTaxesText() {
      return this.selectedTaxes.map((t) => `${t.name} (${t.rate}%)`).join(', ');
    },
    getTitle() {
      if (this.$isMobile()) {
        return this.$t('payment.tax_picker.title');
      }
      return '';
    },
    getItemListTitle() {
      if (this.$isMobile()) {
        return this.$t('payment.tax_picker.select_taxes');
      }
      return '';
    },
    getEmptyStateMsg() {
      return this.$t('payment.tax_picker.empty_state_msg');
    },
    taxListItems() {
      let identifier = 0;
      return this.taxes.map((t) => {
        let selected;
        let disabled;
        if (this.$isMobile()) {
          selected = Boolean(this.selectedTaxesMobile.find((st) => st.id === t.id));
          disabled = (!t.id) || (this.selectedTaxesMobile.length >= 3 && !selected);
        } else {
          selected = Boolean(this.selectedTaxes.find((st) => st.id === t.id));
          disabled = (!t.id) || (this.selectedTaxes.length >= 3 && !selected);
        }
        identifier += 1;
        return {
          ...t,
          label: `${t.name} (${t.rate}%)`,
          selected,
          checked: selected,
          disabled,
          identifier,
        };
      });
    },
  },
  methods: {
    openPopover() {
      if (!this.disabled) {
        this.showPopover = true;
      }
    },
    onTaxAdded(value) {
      if (this.$isMobile()) {
        this.selectedTaxesMobile = [...value];
      } else {
        this.$emit('update-selected', value);
      }
    },
    handleCancel() {
      this.showPopover = false;
      this.selectedTaxesMobile = [...this.selectedTaxes];
    },
    validateTax() {
      this.$emit('update-selected', this.selectedTaxesMobile);
      this.showPopover = false;
    },
  },
};
</script>

<style lang="scss">
@import "styles/variables.scss";
div.vc-popover:has(div[data-qa="tax-picker-pov-layout"]) {
    min-width: 240px;
    max-width: 568px;
    max-height: 624px;
    border-radius: var( --border-radius);
    border: var( --border-frame);
    .vc-popover-content{
      overflow-y: hidden;
      padding: 0;
      .tax-picker-pov-popover-content{
        .tax-picker-pov-vc-list{
          max-height: 160px;
          overflow-y: scroll;
          padding: 0;
        }
        .VcListbox {
          border: 0;
          .vc-base-list-item{
            padding: 0 !important;
            .v-input__slot{
              padding-inline-start: var(--size-value4);
              padding-inline-end: var(--size-value4);
              padding-top: var(--size-value3);
              padding-bottom: var(--size-value3);
            }
          }
        }
      }
    }
}
</style>
