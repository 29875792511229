<template>
  <div>
    <VcImage v-if="image" :image="image" class="image"/>
    <VcIcon v-else :size="20" color="white" class="icon-wrapper">{{ icon }}</VcIcon>
  </div>
</template>

<script>
import { CHECKOUT_ITEM_TYPES } from '@/modules/payments/constants/pos';

export default {
  props: {
    type: {
      type: String,
      validator: (value) => ['Service', 'Product'].includes(value),
    },
    image: {
      type: String,
      default: null,
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case CHECKOUT_ITEM_TYPES.Service:
        case CHECKOUT_ITEM_TYPES.Meeting:
          return 'icon-twousers';
        case CHECKOUT_ITEM_TYPES.Product:
        case CHECKOUT_ITEM_TYPES.ProductOrder:
          return 'icon-products';
        default:
          return 'icon-twousers';
      }
    },
  },
};
</script>

<style scoped>
.image, .icon-wrapper {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  border-radius: var(--border-radius);
}

.icon-wrapper {
  background-color: var(--green);
  padding: 5px;
  width: 100%;
  min-height: 100%;
}
</style>
