<template>
  <VcLayout flex-grow-0 class="checkout-actions-container" data-qa="checkout-button">
    <VcDropdown offset-y offset-overflow
                v-model="isMenuOpen"
                class="checkout-actions">
      <template #activator="{ on }">
        <VcButton v-on="disabled ? {} : on"
                  class="checkout-actions__activator"
                  :disabled="disabled"
                  :loading="loading"
                  data-qa="checkout-actions-activator"
                  ref="checkoutButton">
          {{ $t('point_of_sale.checkout_actions.checkout') }}
          <VcIcon color="currentColor" v-if="!$isMobile()" class="ms-3">icon-caret-down</VcIcon>
        </VcButton>
      </template>
      <template>
        <VcLayout column
                  class="checkout-actions__list"
                  :class="{ 'checkout-actions__list--with-alert': hasAlert }"
                  data-qa="checkout-actions-list">
          <VcAlert v-if="isFreeCheckout"
                   :body-text="$t('point_of_sale.checkout_actions.alert_free_checkout')"
                   class="free-checkout-alert"
                   type="info"/>
          <VcAlert v-else-if="!isCheckoutEnabled"
                   class="no-payment-alert"
                   type="warning"
                   :body-text="$t('point_of_sale.no_payment_gateway.label')"
                   :button-text="$t('point_of_sale.no_payment_gateway.connect')"
                   @onAction="handleAlert"/>

          <VcLayout v-for="item in checkoutActions"
                    :key="item.event"
                    @click="handleAction(item)"
                    align-center
                    class="checkout-actions__list-item"
                    :class="{ beta: item.beta, disabled: item.disabled}"
                    :data-qa="`checkout-action-${item.event}`">
            <VcIcon size="20" color="currentColor">{{ item.icon }}</VcIcon>
            <div class="flex-fill">{{ item.title }}</div>
            <div class="beta-badge" v-if="item.beta">
              {{ $t('point_of_sale.checkout_actions.coming_soon') }}
            </div>
          </VcLayout>
        </VcLayout>
      </template>
    </VcDropdown>
  </VcLayout>
</template>

<script>
import { CHECKOUT_ACTIONS } from '@/modules/payments/constants/pos';

export default {
  name: 'CheckoutButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasAlert: {
      type: Boolean,
      default: false,
    },
    isFreeCheckout: {
      type: Boolean,
      default: false,
    },
    canChargeCard: {
      type: Boolean,
      default: false,
    },
    isCheckoutEnabled: {
      type: Boolean,
      default: false,
    },
    numberOfItemsInCart: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  watch: {
    isMenuOpen: {
      handler(newVal) {
        if (newVal) {
          this.$track('Cart Checkout Click', { Number_of_cart_items: this.numberOfItemsInCart, Is_free_Sale: this.isFreeCheckout });
        }
      },
    },
  },
  computed: {
    checkoutActions() {
      return [
        {
          title: this.$t('point_of_sale.checkout_actions.complete_checkout'),
          icon: 'icon-Done',
          event: CHECKOUT_ACTIONS.FreeCheckout,
          show: this.isFreeCheckout,
        },
        {
          title: this.$t('point_of_sale.checkout_actions.charge'),
          icon: ' icon-Credit-card-new',
          event: CHECKOUT_ACTIONS.Charge,
          show: true,
          disabled: this.isFreeCheckout || !this.canChargeCard,
        },
        {
          title: this.$t('point_of_sale.checkout_actions.record'),
          icon: 'icon-Money1',
          event: CHECKOUT_ACTIONS.Record,
          show: true,
          disabled: this.isFreeCheckout,
        },
        {
          title: this.$t('point_of_sale.checkout_actions.send_payment_link'),
          icon: 'icon-Link',
          event: CHECKOUT_ACTIONS.SendLink,
          show: true,
          disabled: this.isFreeCheckout || !this.isCheckoutEnabled,
        },
        {
          title: this.$t('point_of_sale.checkout_actions.generate_qr_code'),
          icon: 'icon-icon-QR',
          event: 'qr',
          show: true,
          disabled: this.isFreeCheckout || !this.isCheckoutEnabled,
        },
      ].filter((action) => action.show);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.checkoutButton.$el.focus();
    });
  },
  methods: {
    handleAction(item) {
      if (this.disabled || item.beta) {
        return;
      }

      this.$track('Cart Checkout Menu Selection', {
        Selected_payment_flow: item.event,
        Is_gateway_connected: this.canChargeCard,
      });
      this.$emit('action-clicked', item.event);
      this.isMenuOpen = false;
    },
    handleAlert() {
      this.$emit('alert-clicked');
      this.isMenuOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "styles/variables.scss";

.checkout-actions-container {
  padding-top: var(--size-value4);
  padding-inline: var(--size-value4);

  @include md-and-up {
    padding-inline: var(--size-value6);
  }
}

.checkout-actions {
  &__activator {
    flex: 1 1 0;
    gap: var(--size-value3);
    padding: var(--size-value3);
    font-size: var(--font-size-small);
    line-height: var(--size-value4);
    font-weight: var(--font-weight-large);
  }

  &__list {
    padding-block: var(--size-value2);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-7);

    &--with-alert {
      padding-block-start: 0;
    }
  }

  &__list-item {
    gap: var(--size-value4);
    padding: 10px 20px;
    color: var(--gray-darken-5);
    font-size: var(--font-size-x-small);
    line-height: var(--size-value5);
    font-weight: var(--font-weight-medium);
    cursor: pointer;

    &.beta {
      color: var(--gray-darken-2);
      cursor: default;
    }
    &.disabled {
      color: var(--gray-darken-2);
      pointer-events: none;
    }

    &:hover:not(.beta) {
      background-color: var(--gray-lighten-3);
    }

    .beta-badge {
      padding: var(--size-value1) var(--size-value2);
      border-radius: var(--border-radius);
      background: rgba(189, 152, 247, 0.40);
      color: #7C4EFC;
      font-size: var(--font-size-xx-small);
      line-height: 1em;
      font-weight: var(--font-weight-large);
    }
  }
}

.no-payment-alert, .free-checkout-alert {
  border-style: none none solid none;
  border-radius: 0;
}
</style>
