<template>
  <VcLayout v-show-visible="isShown" column class="widget-data-wrapper">
    <VcLayout column @click="onClick" class="widget-data" :class="{empty:isEmpty}">
      <VcLayout class="widget-data__title">{{ title }}</VcLayout>
      <VcLoader v-if="isLoading"/>
      <VcLayout v-else-if="isEmpty" class="widget-data__empty-state">{{ emptyState }}</VcLayout>
      <VcLayout v-else class="widget-data__value" align-end>
        {{ value }}
        <VcTooltip v-if="badgeValue"
                   :content="badgeTooltip"
                   :disabled="!badgeTooltip"
                   dark bottom>
          <VcLayout class="widget-data__badge mb-1 flex-grow-0"
                    :class="`color-${badgeFlavor}`">
            <VcIcon color="inherit" size="10" class="widget-data__badge-icon">
              {{ badgeIcon }}
            </VcIcon>
            {{ badgeValue }}
          </VcLayout>
        </VcTooltip>
      </VcLayout>
    </VcLayout>
    <slot/>
  </VcLayout>
</template>

<script>
export default {
  name: 'WidgetData',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    emptyState: {
      type: String,
      default: '',
    },
    badgeValue: {
      type: [String, Number],
      default: '',
    },
    badgeFlavor: {
      type: String,
      default: 'green',
      validator: (value) => ['green', 'red'].includes(value),
    },
    badgeTooltip: {
      type: String,
      default: '',
    },
  },
  computed: {
    isShown() {
      if (this.isLoading) {
        return true;
      }
      return this.value || this.emptyState || this.value === 0;
    },
    isEmpty() {
      return !this.value && this.emptyState;
    },
    badgeIcon() {
      return this.badgeFlavor === 'green' ? 'icon-arrow-up-s' : 'icon-arrow-down-s';
    },
  },
  methods: {
    onClick() {
      if (this.isEmpty) {
        return;
      }
      this.$emit('click');
    },
  },
};
</script>

<style scoped lang="scss">
.widget-data-wrapper {
  flex-grow: 0;
  gap: var(--size-value2);
}

.widget-data {
  gap: var(--size-value1);

  &:not(.empty) {
    cursor: pointer;

    &:hover {
      color: var(--link-color);
    }
  }

  &__title {
    font-size: var(--font-size-small2);
    font-weight: var(--font-weight-large2);
  }

  &__value {
    font-size: var(--font-size-medium2);
    font-weight: var(--font-weight-large2);
    line-height: var(--size-value8);
    display: flex;
    align-items: center;
    gap: var(--size-value2);
  }

  &__badge {
    padding-inline: 6px;
    border-radius: var(--size-value5);
    font-size: var(--font-size-x-small);
    line-height: var(--size-value4);
    color: var(--white);
    gap: var(--size-value1);

    &.color-green {
      background-color: var(--green);
    }

    &.color-red {
      background-color: var(--red);
    }

    &-icon {
      ::v-deep &.v-icon {
        line-height: var(--size-value4);
      }
    }
  }

  &__empty-state {
    color: var(--gray-darken-4);
    font-size: var(--font-size-x-small);
    font-weight: var(--font-weight-medium);
    line-height: var(--size-value6);
  }
}
</style>
