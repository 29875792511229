import bizAiService from '@/modules/bizai/services/BizAiService';

const state = {
  visible: false,
  launched: false,
  staffAiSettings: {
    isLoaded: false,
    aiRecommendations: {
      enabled: undefined,
      subOptions: {
        nextBestAction: undefined,
        estimate: undefined,
        scheduling: undefined,
      },
    },
  },
};

const getters = {
  isVisible: (state) => state.visible,
  isLaunched: (state) => state.launched,
  isAiRecommendationsEnabled: (state) => state.staffAiSettings.aiRecommendations.enabled,
  isNextBestAction: (state) => state.staffAiSettings.aiRecommendations.subOptions.nextBestAction,
  isEstimation: (state) => state.staffAiSettings.aiRecommendations.subOptions.estimate,
  isScheduling: (state) => state.staffAiSettings.aiRecommendations.subOptions.scheduling,
};

const mutations = {
  setVisibility: (state, visible) => {
    state.launched = true;
    state.visible = Boolean(visible);
  },
  toggleVisibility: (state) => {
    state.launched = true;
    state.visible = !state.visible;
  },
  setLaunched: (state, launched) => {
    state.launched = Boolean(launched);
  },
  setStaffAiSettingsLoaded: (state, staffAiSettingsLoaded) => {
    state.staffAiSettings.isLoaded = Boolean(staffAiSettingsLoaded);
  },
  setAiRecommendationEnabled: (state, aiRecommendationEnabled) => {
    state.staffAiSettings.aiRecommendations.enabled = Boolean(aiRecommendationEnabled);
  },
  setNextBestActionEnabled: (state, nextBestAction) => {
    state.staffAiSettings.aiRecommendations.subOptions.nextBestAction = Boolean(nextBestAction);
  },
  setEstimationEnabled: (state, estimation) => {
    state.staffAiSettings.aiRecommendations.subOptions.estimate = Boolean(estimation);
  },
  setSchedulingEnabled: (state, scheduling) => {
    state.staffAiSettings.aiRecommendations.subOptions.scheduling = Boolean(scheduling);
  },
};

const actions = {
  setVisibility: ({ commit }, visible) => {
    commit('setVisibility', visible);
  },
  toggleVisibility: ({ commit }) => {
    commit('toggleVisibility');
  },
  setLaunched: ({ commit }, launched) => {
    commit('setLaunched', launched);
  },
  async fetchStaffAiSettings({ commit, rootGetters, state }) {
    if (!state.staffAiSettings.isLoaded) {
      const res = await bizAiService.getStaffAiSettings(rootGetters['AuthStore/staffUid']);
      if (res.success) {
        const staffAiSettings = res.data;
        commit('setStaffAiSettingsLoaded', true);
        commit('setAiRecommendationEnabled', staffAiSettings.ai_recommendations.enable);
        commit('setNextBestActionEnabled', staffAiSettings.ai_recommendations.sub_options.next_best_action);
        commit('setEstimationEnabled', staffAiSettings.ai_recommendations.sub_options.estimate);
        commit('setSchedulingEnabled', staffAiSettings.ai_recommendations.sub_options.scheduling);
      } else {
        throw new Error('Failed to fetch staff ai settings');
      }
    }
  },
  async updateAllSettings({ commit, rootGetters, state }, settings = {}) {
    if (state.staffAiSettings.isLoaded) {
      const currentSettings = state.staffAiSettings.aiRecommendations;
      const updatedSettings = {
        enable: 'enabled' in settings ? settings.enabled : currentSettings.enabled,
        sub_options: {
          next_best_action: 'nextBestAction' in settings ? settings.nextBestAction : currentSettings.subOptions.nextBestAction,
          estimate: 'estimate' in settings ? settings.estimate : currentSettings.subOptions.estimate,
          scheduling: 'scheduling' in settings ? settings.scheduling : currentSettings.subOptions.scheduling,
        },
      };

      const res = await bizAiService.updateStaffAiSettings(
        rootGetters['AuthStore/staffUid'],
        {
          staff_uid: rootGetters['AuthStore/staffUid'],
          ai_recommendations: updatedSettings,
        },
      );

      if (res.success) {
        const staffAiSettings = res.data;
        commit('setAiRecommendationEnabled', staffAiSettings.ai_recommendations.enable);
        commit('setNextBestActionEnabled', staffAiSettings.ai_recommendations.sub_options.next_best_action);
        commit('setEstimationEnabled', staffAiSettings.ai_recommendations.sub_options.estimate);
        commit('setSchedulingEnabled', staffAiSettings.ai_recommendations.sub_options.scheduling);
      } else {
        throw new Error('Failed to update staff ai settings');
      }
    } else {
      throw new Error('Failed to update staff ai settings');
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
