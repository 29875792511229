<template>

  <VcLayout justify-center align-center text-center column>
    <Product class="empty-state-svg"/>
    <div class="empty-state-title">
      {{title}}
    </div>
     <div flex-row class="empty-state-sub-title" >
      <span>{{subTitle}}</span>
      <VcLink v-if="linkText"
              url="#"
              :label="linkText"
              target="_self"
              @click="$emit('empty-state-click')"
              class="product-dialog-link">
      </VcLink>
    </div>
  </VcLayout>
</template>

<script>

export default {
  components: {
    Product: () => import('@/assets/images/payments/empty_state.svg'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "styles/variables.scss";

  .empty-state {
    width: 500px;
    align-self: stretch;
    line-height: var(--size-value6);
    color: var(--gray-darken-5);
  }

  .empty-state-svg {
    margin-bottom: var(--size-value6);
  }

  .empty-state-title {
    font-size: var(--font-size-small2);
    font-weight: var(--font-weight-large2);
  }

  .empty-state-sub-title {
    font-size: var(--font-size-x-small);
    font-weight: var(--font-weight-medium);
    .product-dialog-link {
      display: inline;
      color: var(--v-secondary-base);
      padding-left: var(--size-value1);
    }
  }
</style>
