import { authService } from '@/modules/auth/services/authService';
import storageService from '@/modules/common/services/storageService';
import logoutUtil from '@/utils/logoutUtil';
import Vue from 'vue';

/**
 * Interceptors cannot access the store, because it creates a dependency cycle
 * So any data that is needed in the interceptors should go through the local storage.
 * Please make sure not to store sensitive data in local storage, to avoid security issues.
 */

function init(httpClient) {
  httpClient.interceptors.request.use(
    async (initialConfig) => {
      // Do something before request is sent
      let token = null;
      const tokenPromise = await authService.getToken();
      if (tokenPromise?.success && tokenPromise?.jwt) token = tokenPromise?.jwt;

      const { staffUid, businessUid } = tokenPromise;
      Vue.prototype.$saveAuthData(businessUid, staffUid);

      const config = { ...initialConfig };
      config.headers['x-mobile-app'] = storageService.get('isMobileApp');
      config.headers['x-app-name'] = 'Frontage-pov';

      if (!config.params) {
        config.params = {};
      }

      if (staffUid) {
        config.params.context_staff = staffUid;
      }

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error),
  );

  httpClient.interceptors.response.use(
    (response) => response,
    async (initialError) => {
      const error = { ...initialError };
      // Do something with response error
      const relativePath = error?.config?.baseURL && !(/^\\(?!\\)/.test(error?.config?.baseURL));
      if (error?.response?.status === 403 && !(error?.response?.headers['x-platform']) && relativePath) {
        window.location.reload();
      } else if (error.response?.status === 401) {
        const resp = await authService.getToken(true);
        if (resp.success) {
          error.config.headers.Authorization = `Bearer ${resp.jwt}`;
          return httpClient(error.config);
        }
        await authService.logout();
        logoutUtil.handleLogout();
        return Promise.reject(error);
      }
      return Promise.reject(error);
    },
  );
}

export default {
  init,
};
