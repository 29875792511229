<template>
  <VcLayout column class="price-breakdown" v-if="currency" data-qa="price-breakdown">
    <template v-if="netTotal > 0">
      <VcLayout class="netTotal">
        <div class="price-breakdown__label">
          {{ $t('point_of_sale.price_breakdown.net_total') }}
        </div>
        <div class="price-breakdown__value">
          {{ $priceDisplay(netTotal, currency) }}
        </div>
      </VcLayout>

      <VcLayout class="tax">
        <div class="price-breakdown__label">
          {{ $t('point_of_sale.price_breakdown.tax_total') }}
          <span class="price-breakdown__tax-percent">
            {{ $percentageDisplay(globalTaxRate) }}
          </span>
        </div>
        <div class="price-breakdown__value">
          {{ $priceDisplay(taxTotal, currency) }}
        </div>
      </VcLayout>

      <VcDivider/>
    </template>

    <VcLayout class="grandTotal">
      <div class="price-breakdown__label">
        {{ $t('point_of_sale.price_breakdown.grand_total', {currency}) }}
      </div>
      <div class="price-breakdown__value">
        {{ $priceDisplay(grandTotal, currency) }}
      </div>
    </VcLayout>
  </VcLayout>
</template>

<script>
import { DISCOUNT_TYPES } from '@/modules/payments/constants/pos';

export default {
  name: 'PriceBreakdown',
  props: {
    currency: {
      type: String,
      default: 'USD',
    },
    netTotal: {
      type: Number,
      required: true,
    },
    taxTotal: {
      type: Number,
      required: true,
    },
    grandTotal: {
      type: Number,
      required: true,
    },
    discount: {
      type: Object,
      required: true,
      validator: (value) => value.type && value.value >= 0 && Object.values(DISCOUNT_TYPES).includes(value.type),
    },
    discountValue: {
      type: Number,
      required: true,
    },
  },
  computed: {
    globalTaxRate() {
      if (this.netTotal === 0) {
        return 0;
      }
      return (this.taxTotal / this.netTotal) * 100;
    },
  },
};
</script>

<style scoped lang="scss">
.price-breakdown {
  gap: var(--size-value3);
  padding-top: var(--size-value4);
  margin-inline: var(--size-value6);
  border-top: var(--border-frame);
  font-size: var(--font-size-small2);
  font-weight: var(--font-weight-medium2);
  line-height: var(--size-value5);
  color: var(--gray-darken-5);

  &__label {
    flex-grow: 1;
  }

  &__value {
    flex-grow: 0;
  }

  &__tax-percent {
    font-size: var(--font-size-xx-small);
    font-weight: var(--font-weight-medium);
    color: var(--gray-darken-3);
  }

  .grandTotal {
    font-weight: var(--font-weight-large);
    line-height: var(--size-value6);
  }
}
</style>
