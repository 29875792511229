<template>
  <VcToast :value="toastConfig.value"
           :header-text="toastConfig.headerText"
           :body-text="toastConfig.bodyText"
           :type="toastConfig.type"
           :timeout="toastConfig.timeout"
           :key="toastId"
           :identifier="toastConfig.identifier"/>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ToastContainer',
  data() {
    return {
      counter: 0,
    };
  },
  computed: {
    ...mapGetters('CommonStore', ['isMobile']),
    ...mapGetters('BusinessStore', ['hasFeature']),
    ...mapGetters('ToastStore', ['toastConfig']),
    toastId() {
      return `Toast ${this.counter}`;
    },
  },
  watch: {
    toastConfig() {
      this.counter += 1;
    },
  },
  methods: {
  },
};
</script>
