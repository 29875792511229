/* eslint-disable import/no-relative-packages */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import messages from '../../../config/locales/pov/common-pov.en.yml';

// eslint-disable-next-line no-restricted-globals
let locale = new URL(location).searchParams.get('language') || 'en';
// Store the modules needed in the currently displayed page, so they can be reloaded if the locale changes
let currentModules = [];

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale, // set locale
  fallbackLocale: 'en',
  messages, // set locale messages
});

const loadedLanguages = {};
// Track in-flight module loading promises
const modulePromises = {};

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

async function importModule(module, currentLocale) {
  return import(/* webpackChunkName: "lang-[request]" */ `../../../config/locales/pov/${module}.${currentLocale}.yml`).then(
    async (newMessages) => {
      i18n.mergeLocaleMessage(currentLocale, newMessages.default[currentLocale]);
      return Promise.resolve('Module is loaded');
    },
    async (error) => {
      console.warn(error);
      return Promise.reject(error);
    },
  );
}

async function loadCurrentI18nModules() {
  await Promise.all(currentModules.map((module) => Vue.prototype.addI18nModule(module, false)));
}

Vue.prototype.clearCurrentI18nModules = () => {
  currentModules = [];
};

/**
 * @param module - the localization module that is required
 * @param current - whether to add the module to the list of current modules
 * @returns {Promise<unknown>}
 */
Vue.prototype.addI18nModule = (module, current = true) => {
  if (current) {
    currentModules.push(module);
  }
  if (!Object.keys(loadedLanguages).includes(locale)) {
    loadedLanguages[locale] = [];
    setI18nLanguage(locale);
  }

  // Define a key for tracking this module/locale combination
  const promiseKey = `${locale}:${module}`;
  // If there's already a promise in flight for this module, return it
  if (modulePromises[promiseKey]) {
    return modulePromises[promiseKey];
  }

  // If the module hasn't been loaded yet
  if (!loadedLanguages[locale].includes(module)) {
    loadedLanguages[locale].push(module);

    // Create and store the promise for the requested locale
    const promise = importModule(module, locale);
    modulePromises[promiseKey] = promise;

    // Clean up the promise tracking after it resolves or rejects
    promise.finally(() => {
      delete modulePromises[promiseKey];
    });

    // Load the fallback module in English if not already done so
    if (locale !== 'en' && !(loadedLanguages.en || []).includes(module)) {
      importModule(module, 'en');
    }

    return promise;
  }
  return Promise.resolve('Module was already loaded');
};

// These are essential translation modules, which will be automatically reloaded when a language is changed
const essentialModules = {};

Vue.prototype.addEssentialI18nModule = async (module) => {
  essentialModules[module] = true;
  await Vue.prototype.addI18nModule(module, false);
};

async function reloadEssentialModules() {
  await Object.keys(essentialModules).forEach(async (module) => {
    await Vue.prototype.addI18nModule(module, false);
  });
}

Vue.prototype.setLocale = (lang) => {
  locale = lang;
  setI18nLanguage(locale);
  reloadEssentialModules();
  loadCurrentI18nModules();
};

export default i18n;
