<template>
  <VcLayout class="pos-table-container" column justify-stretch>
    <VcTabs :tabs="tabsList" v-model="activeTab" separator-style="divider"
            class="pos-table-container__tabs flex-fill">
      <template v-for="(tab, index) in tabsList" :slot="'tab-content-' + index">
        <VcLayout v-if="activeTabLoading && activeCatalogList.length === 0" justify-center :key="index">
          <VcLoader/>
        </VcLayout>

        <CatalogList v-else :key="index"
                     :catalogItems="activeCatalogList" :completed="activeIsCompleted"
                     @load-more="loadMore" @add-item="addCatalogItem"
                     :showEmptyState="shouldDisplayEmptyState"
                     @open-add-product="onAddProduct"/>

      </template>
      <template #append>
        <VcLayout class="align-center add-custom-item">
          <template>
            <VcButton @click="onAddCustomItem"
              data-qa="pos-add-custom-item"
              small flavor="secondary" outlined>
              <template>
                <template>
                  <VcImage :image="require('@/assets/images/payments/add_item.svg')"/>
                </template>
                <template  v-if="!$isMobile()">
                  <span class="add-custom-item__text">{{ addCustomItemButtonText }}</span>
                </template>
              </template>
           </VcButton>
          </template>
        </VcLayout>
      </template>
    </VcTabs>

    <CompactCheckoutDetails v-if="$isMobile()"
                            :items-count="checkoutItems.length" :net-total="priceBreakdown.netTotal"
                            :currency="priceBreakdown.currency"
                            @show-checkout="goToCheckout"/>

    <CustomItemModal v-model="customItemModal"
                     @onSave="onCreateCustomItem" @saveToCheckout="onSaveToCheckoutCustomItem"
                     @show-checkout="goToCheckout"
                     :taxes="taxes" :locale="locale" :currency="currency" :tax-mode="taxMode" :business-category="businessCategory"/>

    <ProductDialog v-model="productDetailsModal" @on-add-first-product="onAddFirstProduct" :taxes="taxes" :locale="locale"/>
  </VcLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CatalogList from '@/modules/payments/components/posTable/CatalogList.vue';
import BusinessDateFormats from '@/modules/common/filters/BusinessDateFormats';
import { ENTITY_TYPES } from '@/modules/payments/constants/pos';
import CompactCheckoutDetails
  from '@/modules/payments/components/posTable/CompactCheckoutDetails.vue';
import CustomItemModal from '@/modules/payments/components/modals/CustomItemModal.vue';
import ProductDialog from '@/modules/products/components/modals/ProductDialog.vue';

const tabContent = {
  services: 0,
  products: 1,
};

export default {
  components: {
    CompactCheckoutDetails,
    CatalogList,
    CustomItemModal,
    ProductDialog,
  },
  data() {
    return {
      activeTab: tabContent.services,
      customItemModal: false,
      isDisplayingProductDialog: false,
      productDetailsModal: false,
    };
  },
  computed: {
    ...mapGetters('ServicesStore', {
      services: 'services',
      isServicesCompleted: 'completed',
      isServicesLoading: 'loading',
    }),
    ...mapGetters('ProductsStore', {
      products: 'products',
      isProductsCompleted: 'completed',
      isProductsLoading: 'loading',
    }),
    ...mapGetters('PosStore', ['getCountByUidAndType', 'checkoutItems', 'priceBreakdown', 'filterByCurrency', 'newProducts']),
    ...mapGetters('AuthStore', ['businessUid']),
    ...mapGetters('BusinessSettingsStore', ['businessCategory']),
    ...mapGetters('PaymentSettingsStore', ['currency', 'taxMode']),
    ...mapGetters('TaxesStore', ['taxes']),
    ...mapGetters('BusinessStore', ['locale']),
    tabsList() {
      return [
        { label: this.$t('point_of_sale.catalog_items.services') },
        { label: this.$t('point_of_sale.catalog_items.products') },
      ];
    },
    activeTabLoading() {
      return this.activeTab === tabContent.services ? this.isServicesLoading : this.isProductsLoading;
    },
    activeCatalogList() {
      const list = this.activeTab === tabContent.services ? this.catalogServicesList : this.catalogProductsList;
      return this.filterByCurrency(list);
    },
    activeIsCompleted() {
      return this.activeTab === tabContent.services ? this.isServicesCompleted : this.isProductsCompleted;
    },
    catalogServicesList() {
      return this.services.map((service) => ({
        uid: service.id,
        type: ENTITY_TYPES.Service,
        name: service.name,
        price: service.price,
        currency: service.currency,
        image: service.image_path,
        leftComment: this.getServiceLocationType(service.interaction_type),
        rightComment: BusinessDateFormats.duration(service.duration),
        count: this.getCountByUidAndType(service.id, ENTITY_TYPES.Service),
        charge_type: service.charge_type,
        taxes: service.taxes,
      }));
    },
    catalogProductsList() {
      const newUids = this.newProducts.map((product) => product.id);

      const catalogProducts = this.products.filter((item) => !newUids.includes(item.id));

      const productList = [...this.newProducts, ...catalogProducts].map((product) => ({
        uid: product.id,
        type: ENTITY_TYPES.Product,
        name: product.name,
        price: Number(product.price),
        currency: product.currency,
        image: product.image_url,
        count: this.getCountByUidAndType(product.id, ENTITY_TYPES.Product),
      }));

      return productList;
    },
    addCustomItemButtonText() {
      return this.$t('payment.payment_dialogs.add_custom_item');
    },
    shouldDisplayEmptyState() {
      return (this.activeTab === tabContent.products && this.catalogProductsList.length === 0);
    },
  },
  watch: {
    businessUid: {
      immediate: true,
      handler(value) {
        if (value) {
          this.loadServices();
          this.loadProducts();
          this.fetchBusinessSettings(value);
        }
      },
    },
  },
  methods: {
    ...mapActions('ServicesStore', ['loadServices', 'loadMoreServices']),
    ...mapActions('ProductsStore', ['loadProducts', 'loadMoreProducts', 'createProduct', 'setRefreshProducts']),
    ...mapActions('PosStore', ['addBillableItem', 'addNewProduct']),
    ...mapActions('BusinessSettingsStore', ['fetchBusinessSettings']),
    async loadMore($state) {
      if (this.activeTab === tabContent.services) {
        await this.loadMoreServices();
      } else {
        await this.loadMoreProducts();
      }

      if (this.activeIsCompleted) {
        $state.complete();
      } else {
        $state.loaded();
      }
    },
    getServiceLocationType(interactionType) {
      switch (interactionType) {
        case 'client_phone':
          return this.$t('point_of_sale.catalog_items.service_interaction_types.client_phone');
        case 'business_phone':
          return this.$t('point_of_sale.catalog_items.service_interaction_types.business_phone');
        case 'client_location':
          return this.$t('point_of_sale.catalog_items.service_interaction_types.client_location');
        case 'business_location':
          return this.$t('point_of_sale.catalog_items.service_interaction_types.business_location');
        case 'default_business_location':
          return this.$t('point_of_sale.catalog_items.service_interaction_types.default_business_location');
        case 'skype':
          return this.$t('point_of_sale.catalog_items.service_interaction_types.skype');
        case 'online':
          return this.$t('point_of_sale.catalog_items.service_interaction_types.online');
        case 'conference_bridge':
          return this.$t('point_of_sale.catalog_items.service_interaction_types.conference_bridge');
        case 'video_room':
          return this.$t('point_of_sale.catalog_items.service_interaction_types.video_room');
        case 'zoom':
          return this.$t('point_of_sale.catalog_items.service_interaction_types.zoom');
        default:
          return '';
      }
    },
    addCatalogItem(catalogItem) {
      if (catalogItem.charge_type === 'discuss') {
        this.$emit('edit-price-and-add', catalogItem);
      } else {
        this.addBillableItem({ uid: catalogItem.uid, type: catalogItem.type });
      }
    },
    goToCheckout() {
      this.$emit('go-to-checkout');
    },
    onAddCustomItem() {
      this.$track('Checkout - Custom Item Click');
      this.customItemModal = true;
    },
    onSaveToCheckoutCustomItem(item) {
      if (item.quantity && item.quantity > 0) {
        for (let i = 0; i < item.quantity; i++) {
          this.addBillableItem({ ...item.product });
        }
      }
    },
    async onCreateCustomItem(item) {
      const product = await this.createProduct(item.product);
      this.addNewProduct(product);
      if (this.activeTab !== tabContent.products) {
        this.activeTab = tabContent.products;
      }

      if (item.quantity && item.quantity > 0) {
        for (let i = 0; i < item.quantity; i++) {
          this.addBillableItem({ uid: product.id, type: item.type });
        }
      }
      this.$nextTick(() => {
        document.getElementsByClassName('VcTabsContent')[0].scrollTop = 0;
      });
    },
    onAddProduct() {
      this.productDetailsModal = true;
    },
    async onAddFirstProduct(item) {
      await this.createProduct(item.product);
      this.setRefreshProducts(true);
      await this.loadProducts();
    },
  },
};
</script>

<style lang="scss" scoped>
.pos-table-container {
  min-height: 0;

  &__tabs {
    min-height: 0;
  }
}
</style>
<style lang="scss">
@import "styles/variables.scss";

.pos-table-container {
  &__tabs {
    .VcTabs--content {
      padding-inline: var(--size-value4);

      @include md-and-up {
        padding-inline: var(--size-value6);
      }
    }
  }
  .add-custom-item {
    justify-content: flex-end;
    &__text {
      padding-left: var(--size-value2);
      font-weight: var(--font-weight-medium2);
    }
  }
}
</style>
