<template>
  <div>
    <MainMenu
      v-if="isMobileView && shouldShowMenu"
      :userHasRelevantFF="true"
      :staffData="staffData"
      :profileAvatar="profileAvatar"
      :mappedMenuItems="mappedMenuItems"
      :isMenuLoading="isLoading"
      :checklistProgress="checklistProgress"
      :conversationCounts="counts"
      @menuItemClick="onMenuItemClick"
      @subMenuItemClick="onMenuItemClick"
      @rightAreaClick="onRightAreaClick"/>

    <MainMenuWide v-if="!isMobileView"
                  :is-menu-loading="isLoading"
                  :mappedMenuItems="mappedMenuItems[0]"
                  :checklistProgress="checklistProgress"
                  :conversationCounts="counts"
                  :is-actions-disabled="isActionsDisabled"
                  @menuItemClick="onMenuItemClick"
                  @rightAreaClick="onRightAreaClick"/>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import loggerService from '@/modules/common/services/loggerService';
import LayoutConstants from '@/constansts/layoutConstants';
import MainMenu from '@/modules/layout/mainMenu/components/MainMenu.vue';
import MainMenuWide from '@/modules/layout/mainMenu/components/MainMenuWide.vue';
import MenuItemsUtils from '@/modules/layout/mainMenu/utils/menuItemsUtils';
import handleNavigation from '@/utils/navigationUtils';

export default {
  name: 'MenuContainer',
  components: { MainMenuWide, MainMenu },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    isActionsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    try {
      await Promise.all([
        this.addEssentialI18nModule('layout-pov'),
        this.addEssentialI18nModule('payments-pov'),
        this.addEssentialI18nModule('my-livesite-pov'),
        this.addEssentialI18nModule('quick-actions-pov'),
        this.addEssentialI18nModule('onboarding-pov'),
        this.addEssentialI18nModule('calendar-sync-pov'),
      ]);
      this.updateIsMenuTranslationLoading(false);
    } catch (e) {
      this.updateIsMenuTranslationLoading(true);
    }
  },
  methods: {
    ...mapActions('MainMenuStore', ['updateIsMenuTranslationLoading',
      'updateShouldShowMenu',
      'fetchApplicationsMenuItems',
      'dispatchMenuItemAction',
      'dispatchPovMenuItemAction']),
    ...mapActions('ModalStore', ['setForceModalMode']),
    onMenuItemClick(item) {
      if (this.isActionsDisabled && item?.name !== 'logout') return;
      this.menuItemAnalyticsTrack(item.name);
      this.fireMenuItemAction(item);
    },
    fireMenuItemAction(item) {
      if (item?.dispatchAction) {
        this.$store.dispatch(item.dispatchAction);
      }
      if (item?.isDeepLink) {
        this.updateShouldShowMenu(false);
        const itemPath = item?.url_path.startsWith('/') ? item?.url_path : `/${item?.url_path}`;
        handleNavigation({ path: itemPath, queryParams: item?.url_path_params || {} }, this.$router);
      } else if (item?.target) {
        this.updateShouldShowMenu(false);

        let povPath;
        let itemPath = item.target;
        if (item.url_path) {
          itemPath = item.url_path.startsWith('/app/') ? item.url_path.replace('/app', '') : item.url_path;
          povPath = this.$router.resolve(itemPath);
        }
        const isPovPage = povPath ? !povPath.route.meta.isAngular : item.type === 'pov';
        // const isPovPage = item.type === 'pov';
        this.setForceModalMode(false);
        if (isPovPage) {
          this.$router.push(itemPath);
        } else {
          MenuItemsUtils.fireNavigationAction(item);
        }
      }
      if (item?.action) {
        this.dispatchMenuItemAction(item);
      }
      if (item?.povAction) {
        this.dispatchPovMenuItemAction(item.povAction);
      }
    },
    onRightAreaClick(item) {
      if (this.isActionsDisabled) return;
      if (item?.externalLink || item?.rightIconAction) {
        const itemWithAction = { ...item, action: item.externalLink || item.rightIconAction };
        this.dispatchMenuItemAction(itemWithAction);
      }
      this.rightClickAnalyticsTrack(item.name);
    },
    getTranslatedItem(item) {
      return {
        ...item,
        label: this.getItemLabel(item),
        // eslint-disable-next-line localization/no-logic-in-translations
        badgeText: this.$t(item.badgeText),
      };
    },
    translateSubItems(subItems) {
      try {
        return subItems.map((item) => this.getTranslatedItem(item));
      } catch (error) {
        loggerService.error('MAIN-MENU-TRANSLATE-SUB-ITEMS', { subItems, error });
        return [];
      }
    },
    decorateSubItemAsMainCategory(item) {
      return {
        ...item.submenuItems[0],
        ...item,
        submenuItems: [],
      };
    },
    decorateCategoryWithSubItems(item) {
      return {
        ...item,
        submenuItems: this.translateSubItems(item.submenuItems),
      };
    },
    processMenuItem(item) {
      const isCategoryClicked = item.submenuItems?.length === 1;
      const menuItem = isCategoryClicked
        ? this.decorateSubItemAsMainCategory(item)
        : this.decorateCategoryWithSubItems(item);

      return this.getTranslatedItem(menuItem);
    },
    mapMenuGroupItems(menuItemsGroup) {
      try {
        return menuItemsGroup.map((item) => this.processMenuItem(item));
      } catch (error) {
        loggerService.error('MAIN-MENU-MAP-GROUP-ITEMS', { menuItemsGroup, error });
        return [];
      }
    },
    menuItemAnalyticsTrack(itemName) {
      this.$track('Main Menu Item Click', { item: itemName });
      if (itemName === 'client_portal_editor') {
        this.$track('Client Portal View');
      }
    },
    rightClickAnalyticsTrack(itemName) {
      if (itemName === 'client_portal_editor') {
        this.$track('Client Portal Menu Popout');
      }
    },
    getItemLabel(item) {
      if (item.name === LayoutConstants.MENU_DIRECTORY_WORK_AS) {
        // eslint-disable-next-line localization/no-logic-in-translations
        return this.$t(item.translationKey, { staff: this.staffData.display_name });
      }
      // eslint-disable-next-line localization/no-logic-in-translations
      return item.isAlreadyTranslated ? item.label : this.$t(item.translationKey);
    },
  },
  computed: {
    ...mapGetters('CommonStore', ['isMobile', 'isMobileApp']),
    ...mapGetters('BusinessStore', ['hasFeature', 'attributes']),
    ...mapGetters('MainMenuStore', ['isMenuTranslationLoading', 'shouldShowMenu', 'menuItems', 'checklistProgress']),
    ...mapGetters('StaffStore', ['staffData']),
    ...mapGetters('ConversationStore', ['counts']),
    isMobileView() {
      return this.isMobile || this.isMobileApp;
    },
    mappedMenuItems() {
      const decoratedMenuItems = this.menuItems.map((menuItemsGroup) => this.mapMenuGroupItems(menuItemsGroup));
      const filteredMenuItems = decoratedMenuItems.filter((group) => group.length > 0);
      return filteredMenuItems;
    },
    profileAvatar() {
      const avatarObj = {
        colorId: this.staffData.color_id || 27, // TODO: figure out where to get this value from
        name: this.staffData.display_name,
        imagePath: this.staffData.photo_path || this.attributes.photo_path || '',
      };
      return avatarObj;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
