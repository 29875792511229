<template>
  <VcLayout class="catalog-item" :class="{mobile: $isMobile()}" align-center
            @click="onAddDebounced()" tabindex="0"
            :data-qa="`catalog-item-${item.name}`">
    <VcBadge :badge-text="item.count"
             flavor="blue" offset-y="45" offset-x="15"
             class="flex-grow-0">
      <ItemImage :image="item.image" :type="item.type" class="catalog-item__image"/>
    </VcBadge>

    <VcLayout column class="flex-fill">
      <div class="text-truncate" :data-qa="`${item.name}`">{{ item.name }}</div>
      <div class="additional-detail text-truncate">{{ item.leftComment }}</div>
    </VcLayout>

    <VcLayout column align-end class="hide-on-hover flex-shrink-0">
      <div data-qa="item-price" >{{ formattedPrice() }}</div>
      <div class="additional-detail">{{ item.rightComment }}</div>
    </VcLayout>

    <VcLayout column align-end class="show-on-hover flex-shrink-0">
      <VcButton @click.stop="onAddDebounced" :label="addButtonText" data-qa="add-item"/>
    </VcLayout>
  </VcLayout>

</template>

<script>
import ItemImage from '@/modules/payments/components/posTable/ItemImage.vue';
import justDebounce from 'just-debounce';

export default {
  components: { ItemImage },
  props: {
    item: {
      type: Object,
      required: true,
      validator: (value) => value.uid && value.type && value.name && value.price >= 0 && value.currency,
    },
  },
  data() {
    return {
      onAddDebounced: justDebounce(this.onAdd, 300, true, true),
    };
  },
  computed: {
    addButtonText() {
      return this.$t('point_of_sale.catalog_items.add_item');
    },
  },
  methods: {
    onAdd() {
      const itemTypeMap = {
        discuss: 'Price varies service',
        no_price: 'Hidden service',
        Service: 'Service',
        Product: 'Product',
      };
      this.$track('Checkout - Add Catalog Item To Cart', {
        selected_item: itemTypeMap[this.item.charge_type] || itemTypeMap[this.item.type],
      });
      this.$emit('add-item', this.item);
    },
    formattedPrice() {
      return this.item.charge_type === 'discuss'
        ? this.$t('point_of_sale.catalog_items.no_price')
        : this.$priceDisplay(this.item.price, this.item.currency);
    },
  },
};
</script>

<style scoped lang="scss">
@import "styles/variables.scss";

.catalog-item {
  gap: var(--size-value3);
  height: var(--size-value16);
  padding: var(--size-value3);
  border: var(--border-frame);
  border-radius: var(--border-radius);
  color: var(--gray-darken-5);
  font-size: var(--font-size-x-small);
  font-weight: var(--font-weight-medium2);
  line-height: var(--font-size-medium0);
  cursor: pointer;

  &__image {
    width: var(--size-value10);
    height: var(--size-value10);
  }

  .additional-detail {
    color: var(--gray-darken-4);
    font-size: var(--font-size-xs-small);
    font-weight: var(--font-weight-medium);
    line-height: var(--font-size-small);
  }

  .show-on-hover {
    display: none;
  }

  @include md-and-up {
    &:hover {
      background-color: var(--gray-lighten-3);

      .show-on-hover {
        display: flex;
      }

      .hide-on-hover {
        display: none;
      }
    }
  }

  &:not(.mobile):focus-within, &:active {
    background-color: var(--gray-lighten-1);
  }
}
</style>
