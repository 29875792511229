import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import eventListenerService from '@/utils/eventCommunicationUtils/eventListenerService';

const angularModals = [
  'ClientPicker', 'NewNoteDialog',
  'NewPayment', 'TakePayment', 'NewPayment', 'NewInvoice', 'NewEstimate', 'PaymentCancel',
  'ConversationAssignment', 'RecurrenceApply', 'FullscreenVueDialog', 'OnlineScheduling',
  'PaymentWizard', 'XpertWizard', 'AppMarketWizard', 'PaymentsUpgradeDialog', 'UpgradeDialog',
  'SchedulePayment', 'NewClient', 'NewMessage', 'NewAppointment', 'NewNoteDialog', 'NewDocument',
  'LinkBuilderDialog', 'NewAnnouncement', 'YextCreateService', 'AppWizardService', 'CoreWizardService',
  'OnboardingSelections', 'Confirmation', 'GettingStartedActions', 'NpsPopupService', 'ClientBulkTags',
  'ImportContacts', 'NewReport', 'CloseBalance', 'ClientBulkStatus', 'ClientMerge', 'SendLinkSuccess',
  'ClientPicker', 'ImageUploader', 'PaymentsSettingsDialog', 'DownloadAppWizard', 'MarketingWizard',
];

const state = {
  angularModals,
  openModals: [],
  openModalsPayloads: {},
  forceModalMode: false,
};

const mutations = {
  setOpenModals(state, value) {
    state.openModals = value;
  },
  setAngularModals(state, value) {
    state.angularModals = value;
  },
  setForceModalMode(state, value) {
    state.forceModalMode = value;
  },
  setOpenModalsPayloads(state, value) {
    state.openModalsPayloads = value;
  },
};

const showAngularBackground = (showBackground) => {
  outgoingPostMessageUtils.post('show-angular-background', { showBackground });
};

const openAngularModal = (angularModalState, name, payload, functionName, identifier, hideBackground = true) => {
  if (angularModalState.includes(name)) {
    outgoingPostMessageUtils.post('open-modal', {
      name,
      payload,
      functionName,
      identifier,
      hideBackground,
    });
  } else {
    console.error('trying to open unknown modal in Angular', name);
  }
};

const actions = {
  showAngularBackground(data, showBackground) {
    showAngularBackground(showBackground);
  },
  /**
   * @param commit
   * @param state
   * @param rootState
   * @param name Name of the modal to open
   * @param payload Array of parameters to pass to the modal, in the order they are expected
   * @param functionName By default 'open', this allows to call another function
   * @param identifier Text identifier of the modal, used to differentiate between multiple modals of the same type
   */
  async openModal({
    commit, state, rootState, getters,
  }, {
    name,
    payload,
    functionName = 'open',
    identifier = '',
  }) {
    const { isAngularLoaded, isAngularDisplayed } = rootState.AngularStore;
    if (!payload || Array.isArray(payload)) {
      if (getters.isAngularModal(name)) {
        if (isAngularLoaded) {
          openAngularModal(state.angularModals, name, payload, functionName, identifier, !isAngularDisplayed);
          commit('setOpenModals', [...state.openModals, name]);
        } else {
          eventListenerService.addListener('angularLoaded', () => {
            const lateIsAngularDisplayed = rootState.AngularStore.isAngularDisplayed;
            openAngularModal(state.angularModals, name, payload, functionName, identifier, !lateIsAngularDisplayed);
            commit('setOpenModals', [...state.openModals, name]);
          });
        }
      } else {
        commit('setOpenModals', [...state.openModals, name]);
        commit('setOpenModalsPayloads', { ...state.openModalsPayloads, [name]: payload });
      }
    } else {
      console.error(`Calling modal ${name} with params that are not an array`, payload);
    }
  },
  async closeModal({ commit, state }, { name, result, identifier }) {
    const updatedOpenModals = [...state.openModals];
    updatedOpenModals.splice(state.openModals.indexOf(name), 1);
    commit('setOpenModals', updatedOpenModals);

    const updatedOpenModalsPayloads = { ...state.openModalsPayloads };
    delete updatedOpenModalsPayloads[name];
    commit('setOpenModalsPayloads', updatedOpenModalsPayloads);

    eventListenerService.emit('modalClosed', { name, result, identifier });
  },
  /**
   * This action is used to update the list of Angular modals based on FF or other considerations
   */
  removeAngularModal({ commit, state }, name) {
    const copy = [...state.angularModals];
    copy.splice(state.angularModals.indexOf(name), 1);
    commit('setAngularModals', copy);
  },
  /**
   * This is used for certain modals, such as quick actions and app market, which are opened in a different way
   * (not through the modal store).
   * While the forceModalMode is set to true, the angular iframe will have display: block, so it will be rendered,
   * even when in a lower z-index, so it will be taking resources from the browser.
   * It can be difficult to identify the exit point of this state, so for now we will always remove it on menu navigation.
   */
  setForceModalMode({ commit }, value) {
    commit('setForceModalMode', value);
  },
};

const getters = {
  openModals: (state) => state.openModals,
  openAngularModals: (state) => state.openModals.filter((item) => state.angularModals.includes(item)),
  isModalOpen: (state, { isAngularModal }) => (name) => !isAngularModal(name) && state.openModals.includes(name),
  isAngularModal: () => (name) => state.angularModals.includes(name),
  isAngularModalOpen: (state) => state.forceModalMode || state.openModals.some((item) => state.angularModals.includes(item)),
  getOpenModalPayload: (state) => (name) => (state.openModalsPayloads[name] ? state.openModalsPayloads[name][0] : []),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
