<template>
  <div class="overlay">
    <AiGenerator
      ref="aiGeneratorRef"
      :anchor-y="y"
      :anchor-x="x"
      absolute
      offset-y
      :feature="feature"
      :agent-name="agentName"
      :target-text-value="targetTextValue"
      :variables="variables"
      :analyticsParameters="analyticsParameters"
      :force-chat-uid="elementToChatUid[currentElementId]"
      :dropdownState="dropdownMenuOpen"
      :info-banner-text="infoBannerText"
      :override-generate-output="overrideGenerateOutput"
      @dropdownStateChange="dropDownStateChange"
      @startStream="startStream"
      @onStream="onStream"
      @endStream="endStream"
      @onChatInit="onChatInit"
      @onOverrideGenerateOutput="onOverrideGenerateOutput"
    />
  </div>
</template>

<script>
import eventListenerService from '@/utils/eventCommunicationUtils/eventListenerService';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import AiGenerator from '@/modules/aiContentGenerator/components/AiGenerator.vue';
import { mapActions } from 'vuex';

export default {
  name: 'AiGeneratorAngularAdapter',
  components: {
    AiGenerator,
  },
  data() {
    return {
      // coordinates of the angular button location
      // this is where the AiGenerator dropdown will be positioned based on "position-x" and "position-y" props
      // along-side with the "absolute" props
      x: 0,
      y: 0,
      // elementId of the button that was clicked
      currentElementId: null,
      // // shouldClick is a flag that toggles the AiGenerator component.
      // // it simulates the AiGenerator activator button click event (but it's invisible in the angular singleton)
      // // there might be a better way to do this, but this is the best I could come up with now
      // shouldClick: false,
      // init data from angular component
      agentName: '',
      feature: '',
      targetTextValue: '',
      variables: {},
      analyticsParameters: {},
      // element to chat uid mapping
      elementToChatUid: {},
      infoBannerText: null,
      dropdownMenuOpen: false,
      overrideGenerateOutput: undefined,
      events: {
        'ai-generator-button-clicked': this.clickHandler,
        'ai-generator-add-feedback': this.addFeedbackHandler,
      },
    };
  },
  created() {
    // eslint-disable-next-line max-len
    eventListenerService.addListener('bizai-event', this.handleEvent);
    this.$router.beforeEach((to, from, next) => {
      // close the dropdown menu when navigating to a different route only if it's open
      // since the used overlay is shared among other components
      if (this.dropdownMenuOpen) {
        this.dropDownStateChange(false);
      }
      next();
    });
  },
  destroyed() {
    eventListenerService.removeListener('bizai-event', this.handleEvent);
  },
  methods: {
    ...mapActions('AngularStore', ['setIsOverlayDisplay']),
    ...mapActions('AiGeneratorStore', ['addFeedback']),
    handleEvent(data) {
      const bizaiEvent = data.data;
      this.events[bizaiEvent.eventName](bizaiEvent.data);
    },
    dropDownStateChange(state) {
      this.dropdownMenuOpen = state;
      this.setIsOverlayDisplay(state);
    },
    onChatInit(chatUid) {
      this.elementToChatUid[this.currentElementId] = chatUid;
    },
    startStream() {
      this.postMessage('startStream', true);
    },
    onStream(token) {
      this.postMessage('onStream', token);
    },
    endStream(msg) {
      this.postMessage('endStream', msg);
    },
    onOverrideGenerateOutput(data) {
      this.postMessage('overrideGenerateOutput', data);
      this.dropDownStateChange(false);
    },
    postMessage(event, data) {
      if (data) {
        outgoingPostMessageUtils.post('ai-generator', {
          type: event,
          elementId: this.currentElementId,
          value: data,
        });
      }
    },
    addFeedbackHandler(data) {
      const {
        feature, key, value,
      } = data;

      try {
        this.addFeedback({
          feature,
          feedback: {
            key, value,
          },
        });
      } catch (e) {
        console.error('Error sending feedback', e);
      }
    },
    clickHandler(data) {
      const {
        agent: agentName, feature, targetTextValue, variables, analyticsParameters,
      } = data.initData;
      this.agentName = agentName;
      this.feature = feature;
      this.targetTextValue = targetTextValue;
      this.variables = variables;
      this.analyticsParameters = analyticsParameters;
      this.infoBannerText = data.infoBannerText;

      this.currentElementId = data.elementId;
      this.overrideGenerateOutput = data.overrideGenerateOutput;

      // Positioning
      this.x = data.rect.left;
      this.y = data.rect.bottom + 8;

      this.dropDownStateChange(true);
    },
  },
};
</script>

<style scoped lang="scss">

.overlay {
  z-index: 4;
}

</style>
