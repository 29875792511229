import httpService from '@/modules/common/services/httpService';

const baseRoute = '/v3/sales/reports/';
const widgetDataRoute = 'payments_widget';
const forcastRoute = 'forecast_payments';

const getSalesWidgetData = async () => httpService.get(`${baseRoute}${widgetDataRoute}`)
  .then((response) => response.data);

const getForecastData = async () => httpService.get(`${baseRoute}${forcastRoute}`)
  .then((response) => response.data);

export default {
  getSalesWidgetData,
  getForecastData,
};
