<template>
  <VcLayout class="catalog-list" column>
    <VcLayout v-if="showEmptyState" class="catalog-list__empty-state" d-flex justify-center align-center>
          <EmptyState class="empty-state" v-bind="emptyStateText" @empty-state-click="openCreateProductDialog"/>
    </VcLayout>

    <VcLayout v-else column class="catalog-list__items">
      <CatalogLine v-for="catalogItem in catalogItems" :key="catalogItem.uid"
                   :item="catalogItem" @add-item="onAdd"/>
    </VcLayout>

    <VcInfiniteScroll v-if="catalogItems.length && !completed"
                      :is-empty="false"
                      no-result-text="No catalog items found"
                      :identifier="infiniteId"
                      @onScroll="loadMore"/>
  </VcLayout>
</template>

<script>
import CatalogLine from '@/modules/payments/components/posTable/CatalogLine.vue';
import EmptyState from '@/modules/payments/components/posTable/EmptyState.vue';

export default {
  name: 'CatalogList',
  components: { CatalogLine, EmptyState },
  data() {
    return {
      infiniteId: +new Date(),
    };
  },
  props: {
    catalogItems: {
      type: Array,
      required: true,
      validator: (value) => value.every((item) => item.uid && item.type && item.name && item.price >= 0 && item.currency),
    },
    completed: {
      type: Boolean,
      required: true,
    },
    showEmptyState: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    emptyStateText() {
      return {
        title: this.$t('point_of_sale.catalog_items.empty_states.no_products_created.title'),
        subTitle: this.$t('point_of_sale.catalog_items.empty_states.no_products_created.sub_title_a'),
        linkText: this.$t('point_of_sale.catalog_items.empty_states.no_products_created.sub_title_b'),
      };
    },
  },
  methods: {
    loadMore($state) {
      this.$emit('load-more', $state);
    },
    onAdd(catalogItem) {
      this.$emit('add-item', catalogItem);
    },
    openCreateProductDialog() {
      this.$emit('open-add-product');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "styles/variables.scss";

.catalog-list {
  padding-block: var(--size-value3);
  padding-inline: var(--size-value4);

  &__empty-state {
    gap: var(--size-value4);
    align-self: stretch;
    padding: var(--size-value18) var(--size-value8) var(--size-value6);
  }

  @include md-and-up {
    padding-inline: var(--size-value6);
  }

  &__items {
    gap: var(--size-value2);
  }
}
</style>
