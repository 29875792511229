import httpService from '@/modules/common/services/httpService';

const gatewayService = httpService.create(window.pov_config.VUE_APP_API_URL_GATEWAY);
const channelsBasePath = 'business/messaging/v1/channels/';
const baseRoute = 'v3/communication';

const getCallsSettings = async () => gatewayService.get(`${baseRoute}/voice_call_settings`).then((res) => res.data);

const createCallsSettings = async (settings) => gatewayService.post(`${baseRoute}/voice_call_settings`, settings).then((res) => res.data);

const updateCallsSettings = async (settingsUid, updatedSettings) => gatewayService.put(`${baseRoute}/voice_call_settings/${settingsUid}`, updatedSettings).then((res) => res.data);

const getRemainingQuota = async () => gatewayService.get(`${baseRoute}/voice_call_quotas`).then((res) => res.data);

const getBusinessActiveDedicatedNumber = async (params) => {
  const result = await httpService.get(channelsBasePath, { new_api: true, provider: 'nexmo', ...params });
  return result.data.data.channels?.find((number) => (number.number_type === 'transactional')
    && (number.status === 'active' || number.status === 'inactive') && !number.pool);
};

const toggleTextingStatus = async (status) => httpService
  .put('platform/v1/numbers/dedicated_numbers/set_two_way_texting_status', { status })
  .then((res) => res);

const claimNumber = async ({ voiceTextAppInstalled }) => httpService.post(
  `${channelsBasePath}`,
  { new_api: true, channel: { provider: 'nexmo', voice_text_app_installed: voiceTextAppInstalled } },
).then((res) => res.data?.data.channel);

export default {
  getCallsSettings,
  createCallsSettings,
  updateCallsSettings,
  getRemainingQuota,
  getBusinessActiveDedicatedNumber,
  toggleTextingStatus,
  claimNumber,
};
