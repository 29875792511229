<template>
  <VcLayout class="forecast-graph" ref="component">
      <VcLayout class="forecast-graph__title"> {{ title }} </VcLayout>
      <BarChart :chart-data="chartData"
               :chart-options="{ maintainAspectRatio: false }"
               :y-axis-max="maxYAxis || 1000"
               :y-axis-step-size="yStepSize"
               :y-axis-grid-line-display="!isEmpty"
               :tooltip-callback="tooltipCallback"
               :legend-display="false"
               :tooltip-title-callback="() => null"
               :y-axis-tick-callback="yAxisTickCallback"
               :aria-label="$t('widget.sales_widget.forecast_graph.aria_label')"
               class="forecast-graph__chart">
        <template #emptyStateArea>
          <VcLayout align-center>
            <VcEmptyState
              title=""
              class="mb-14"
              :subtitle="$t('widget.sales_widget.forecast_graph.empty_state')"
              size="md"
              :image="require('@/assets/images/payments/sale_forecast_empty.svg')"
              outlined-button
            />
          </VcLayout>
        </template>
    </BarChart>
  </VcLayout>
</template>

<script>
import businessDateFormats from '@/modules/common/filters/BusinessDateFormats';

const LARGE = 583;
const X_LARGE = 758;

const BarChart = () => import(/* webpackChunkName: "BarChart" */ '@/modules/common/components/charts/BarChart/BarChart.vue');

export default {
  name: 'ForecastGraph',
  components: {
    BarChart,
  },
  props: {
    forecastData: {
      type: Object,
      default: null,
    },
    currency: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      componentWidth: 0,
    };
  },
  async mounted() {
    window.addEventListener('resize', this.updateComponentWidth);
    // Wait for the component to be visible before updating the width
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.updateComponentWidth();
        observer.disconnect();
      }
    });
    observer.observe(this.$el);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateComponentWidth);
  },
  computed: {
    title() {
      return this.$t('widget.sales_widget.forecast_graph.header');
    },
    hasData() {
      return Object.keys(this.forecastData ?? {}).length > 0;
    },
    chartColumnCount() {
      if (this.componentWidth > X_LARGE) return 10;
      if (this.componentWidth > LARGE) return 8;
      return 5;
    },
    chartDateLabels() {
      return Object.keys(this.sortedChartData);
    },
    chartColumnsData() {
      return Object.values(this.sortedChartData).map((week) => week.total_amount ?? 0);
    },
    chartData() {
      return {
        labels: this.chartDateLabels,
        datasets: [{
          data: this.chartColumnsData,
          backgroundColor: '#4CAF50',
        }],
      };
    },
    sortedChartData() {
      const startOfWeek = this.getStartOfWeek;

      const data = this.forecastData ?? {};
      const futureKeys = Object.keys(data).filter((key) => new Date(key) >= startOfWeek);
      const sortedKeys = futureKeys.sort((a, b) => new Date(a) - new Date(b));
      const barsToDisplay = sortedKeys.slice(0, this.chartColumnCount);

      const transformedData = barsToDisplay.reduce((acc, key) => {
        const newKey = businessDateFormats.dateNoCurrentYear(key);
        acc[newKey] = data[key];
        return acc;
      }, {});

      return transformedData;
    },
    getStartOfWeek() {
      const currentDate = new Date();
      const startOfWeek = new Date(currentDate);
      // Get the previous Sunday by subtracting the current day number
      // It's doesn't matter the businsess start work day - we want to include the current week
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
      startOfWeek.setHours(0, 0, 0, 0);
      return startOfWeek;
    },
    isEmpty() {
      return !this.hasData || this.chartColumnsData.every((value) => value === 0);
    },
    maxYAxis() {
      const max = Math.max(...this.chartColumnsData) * 1.1;
      // set maxmun value of the y-axis - add 10 % more and round it to the next 50
      return Math.ceil(max / 50) * 50;
    },
    yStepSize() {
      const yStepSizeDefault = 200;
      // Simply divide maxYAxis by 5 to get evenly spaced steps
      // maxYAxis is already rounded to nearest 50, so result will be clean
      return Math.ceil(this.maxYAxis / 5) || yStepSizeDefault;
    },
    tooltipCallback() {
      return (tooltipItem) => {
        const week = tooltipItem.label;
        const calendarWeek = this.sortedChartData[week]?.calendar_week ?? 'N/A';
        const endWeekDate = businessDateFormats.dateNoCurrentYear(new Date(this.sortedChartData[week]?.week_end_date));
        const amountDisaply = this.$priceDisplay(tooltipItem.raw, this.currency);
        return this.$t('widget.sales_widget.forecast_graph.tooltip', {
          amount: amountDisaply,
          calendar_week: calendarWeek,
          start_week: week,
          end_week: endWeekDate,
        });
      };
    },
    yAxisTickCallback() {
      return (value) => (value >= 0 ? this.$priceDisplay(value, this.currency) : '');
    },
  },
  methods: {
    updateComponentWidth() {
      this.componentWidth = this.$el.offsetWidth;
    },
  },
};
</script>

<style scoped lang="scss">
.forecast-graph {
  min-height: 300px;
  gap: var(--size-value4);
  height: 100%;
  display: flex;
  flex-direction: column;

  &__chart {
    flex-grow: 1;
    margin-bottom: -4px;
  }

  &__title {
    font-size: var(--font-size-small2);
    font-weight: var(--font-weight-large2);
    line-height: var(--size-value6);
  }
  ::v-deep .VcEmptyStateSubtitle {
    max-width: 50%;
    margin-left: 25%;
  }

  ::v-deep .VcEmptyStateImage {
    max-height: 72px;

    .image {
      width: 81px;
    }
  }
}

</style>
