import formattingUtil from '@/utils/formattingUtil';

const addToVueInstance = (Vue) => {
  Vue.prototype.$priceDisplay = function (amount, currency = 'USD', locale = this.$i18n.locale) {
    return formattingUtil.priceDisplay(amount, currency, locale);
  };
  Vue.prototype.$percentageDisplay = (amount) => formattingUtil.percentageDisplay(amount);
  Vue.prototype.$currencySymbol = function (currency, locale = this.$i18n.locale) {
    return formattingUtil.getCurrencySymbol(currency, locale);
  };
};

export default {
  addToVueInstance,
};
