<template>
  <VcLayout column class="clients-widget widget-wrapper" ref="clientsWidget">
    <!--    eslint-disable-next-line vuejs-accessibility/click-events-have-key-events       -->
    <div @click="onTitleClick" class="widget-title ma-5 mb-0">
      {{matterNamePluralCapitalized}}
    </div>
    <template v-if="isLoading">
      <VcSkeleton
          v-for="n in maxClients" :key="n"
          class="px-5"
          data-qa="VcSkeleton"
          type="list-item-avatar-two-line"
      />
    </template>
    <template v-else>
      <div class="views-select mt-4 mx-5">
        <VcSelectField
            :items="clientsViews"
            :label="selectLabel"
            :pre-selection="currentView"
            @selection-changed="onViewSelect"
            data-qa="VcSelectField"
        />
      </div>
      <div v-if="clients.length" class="mt-4 mx-2">
        <div v-for="client in clients"
             :key="client.matter_uid">
          <VcClientItem
              data-qa="VcClientItem"
              truncate-text
              :matter-name="client.displayName"
              :contact-name="client.contact_full_name"
              :subtitle="lastInteraction(client)"
              :client-avatar="{
            imagePath: client.photo_path || '',
            name: client.matter_name,
            colorId: client.color_id,
            size: 'x-sm',
          }"
              show-actions-on-hover
              class="client-item pl-4"
              @click="goToClient(client)"
              @onSendMessageClicked="sendMessage(client)"
          />
        </div>
      </div>
      <VcLayout v-else align-center class="mt-4">
        <VcEmptyState
            title=""
            :subtitle="emptyStateTitle"
            size="md"
            :image="require('@/assets/images/widgets/no_clients.svg')"
            outlined-button
            data-qa="VcEmptyState"
        />
      </VcLayout>
    </template>
  </VcLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import viewService from '@/modules/crm/services/viewService';
import BusinessDateFormats from '@/modules/common/filters/BusinessDateFormats';
import storageService from '@/modules/common/services/storageService';

const CLIENT_ITEM_HEIGHT = 64;
const SAVED_VIEW_KEY = 'clients-widget-selected-view';
export default {
  name: 'ClientsWidget',
  data() {
    return {
      fetchClientsData: {},
      isLoading: false,
      currentView: null,
      maxClients: 7,
    };
  },
  computed: {
    ...mapGetters('BusinessStore', ['matterTermTranslation']),
    ...mapGetters('ClientStore', ['clientResults']),
    ...mapGetters('ViewStore', ['allViewsList']),
    matterNameSingular() {
      // eslint-disable-next-line localization/no-logic-in-translations
      return this.$t(this.matterTermTranslation?.lowercase_singular);
    },
    matterNamePluralCapitalized() {
      // eslint-disable-next-line localization/no-logic-in-translations
      return this.$t(this.matterTermTranslation?.capitalized_plural);
    },
    emptyStateTitle() {
      // eslint-disable-next-line localization/no-logic-in-translations
      const matterNamePlural = this.$t(this.matterTermTranslation?.lowercase_plural);
      return this.$t('client.crm_page.crm_table.empty_state.title', {
        matter_term_plural: matterNamePlural,
      });
    },
    clientsViews() {
      const viewsList = [...this.allViewsList];
      return viewsList
        .sort((a, b) => {
          if (a.order === null && b.order !== null) return 1;
          if (b.order === null && a.order !== null) return -1;
          return a.order - b.order;
        })
        .map((view) => ({ ...view, text: view.name, value: view.uid }));
    },
    clients() {
      const clients = this.clientResults(this.currentView?.uid);
      if (!clients) return [];
      return clients.map((client) => ({
        ...client,
        displayName: this.getDisplayMatterName(client.matter_name, client.client_type === 'demo_client'),
      }));
    },
    hasShowFiltersFF() {
      return this.$hasFeature('crm_show_filters');
    },
    selectLabel() {
      return this.$t('widget.clients_widget.matters_view', {
        matter_term_plural_capitalized: this.matterNamePluralCapitalized,
        view: this.$t('widget.clients_widget.view'),
      });
    },
  },
  async created() {
    this.isLoading = true;
    this.addI18nModule('client-pov');
    this.addI18nModule('widgets-pov');
    await this.init();
    this.isLoading = false;
    this.$emit('ready');
    this.$emit(
      'track',
      'View',
      { default_view_name: this.currentView.name },
    );
  },
  methods: {
    ...mapActions('ClientStore', ['resetClients', 'fetchClients']),
    ...mapActions('ViewStore', ['fetchViews']),
    ...mapActions('ModalStore', ['openModal']),
    async init() {
      this.resetClients();
      await this.fetchViews();
      // Check if there's a saved view ID using storageService
      const savedViewId = storageService.get(SAVED_VIEW_KEY);

      if (savedViewId && this.clientsViews.some((view) => view.uid === savedViewId)) {
        // If saved view exists in available views, use it
        this.currentView = this.clientsViews.find((view) => view.uid === savedViewId);
      } else {
        // Otherwise fall back to default view or first view
        const defaultView = this.clientsViews.find((view) => view.default_view === true);
        this.currentView = defaultView || this.clientsViews[0];
      }
      this.calculateMaxClients();
      await this.onFetchClients({ setClients: true }, this.currentView);
      this.loading = false;
    },
    calculateMaxClients() {
      const topWidgetHeight = 120;
      const widgetHeight = this.$refs.clientsWidget.$el.parentElement.clientHeight;
      if (!widgetHeight) return;
      const clientsListHeight = widgetHeight - topWidgetHeight;
      this.maxClients = Math.floor(clientsListHeight / CLIENT_ITEM_HEIGHT);
    },
    async onViewSelect(view) {
      this.$emit(
        'track',
        'View Change',
        {
          previous_view_name: this.currentView.name,
          new_view_name: view.name,
          is_system_view: view.level === 'system',
        },
      );
      this.currentView = view;
      // Save the selected view ID using storageService
      storageService.save(SAVED_VIEW_KEY, view.uid);
      this.isLoading = true;
      await this.onFetchClients({ setClients: true }, view);
      this.isLoading = false;
    },
    async onFetchClients(data, view) {
      const filter = this.hasShowFiltersFF
        ? viewService.getSearchFiltersObjectFromNewDecorationFiltersObject(view.filtersObject)
        : view.filtersObject;
      const payload = { ...data, viewUid: view.uid };
      await this.fetchClients({
        ...payload, page: 1, perPage: this.maxClients, filter,
      });
      this.fetchClientsData[view.uid] = { ...payload, setClients: false };
      if (!this.clients.length) {
        this.$emit(
          'track',
          'Empty State View',
          { view_name: this.currentView.name },
        );
      }
    },
    goToClient(client) {
      this.$emit('track', 'Client Click');
      this.$emit('sdkAction', 'Navigate', {
        type: 'relative',
        route: 'app.client',
        queryParams: { uid: client.contact_uid, matter_uid: client.matter_uid },
      });
    },
    sendMessage(client) {
      const mappedClient = {
        matter_uid: client.matter_uid,
        full_name: client.contact_full_name,
        matter_name: client.matter_name,
        photo_path: client.photo_path,
        color_id: client.color_id,
        email: client.contact_email,
      };
      this.$emit('sdkAction', 'OpenModal', {
        modalName: 'NewMessage',
        modalProps: [null, mappedClient],
      });
    },
    lastInteraction(client) {
      if (!client.last_interaction) return '';
      const dayMonthText = BusinessDateFormats.dateNoCurrentYear(client.last_interaction);
      // eslint-disable-next-line localization/no-logic-in-translations
      return `${this.$t('widget.clients_widget.last_activity')}: ${dayMonthText}`;
    },
    onEmptyClick() {
      this.openModal({
        name: 'NewClient',
        identifier: 'NewMatter',
        payload: [null],
      });
    },
    getDisplayMatterName(matterName, isDemoClient) {
      return isDemoClient ? this.$t('client.crm_page.demo_client', { matterName }) : matterName;
    },
    onTitleClick() {
      this.$emit('track', 'Title Click');
      this.$emit('sdkAction', 'Navigate', {
        type: 'relative',
        path: 'clients',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.widget-wrapper {
  line-height: normal;
  text-align: start;
  width: 100%;

  ::v-deep {
    .VcEmptyState .VcEmptyStateImage {
      height: 56px;
    }
  }
}
.widget-title {
  font-size: var(--font-size-medium2);
  font-weight: var(--font-weight-large2);
  color: var(--gray-darken-5);
  cursor: pointer;

  &:hover {
    color: var(--link-color);
  }
}
.clients-list {
  text-align: start;
}
.client-item {
  cursor: pointer;
}
::v-deep(.client-item-action) {
  display: none;
}
</style>
