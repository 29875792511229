<template>
  <VcLayout class="billable-item-wrapper" :class="{ highlighted }" flex-grow-0>
    <VcDropdown offset-y offset-overflow class="item-actions" :close-on-content-click="true"
                v-model="isDropdownOpen">
      <template #activator="{ on, attrs }">
        <VcLayout class="billable-item-container"
                  v-bind='attrs' v-on="!$isMobile() ? {} : on"
                  :data-qa="dataQa">
          <VcLayout class="billable-item-container__border">
            <VcLayout column class="billable-item-container__wrapper flex-fill" justify-center>
              <div class="billable-item-container__name">
                {{ item.name }}
              </div>
              <div class="billable-item-container__description" v-if="item.description">
                {{ item.description }}
              </div>
            </VcLayout>

            <VcLayout column
                      class="hide-on-hover billable-item-container__wrapper flex-grow-0 flex-shrink-0"
                      align-end justify-center>
                <VcLayout class="priceWrapper" :class="{hasDiscount: itemHasDiscount}" align-center>
                  <div class="priceBeforeDiscount" v-if="itemHasDiscount">
                    {{ $priceDisplay(item.priceBreakdown.priceBeforeDiscount, item.currency) }}
                  </div>
                  <div class="billable-item-container__price">
                    {{ $priceDisplay(item.priceBreakdown.displayPrice, item.currency) }}
                  </div>
                </VcLayout>
                <div class="billable-item-container__discount" v-if="itemHasDiscount">
                  {{ $t('point_of_sale.edit_item_dialog.discount') }}
                  {{' '}}
                  {{ discountDisplay }}
                </div>
              <div class="billable-item-container__tax_note" v-if="item.priceBreakdown.taxTotal">
                {{ taxText }}
              </div>
            </VcLayout>

            <VcLayout align-center class="show-on-hover flex-grow-0 flex-shrink-0">
              <VcLayout v-for="item in itemActions"
                        :key="item.event"
                        class="item-actions__desktop-list-item"
                        :data-qa="`item-action-desktop-${item.event}`">
                <VcTooltip :content="item.title" dark top align-end :openDelay="1200">
                  <VcButton :class="`billable-item-container__desktop-${item.event}-button`"
                            ghost flavor="secondary" :width="40" :disabled="item.disabled"
                            @click="handleAction(item)">
                    <VcIcon :class="item.icon" size="16"/>
                  </VcButton>
                </VcTooltip>
              </VcLayout>
            </VcLayout>
          </VcLayout>
        </VcLayout>
      </template>
      <template>
        <VcLayout column class="item-actions__list" data-qa="item-actions-list">
          <VcLayout v-for="item in itemActions"
                    :key="item.event"
                    align-center
                    @click="handleAction(item)"
                    class="item-actions__list-item"
                    :class="{ 'disabled': item.disabled }"
                    :data-qa="`item-action-${item.event}`">
            <VcIcon size="20" color="currentColor">{{ item.icon }}</VcIcon>
            <div class="flex-fill">{{ item.title }}</div>
          </VcLayout>
        </VcLayout>
      </template>

    </VcDropdown>
  </VcLayout>
</template>
<script>
import { TAX_MODES } from '@/modules/payments/utils/taxUtil';

export default {
  name: 'BillableItem',
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
      validator: (prop) => prop.name && prop.grandTotal >= 0 && prop.currency,
    },
    dataQa: {
      type: String,
      default: '',
    },
    taxMode: {
      type: String,
      required: true,
      validator: (value) => Object.values(TAX_MODES).includes(value),
    },
  },
  methods: {
    editItem() {
      this.$track('Checkout - Edit Item Price In Cart');
      this.$emit('edit-item');
    },
    removeItem() {
      this.$track('Checkout - Remove Item From Cart');
      this.$emit('remove-item');
    },
    handleAction(item) {
      if (item.disabled) return;
      if (item.event === 'remove') this.removeItem();
      else if (item.event === 'edit') this.editItem();
      this.isDropdownOpen = false;
    },
  },
  computed: {
    highlighted() {
      return this.item.highlighted && !this.$isMobile();
    },
    itemActions() {
      return [
        {
          title: this.$t('common.words.edit'),
          icon: 'icon-Temp_pencil-s22',
          event: 'edit',
          disabled: this.item.readonly,
        },
        {
          title: this.$t('common.words.remove'),
          icon: 'icon-canceled',
          event: 'remove',
        },
      ];
    },
    taxText() {
      if (this.taxMode === TAX_MODES.exclude) {
        return this.$t('point_of_sale.checkout_items.tax_excluded');
      }
      return this.$t('point_of_sale.checkout_items.tax_included');
    },
    itemHasDiscount() {
      return !!this.item.discount?.value;
    },
    discountDisplay() {
      if (this.item.discount.type === 'precentage') {
        return `-${this.$percentageDisplay(this.item.discount.value)}`;
      }
      return `-${this.$priceDisplay(this.item.discount.value, this.item.currency)}`;
    },
  },
};
</script>
<style scoped lang="scss">
@import "styles/variables.scss";

.billable-item-wrapper.highlighted {
  $transition-duration: 600ms;
  $transition-delay: 200ms;
  $fade-delay-increment: 100ms;
  $max-items: 10;

  @keyframes fadeToTransparent {
    0% {
      background-color: var(--v-secondary-lighten3);
    }
    100% {
      background-color: transparent;
    }
  }

  background-color: var(--v-secondary-lighten3);
  animation: fadeToTransparent $transition-duration ease-out;
  animation-fill-mode: forwards;

  @for $i from 1 through $max-items {
    &:nth-of-type(#{$i}) {
      animation-delay: $transition-delay + ($i - 1) * $fade-delay-increment;
    }
  }
}

.billable-item-container {
  min-height: var(--size-value15);

  &__border {
    margin-inline: var(--size-value4);
    border-bottom: var(--border-frame);
    gap: var(--size-value2);
  }

  &__wrapper {
    min-width: 0;
    padding-block: var(--size-value4);
    gap: var(--size-value1);

    .priceWrapper {
      line-height: var(--size-value5);

      &.hasDiscount {
        gap: var(--size-value2);

        .priceBeforeDiscount{
          color: var(--gray-darken-2);
          font-size: 15px;
          font-weight: 500;
          text-decoration-line: line-through;
        }
      }
    }
  }

  &__name, &__price {
    font-size: var(--font-size-small2);
    color: var(--gray-darken-5);
    font-weight: var(--font-weight-medium2);
  }

  &__description, &__tax_note, &__discount {
    font-size: var(--font-size-x-small);
    color: var(--gray-darken-4);
    font-weight: var(--font-weight-medium);
  }

  &__name, &__description, &__tax_note, &__discount {
    line-height: var(--size-value5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .show-on-hover {
    visibility: hidden;
    width: 0;
  }

  @include md-and-up {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);

      .show-on-hover {
        visibility: visible;
        width: auto;
      }

      .hide-on-hover {
        visibility: hidden;
        width: 0;
      }
    }
  }
}

.item-actions {
  &__activator {
    flex: 1 1 0;
    gap: var(--size-value3);
    padding: var(--size-value3);
    font-size: var(--font-size-small);
    line-height: var(--size-value4);
    font-weight: var(--font-weight-large);
  }

  &__list {
    height: 104px;
  }

  &__list-item {
    gap: var(--size-value4);
    padding: 10px 20px;
    color: var(--gray-darken-5);
    font-size: var(--font-size-x-small);
    line-height: var(--size-value5);
    font-weight: var(--font-weight-medium);

    &.disabled {
      color: var(--gray-darken-3);
      pointer-events: none;
    }
  }

  &__desktop-list-item {
    gap: var(--size-value4);
    color: var(--gray-darken-5);
    font-size: var(--font-size-x-small);
    line-height: var(--size-value5);
    font-weight: var(--font-weight-medium);
  }
}
</style>
