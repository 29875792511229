import todoService from '@/modules/todo/services/todoService';

const state = {
  todo: {},
};

const getters = {
  todo: (state) => state.todo,
};

const mutations = {
  setTodo: (state, todo) => {
    state.todo = todo;
  },
};

const actions = {
  fetchTodo: async ({ commit }) => {
    try {
      const todo = await todoService.getTodo();
      commit('setTodo', todo);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
