import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import router from '../router/index';

const isDevMode = process.env.CLOUD_DEV === 'true' || window.pov_config.VUE_APP_ENV === 'development';
const isSentryEnabled = !isDevMode && window.pov_config.VUE_APP_FRONTAGE_POV_SENTRY_DSN;
if (isSentryEnabled) {
  const dataCenter = window.pov_config.VUE_APP_DATA_CENTER || 'us';
  const sampleRate = window.pov_config.VUE_APP_ENV === 'integration' ? 1.0 : 0.1;

  Sentry.init({
    dsn: window.pov_config.VUE_APP_FRONTAGE_POV_SENTRY_DSN,
    environment: window.pov_config.VUE_APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: sampleRate,
    replaysOnErrorSampleRate: sampleRate,
  });
  Sentry.configureScope((scope) => {
    scope.setTag('service', 'frontage-pov');
    scope.setTag('data_center', dataCenter);
  });
}

Vue.prototype.$setSentryTag = function (tagName, tagValue) {
  if (!isSentryEnabled) return;
  Sentry.setTag(tagName, tagValue);
};
