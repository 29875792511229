<template>
  <div data-testid="CoreWidgetExample" class="core-widget">
    <h3>Core Widget Example</h3>
    <VcButton data-testid="CoreWidgetExample-navigation" @click="navigate" flavor="primary">Go to inbox</VcButton> <br/>
    <VcButton data-testid="CoreWidgetExample-getJwks" @click="getJWKS" flavor="primary">Get JWKS</VcButton> <br/>
    <VcButton data-testid="CoreWidgetExample-openAppStore" @click="openAppStore" flavor="primary">Open app store</VcButton> <br/>
  </div>
</template>

<script>

export default {
  name: 'CoreWidgetExample',
  created() {
    setTimeout(() => {
      this.$emit('sdkAction', 'SetState', 'ready');
    }, 1000);
  },
  methods: {
    navigate() {
      this.$emit('sdkAction', 'Navigate', {
        path: '/app/inbox',
        type: 'relative',
        queryParams: {
          folder: 'follow_up',
        },
      });
    },
    getJWKS() {
      this.$emit('sdkAction', 'GetJWKSToken');
    },
    openAppStore() {
      this.$emit('sdkAction', 'OpenModal', {
        modalName: 'AppMarket',
        modalProps: [123],
      });
    },
  },
};
</script>

<style scoped>
.core-widget {
  height: 100%;
}
h3 {
  color: red;
}

ol {
  padding: 0 36px;
}
</style>
