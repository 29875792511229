<template>
  <VcLayout class="payment-widget-list">
    <VcLayout v-if="isLoading">
      <VcSkeleton type="paragraph" width="100%" height="30px" data-qa="PaymentWidgetListSkeleton"/>
    </VcLayout>

    <VcLayout v-else-if="!hasData" column>
      <div class="payment-widget-list__empty-state-title">
        {{ $t('widget.sales_widget.payment_widget_list.ytd') }}
      </div>
      <VcEmptyState title=""
                    :subtitle="$t('widget.sales_widget.payment_widget_list.empty_state.title')"
                    size="sm"
                    :image="require('@/assets/images/payments/empty-payment-widget.svg')"
                    :button="$t('widget.sales_widget.payment_widget_list.empty_state.button')"
                    outlined-button
                    @click="onClickEmptyState"
                    class="my-auto flex-grow-0"
                    data-qa="PaymentWidgetListEmptyState">
      </VcEmptyState>
    </VcLayout>

    <VcLayout v-else column justify-space-between>
      <WidgetData v-for="widget in widgetItems"
                  :key="widget.identifier"
                  :title="widget.title"
                  :value="widget.value"
                  :badge-value="widget.badgeValue"
                  :badge-flavor="widget.badgeFlavor"
                  :badge-tooltip="widget.badgeTooltip"
                  :empty-state="widget.emptyState"
                  @click="handleWidgetClick(widget.identifier)"
                  :data-qa="`PaymentWidget-${widget.identifier}`">
        <template v-if="widget.breakdownData">
          <OverduePaymentsBreakdown :breakdown-data="widget.breakdownData"
                                    :currency="currency"
                                    @breakdown-click="onBreakdownClick(widget.identifier, $event)"/>
        </template>
      </WidgetData>
    </VcLayout>
  </VcLayout>
</template>

<script>
import businessDateFormats from '@/modules/common/filters/BusinessDateFormats';
import WidgetData from './WidgetData.vue';
import OverduePaymentsBreakdown from './OverduePaymentsBreakdown.vue';

export default {
  name: 'PaymentWidgetList',
  components: {
    WidgetData,
    OverduePaymentsBreakdown,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    salesData: {
      type: Object,
      default: null,
      validator(value) {
        if (!value) return true;
        return [
          'total_payments',
          'pending_estimates',
          'overdue_payments_summary',
        ].every((key) => value[key]);
      },
    },
    currency: {
      type: String,
      default: null,
    },
  },
  computed: {
    hasData() {
      if (!this.currency || !this.salesData) return false;
      return this.ytdPayments || this.pendingEstimatesCount || this.overduePaymentsTotal;
    },
    ytdPayments() {
      const value = this.salesData.total_payments.data?.current_year;
      if (value && parseInt(value, 10) > 0) {
        return this.$priceDisplay(value, this.currency);
      }
      return '';
    },
    shouldShowYtdBadge() {
      const now = new Date();
      return this.currentYear && this.lastYear && now.getMonth() >= 3; // 01-04 or later
    },
    currentYear() {
      return this.salesData.total_payments.data?.current_year;
    },
    lastYear() {
      return this.salesData.total_payments.data?.last_year;
    },
    ytdBadgeValue() {
      if (!this.shouldShowYtdBadge) {
        return 0;
      }
      const currentYearValue = this.currentYear;
      const lastYearValue = this.lastYear;

      if (lastYearValue === 0) {
        return currentYearValue > 0 ? 100 : 0;
      }
      return ((currentYearValue - lastYearValue) / lastYearValue) * 100;
    },
    ytdBadgeFlavor() {
      return this.ytdBadgeValue > 0 ? 'green' : 'red';
    },
    pendingEstimatesCount() {
      return this.salesData.pending_estimates.data?.count;
    },
    overduePaymentsTotal() {
      const value = this.salesData.overdue_payments_summary.data?.total_overdue;
      if (value && parseInt(value, 10) > 0) {
        return this.$priceDisplay(value, this.currency);
      }
      return '';
    },
    overduePaymentsSummary() {
      return this.salesData.overdue_payments_summary.data;
    },
    widgetItems() {
      if (!this.hasData) return [];
      return [
        {
          identifier: 'Ytd',
          title: this.$t('widget.sales_widget.payment_widget_list.ytd'),
          value: this.ytdPayments,
          badgeValue: this.shouldShowYtdBadge ? this.$percentageDisplay(this.ytdBadgeValue) : '',
          badgeFlavor: this.ytdBadgeFlavor,
          badgeTooltip: this.$t('widget.sales_widget.payment_widget_list.ytd_badge_tooltip'),
          emptyState: this.$t('widget.sales_widget.payment_widget_list.ytd_empty'),
        },
        {
          identifier: 'OverduePayments',
          title: this.$t('widget.sales_widget.payment_widget_list.overdue_payments'),
          value: this.overduePaymentsTotal,
          emptyState: this.$t('widget.sales_widget.payment_widget_list.overdue_payments_empty'),
          breakdownData: this.overduePaymentsSummary,
        },
        {
          identifier: 'PendingEstimates',
          title: this.$t('widget.sales_widget.payment_widget_list.pending_estimates'),
          value: this.$hasFeature('payments_feature_estimates') && this.pendingEstimatesCount,
        },
      ];
    },
  },
  methods: {
    onClickEmptyState() {
      this.$track('Dashboard Sales Widget Empty state Click');
      this.$emit('click-empty-state');
    },
    navigate(path, queryParams) {
      this.$emit('navigate', { path, queryParams });
    },
    handleWidgetClick(identifier) {
      switch (identifier) {
        case 'Ytd': {
          this.$track('Dashboard Sales Widget YTD Click');
          const now = new Date();
          const startDate = new Date(now.getFullYear(), 0, 1);
          this.navigate('/app/payments/transactions', { start_date: businessDateFormats.dateFormatted(startDate) });
          break;
        }
        case 'PendingEstimates':
          this.$track('Dashboard Sales Widget Pending estimates Click');
          this.navigate('/app/payments/estimates', { selected_status: 'issued' });
          break;
        case 'OverduePayments':
          this.$track('Dashboard Sales Widget overdue payments Click');
          this.onBreakdownClick(identifier, { timeFrame: null });
          break;
        default:
          break;
      }
    },
    onBreakdownClick(identifier, { timeFrame }) {
      switch (identifier) {
        case 'OverduePayments': {
          const { startDate, endDate } = this.getDateRange(timeFrame);
          this.navigate('/app/payments/orders', {
            selected_status: 'past_due',
            due_date_start: startDate,
            due_date_end: endDate,
          });
          break;
        }
        default:
          break;
      }
    },
    getXDaysAgo(days) {
      const now = new Date();
      const today = new Date(now.setHours(0, 0, 0, 0));
      return new Date(today.setDate(today.getDate() - days));
    },
    getDateRange(timeFrame) {
      switch (timeFrame) {
        case 'last_week':
          return {
            startDate: businessDateFormats.dateFormatted(this.getXDaysAgo(7)),
            endDate: businessDateFormats.dateFormatted(new Date()),
          };
        case 'last_month':
          return {
            startDate: businessDateFormats.dateFormatted(this.getXDaysAgo(30)),
            endDate: businessDateFormats.dateFormatted(this.getXDaysAgo(8)),
          };
        case 'old':
          return {
            startDate: businessDateFormats.dateFormatted(this.getXDaysAgo(10 * 365)),
            endDate: businessDateFormats.dateFormatted(this.getXDaysAgo(31)),
          };
        default:
          return {};
      }
    },
  },
};
</script>

<style scoped lang="scss">
.payment-widget-list {
  min-height: 300px;

  &__empty-state-title {
    font-size: var(--font-size-small2);
    font-weight: var(--font-weight-large2);
  }
  ::v-deep .sm .VcEmptyStateImage {
    height: 56px;
    margin-bottom: var(--size-value4);

    .image {
      width: 51px;
    }
  }
}
</style>
