import store from '@/store';

/**
 * Retrieves nested data from the Vuex store using a path string
 * @param {string} path - The path to the data in the store (e.g. 'ModuleStore/submodule/nestedPath')
 * @returns {Object} Object containing either the data or an error message
 */
// eslint-disable-next-line import/prefer-default-export
export const getNestedStoreData = (path) => {
  try {
    if (!path) {
      return { error: 'Path parameter is required' };
    }

    const parts = path.split('/');
    let currentState = store.state;
    let currentPath = '';

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      currentPath += (currentPath ? '/' : '') + part;

      if (currentState === undefined || currentState === null) {
        return { error: `Data not found at path: ${currentPath}` };
      }

      if (typeof currentState !== 'object') {
        return { error: `Data not found at path: ${currentPath}` };
      }

      currentState = currentState[part];

      // If this is the last part and we have a value, return it
      if (i === parts.length - 1) {
        if (currentState === undefined || currentState === null) {
          return { error: `Data not found at path: ${currentPath}` };
        }
        return { data: currentState };
      }
    }

    // If we've gone through all parts but haven't returned, the path doesn't exist
    return { error: `Data not found at path: ${path}` };
  } catch (error) {
    return { error: `Error retrieving data: ${error.message}` };
  }
};
