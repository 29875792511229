import httpService from '@/modules/common/services/httpService';

const getTodo = async () => {
  try {
    const res = await httpService.get('v2/todo', { automatic: true });
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export default {
  getTodo,
};
