import { render, staticRenderFns } from "./BizAI.vue?vue&type=template&id=643a349b"
import script from "./BizAI.vue?vue&type=script&lang=js"
export * from "./BizAI.vue?vue&type=script&lang=js"
import style0 from "./BizAI.vue?vue&type=style&index=0&id=643a349b&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports