<template>
  <VcLayout
    column
    class="widget-wrapper quick-actions-widget pt-6 pb-4"
  >
    <VcLayout justify-space-between shrink class="px-6">
      <div class="widget-title">{{ $t('widget.quick_actions_widget.title') }}</div>
      <VcTooltip
        dark
        bottom
        :content="$t('widget.quick_actions_widget.edit')"
        class="edit-icon-wrapper"
      >
        <VcButton
          @click="openEditModal"
          prepend-icon="pencil"
          :icon-size="16"
          flavor="secondary"
          small
          prepend-default-icon
          class="pl-3 pr-0"
          data-qa="edit-button"
        />
      </VcTooltip>
    </VcLayout>
    <VcLayout
      v-if="visibleQuickActions.length"
      wrap
      shrink
      class="mt-2 px-6 actions-list"
    >
      <VcSmallQuickAction
        v-for="item in visibleQuickActions"
        :key="item.name"
        :image="item.image"
        :title="actionTitle(item)"
        :tooltipProps="{dark: true}"
        @click="fireQuickAction(item.name)"
        class="quick-action-item"
        data-qa="quick-action"
        :data-name="`item-${item.name}`"
      />
    </VcLayout>
  </VcLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'QuickActionsWidget',
  computed: {
    ...mapGetters('StaffStore', ['quickActions']),
    ...mapGetters('AngularStore', ['isAngularDisplayed']),
    ...mapGetters('BusinessStore', ['matterTermTranslation']),
    matterNameSingular() {
      // eslint-disable-next-line localization/no-logic-in-translations
      return this.$t(this.matterTermTranslation?.lowercase_singular);
    },
    visibleQuickActions() {
      return this.quickActions.filter((action) => action.visible);
    },
  },
  async created() {
    this.addI18nModule('widgets-pov');
    await this.fetchQuickActions(true);
    this.$emit('ready');
  },
  methods: {
    ...mapActions('ModalStore', ['setForceModalMode']),
    ...mapActions('StaffStore', ['fetchQuickActions']),
    openEditModal() {
      this.$emit('sdkAction', 'OpenModal', {
        modalName: 'edit-quick-actions-modal',
      });
    },
    fireQuickAction(quickActionName) {
      this.$emit('sdkAction', 'OpenQuickAction', {
        actionName: quickActionName,
        origin: 'new_dashboard',
        quickActionProps: { hideBackground: !this.isAngularDisplayed },
      });
    },
    actionTitle(action) {
      // eslint-disable-next-line localization/no-logic-in-translations
      return this.$t(action.translationKey, { lowercase_singular: this.matterNameSingular });
    },
  },
};
</script>

<style scoped lang="scss">
$small: 267px;

.widget-wrapper {
  container-type: inline-size;
  line-height: normal;
  text-align: start;
  width: 100%;
  max-height: 100%;
  color: var(--gray-darken-5);

  .edit-icon-wrapper {
    display: none;
    @media screen and (max-width: 1024px) {
      display: block;
    }
  }
  &:hover {
    .edit-icon-wrapper {
      display: block;
    }
  }
}

.widget-title {
  height: 32px;
  font-size: var(--font-size-medium2);
  font-weight: var(--font-weight-large2);
  color: var(--gray-darken-5);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.actions-list {
  column-gap: var(--size-value2);
  overflow: hidden;
}

.quick-action-item {
  height: var(--size-value10);
  border-inline-start: 3px solid var(--modal-bg-color);
  &:hover {
    border-color: var(--gray-lighten-3);
    background-color: var(--gray-lighten-3);
  }
  &.VcSmallQuickAction {
    cursor: pointer;

    @container (min-width: #{$small}) {
      width: calc(50% - var(--size-value2));
    }
  }
}

</style>
