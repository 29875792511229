import formattingUtil from '@/utils/formattingUtil';

export const TAX_MODES = {
  include: 'include',
  exclude: 'exclude',
};

// ============ Helper Functions ============
// eslint-disable-next-line no-underscore-dangle
function _calcAndSetTaxesTotal(taxes, price) {
  taxes.forEach((tax) => {
    // eslint-disable-next-line no-param-reassign
    tax.tax_total = formattingUtil.roundNumber(price * (tax.rate / 100));
  });
  return taxes.reduce((acc, tax) => acc + tax.tax_total, 0);
}

// eslint-disable-next-line no-underscore-dangle
function _calcPriceBreakdownTaxExcluded(priceAmount, taxes) {
  const price = formattingUtil.roundNumber(priceAmount);
  const taxTotal = _calcAndSetTaxesTotal(taxes, price);
  const grandTotal = price + taxTotal;

  return {
    displayPrice: price,
    netTotal: price,
    taxTotal: formattingUtil.roundNumber(taxTotal),
    grandTotal: formattingUtil.roundNumber(grandTotal),
  };
}

// eslint-disable-next-line no-underscore-dangle
function _calcPriceBreakdownTaxIncluded(priceAmount, taxes) {
  const price = formattingUtil.roundNumber(priceAmount);
  const taxTotalRate = taxes.reduce((acc, tax) => acc + tax.rate, 0);
  let netTotal = price / (1 + taxTotalRate / 100);
  const taxTotal = _calcAndSetTaxesTotal(taxes, netTotal);
  netTotal = price - taxTotal;

  return {
    displayPrice: price,
    netTotal: formattingUtil.roundNumber(netTotal),
    taxTotal: formattingUtil.roundNumber(taxTotal),
    grandTotal: price,
  };
}
// ============ End Helper Functions ============

function calcPriceBreakdown(taxMode, priceAmount, taxes = []) {
  if (taxMode === TAX_MODES.include) {
    return _calcPriceBreakdownTaxIncluded(priceAmount, taxes);
  }
  return _calcPriceBreakdownTaxExcluded(priceAmount, taxes);
}

function getPriceByTaxMode(taxMode, netTotal, grandTotal) {
  if (taxMode === TAX_MODES.exclude) {
    return netTotal;
  }
  return grandTotal;
}

function calcPriceBreakdownWithPartialPayment(priceBreakdown, taxes, paidAmount, taxMode) {
  const paidBreakdown = calcPriceBreakdown(TAX_MODES.include, paidAmount, taxes);

  const breakdown = {
    netTotal: formattingUtil.roundNumber(priceBreakdown.netTotal - paidBreakdown.netTotal),
    taxTotal: formattingUtil.roundNumber(priceBreakdown.taxTotal - paidBreakdown.taxTotal),
    grandTotal: formattingUtil.roundNumber(priceBreakdown.grandTotal - paidBreakdown.grandTotal),
  };

  breakdown.displayPrice = getPriceByTaxMode(taxMode, breakdown.netTotal, breakdown.grandTotal);
  return breakdown;
}

function generateLocalID() {
  return Math.floor(Math.random() * (100000 - 1000 + 1)) + 1000;
}

export default {
  TAX_MODES,
  calcPriceBreakdown,
  getPriceByTaxMode,
  calcPriceBreakdownWithPartialPayment,
  generateLocalID,
};
